<template>
  <div class="holiday_data">
    <div class="data_title_box">
      <h2>有給休暇Data</h2>

      <button class="filter" @click="handleFilter">
        Filter<span class="material-symbols-outlined"> filter_alt </span>
      </button>
    </div>

    <table>
      <tr>
        <th>#</th>
        <th>Engineer Name</th>
        <th>
          <div v-if="!isFilter">エリア</div>
          <div v-else>
            Area<select name="area" v-model="area_selected" @change="handleAreaChange" class="table_title_select">
              <option disabled value="">選択</option>
              <option value="sapporo">札幌</option>
              <option value="morioka">盛岡</option>
              <option value="oosaka">大阪</option>
              <option value="fukuoka">福岡</option>
              <option value="all">すべて</option>
            </select>
          </div>
        </th>

        <th>
          <div v-if="!isFilter">担当者</div>
          <div v-else>
            担当者<select name="pic" v-model="pic_selected" @change="handlePICChange" class="table_title_select">
              <option disabled value="">選択</option>
              <option value="kiyanagi">鬼柳</option>
              <option value="hata">畑</option>
              <option value="oda">小田</option>
              <option value="all">すべて</option>
            </select>
          </div>
        </th>

        <th>
          <div v-if="!isFilter">有休残日数</div>
          <div v-else>
            有休残日数<select name="remainday" v-model="remain_selected" @change="handleSortRemain"
              class="table_title_select">
              <option disabled value="">選択</option>
              <option value="1">降順↓</option>
              <option value="2">昇順↑</option>
            </select>
          </div>
        </th>

        <th>
          <div v-if="!isFilter">次回有休付与日</div>
          <div v-else>
            次回有休付与日<select name="enter_date" v-model="next_add_selected" @change="handleSortNextAdd"
              class="table_title_select">
              <option disabled value="">選択</option>
              <option value="0">リセット</option>
              <option value="1">近い順</option>
              <option value="2">遠い順</option>

            </select>
          </div>
        </th>

        <th>
          <div v-if="!isFilter">次回付与日数</div>
          <div v-else>
            次回有休付与日<select name="enter_date" v-model="num_of_add_selected" @change="handleSortNum"
              class="table_title_select">
              <option disabled value="">選択</option>
              <option value="0">リセット</option>
              <option value="1">多い順</option>
              <option value="2">少ない順</option>
            </select>
          </div>
        </th>
        <th>
          <div v-if="!isFilter">使用状況</div>
          <div v-else>
            使用状況<select name="enter_date" v-model="holiday_status_selected" @change="handleHoliStatus"
              class="table_title_select">
              <option disabled value="">選択</option>
              <option value="1">消化済</option>
              <option value="2">消化予定</option>
              <option value="3">未消化</option>
              <option value="4">未使用</option>
              <option value="5">すべて</option>
            </select>
          </div>
        </th>
      </tr>
      <tr v-for="(defaultdata, index) in currentPageItems" :key="defaultdata.id">
        <td>
          {{ (index + 1).toString().padStart(2, "0") }}
        </td>
        <td>
          <div class="default_engineer_name_frame" @click="engineerCheck(defaultdata.id)">
            <div v-if="defaultdata.face_url">
              <img :src="defaultdata.face_url" class="face" />
            </div>
            <div v-else><img src="@/assets/mik_green.png" class="face" /></div>
            <div class="engineer_name">
              {{ defaultdata.last_name }} {{ defaultdata.first_name }}
            </div>
          </div>
        </td>
        <td>
          <div v-if="defaultdata.area">
            <AreaStatus :id="defaultdata.id" :area="defaultdata.area" />
          </div>
        </td>
        <td>
          <div v-if="defaultdata.pic">
            <PICStatus :id="defaultdata.id" :pic="defaultdata.pic" />
          </div>
        </td>
        <td>

          <div class="table_item">{{ defaultdata.remain_holiday }}日</div>

        </td>
        <td>
          <div v-if="defaultdata.next_add" class="table_item">
            {{ defaultdata.next_add }}
          </div>
          <div v-else class="table_item">未設定</div>
        </td>

        <td>
          <div v-if="defaultdata.num_of_add" class="table_item">
            {{ defaultdata.num_of_add }}日
          </div>
          <div v-else class="table_item">未設定</div>
        </td>

        <td>
          <div class="table_item">
            <HolidayStatus :status_number="defaultdata.status" />
          </div>
        </td>
      </tr>
    </table>

    <div class="data_footer">
      <div class="previous_frame">
        <button class="previous_button" @click="prevPage" v-if="currentPage !== 1">
          <span class="material-symbols-outlined"> west </span>Previous
        </button>
      </div>
      <div class="pagenation">
        <ul>
          <li v-for="page in totalPages" :key="page" @click="changePage(page)"
            :class="{ 'active': page === currentPage }">
            <div class="page_number">{{ page }}</div>
          </li>
        </ul>
      </div>
      <div class="next_frame">
        <button class="next_button" @click="nextPage" v-if="currentPage !== totalPages">
          Next<span class="material-symbols-outlined"> east </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import HolidayStatus from "./HolidayStatus.vue";
import DefaultStatus from "./HolidayStatus.vue";
import { ref, onMounted, watch, toRef } from "vue";
import AreaStatus from "../home_comp/AreaStatus.vue";
import PICStatus from "../home_comp/PICStatus.vue";

export default {
  props: {
    isSelect: Boolean,
    defaultdatas: {
      type: Array,
      default: () => [],
    },
    searchdatas: {
      type: Array,
      default: () => [],
    },
    isFix: Boolean,
    reloadDefaultData: Function,
    now_page: Number,
  },
  name: "EngineerDefaultData",
  components: {
    DefaultStatus,
    HolidayStatus,
    AreaStatus,
    PICStatus,
  },
  data() {
    return {
      itemsPerPage: 6,

      item_num: 1,
      selectedItems: [],
      isFilter: false,
      area_selected: null,
      pic_selected: null,
      remain_selected: null,
      next_add_selected: null,
      num_of_add_selected: null,
      holiday_status_selected: null,
    };
  },
  computed: {
    currentPageItems() {
      if (this.defaultdatas) {
        //フィルターソートのため、Restではソートを行わない。
        //this.defaultdatas.sort((b, a) => b.enter_date - a.enter_date);
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        return this.defaultdatas.slice(startIndex, endIndex);
      } else {
        return [];
      }
    },

    totalPages() {
      if (this.defaultdatas) {
        return Math.ceil(this.defaultdatas.length / this.itemsPerPage);
      }
      return 0;
    },
  },
  setup(props) {
    const currentPage = ref(1)

    const changePage = (pageNumber) => {
      currentPage.value = pageNumber;
    }
    console.log("props.page", props.now_page)
    if (props.now_page === 0) {
      changePage(1)
    } else {
      changePage(props.now_page)
    }

    if (props.isFix) {
      console.log(props.defaultdatas);
      watch(
        () => props.defaultdatas.value,
        (newDefaultDatas) => {
          if (newDefaultDatas !== null) {
            props.defaultdatas.value = newDefaultDatas;
          }
        }
      );
      onMounted(() => {
        if (props.reloadDefaultData) {
          props.reloadDefaultData();
          console.log("mount");
        }
      });
    }

    return {
      currentPage,
      changePage,
    }

  },

  methods: {

    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    engineerCheck(id) {
      if (this.selectedItems.length === 0) {
        this.selectedItems.push(id);
      } else {
        this.selectedItems.shift();
        this.selectedItems.push(id);
      }
      this.currentPageItems.forEach((defaultdata) => {
        if (!this.selectedItems.includes(defaultdata.id)) {
          defaultdata.isSelected = false;
        }
      });

      console.log(this.selectedItems);
      this.$emit("selected_engineer_id", this.selectedItems[0], this.currentPage);
    },
    handleFilter() {
      this.isFilter = !this.isFilter;
    },
    handleAreaChange() {
      this.$emit("area_selected", this.area_selected);
    },
    handlePICChange() {
      this.$emit("pic_selected", this.pic_selected);
    },
    handleSortRemain() {
      this.$emit("sort_remain_day", this.remain_selected);
    },
    handleSortNextAdd() {
      this.$emit("next_add_selected", this.next_add_selected);
    },
    handleSortNum() {
      this.$emit("num_of_add_selected", this.num_of_add_selected);
    },
    handleHoliStatus() {
      this.$emit("holiday_status_selected", this.holiday_status_selected)
    }
  },
};
</script>

<style scoped>
.data_title_box {
  width: 1042px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.data_title_box button {
  width: 102px;
  height: 44px;
  border-radius: 12px;
  border: 1px solid #cdcde3;
  color: #a8a8bd;
  line-height: 19.6px;
  word-wrap: break-word;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter .material-symbols-outlined {
  color: #a8a8bd;
  font-size: 20px;
  margin-left: 5px;
}

.holiday_data {
  width: 1135px;
  height: 652px;
  border-radius: 16px;
  background: white;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.holiday_data table {
  width: 1042px;
  height: 492px;
  border-collapse: collapse;
}

.holiday_data td {
  border-top: 1px solid #ddd;
}

.holiday_data .engineer_name_frame {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  cursor: pointer;
}

.holiday_data .engineer_name {
  width: 150px;
  display: flex;
  justify-content: flex-start;
  margin-left: 5px;
}

.photo {
  width: 30px;
  height: 30px;
  background: #ddd;
  border-radius: 15px;
}

.holidaydata_button {
  width: 110px;
  height: 30px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.data_footer {
  display: flex;
  margin-top: 22px;
  width: 1042px;
  height: 40px;

  justify-content: space-between;
  align-items: center;
}

.data_footer ul {
  display: flex;
  list-style: none;
  width: 292px;
  padding: 0;
  justify-content: space-around;
}

/* .data_footer li {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
} */

/* .data_footer button {
  appearance: none;
  border: none;
  background: white;
  font-size: 16px;
} */

/* .data_footer li :hover {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #006aff;
  background: rgba(0, 106, 255, 0.5);
  border: none;
} */

.previous_button {
  width: 114px;
  height: 36px;
  color: #2c3e50;
  border: 1px solid #cdcde3;
  background: white;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.previous_frame {
  width: 114px;
}

.next_button {
  width: 88px;
  height: 36px;
  color: #2c3e50;
  border: 1px solid #cdcde3;
  background: white;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.next_frame {
  width: 88px;
}

.material-symbols-outlined {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
}

.data_footer .material-symbols-outlined {
  font-size: 16px;
  color: #2c3e50;
  width: 18px;
  display: flex;
  align-items: center;
}

.edit_check {
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #2c3e50;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.edit_check:checked {
  background: #58769f;
}

.pagenation li {
  margin: 0 10px;
}

.page_number {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.pagenation li.active .page_number {
  font-weight: bold;
}
</style>
