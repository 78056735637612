<template>
  <div class="user_holiday_table_box">
    <p>※未来の有休申請は×ボタンで取消しができます。</p>
    <table>
      <tr>
        <th>取得日</th>
        <th>有休利用</th>
        <th>日数</th>
        <th>理由</th>

        <th>状況</th>
        <th>取消</th>
      </tr>
      <tr v-for="user_data in filtered_holiday_within" :key="user_data.id">
        <td>{{ user_data.holiday_date }}</td>
        <td>
          <div v-if="user_data.holiday_type === 'paid'" class="paid_text">
            有休
          </div>
          <div v-if="user_data.holiday_type === 'absent'" class="absent_text">
            <div v-if="user_data.type_of_absent === 1">欠勤（全日休）</div>
            <div v-if="user_data.type_of_absent === 2">欠勤（遅刻）</div>
            <div v-if="user_data.type_of_absent === 3">欠勤（早退）</div>
          </div>
        </td>
        <td>{{ user_data.use_holiday_num }}</td>
        <td>{{ user_data.holiday_reason }}</td>

        <td>
          <div v-if="user_data.holiday_type === 'paid'">
            <div v-if="user_data.holiday_formated">
              <div
                v-if="user_data.holiday_formated.toDate() < today"
                class="get_mark"
              >
                済
              </div>
              <div v-else class="unget_mark">未</div>
            </div>
          </div>
          <div v-else>-</div>
        </td>
        <td>
          <div v-if="user_data.holiday_type === 'paid'">
            <div v-if="user_data.holiday_formated">
              <div v-if="user_data.holiday_formated.toDate() > today">
                <button
                  @click="handleDataDelete(user_data.id)"
                  :disabled="user_data.disableCancelButton"
                >
                  {{ user_data.cancel_button_title }}
                </button>
              </div>
            </div>
          </div>
          <div v-else>-</div>
        </td>
      </tr>
    </table>
    <div class="batsu_button" @click="handleDataClose">×</div>
  </div>
</template>

<script>
import { now_timestamp, projectFirestore } from "@/firebase/config";
import { watch, ref, onMounted } from "vue";
import getDefaultDatas from "../composables/getDefaultDatas";
import getCalcTotalUse from '../composables/getCalcTotalUse';
export default {
  props: ["user_id","next_add"],
  data() {
    return {
      today: new Date(),
    };
  },
  setup(props, context) {
    const user_datas = ref([]);

    const { defaultdatas: datas, datas_load } = getDefaultDatas(
      "holiday_application"
    );
    const filtered_holiday_within = ref([]);
      
    onMounted(async () => {
      await datas_load();
      const sortedDatas = datas.value
        .slice()
        .sort((a, b) => b.holiday_formated - a.holiday_formated);
      user_datas.value = sortedDatas.filter((data) => {
        return data.userid === props.user_id;
      });
      user_datas.value.forEach((user_data) => {
        user_data.cancel_button_title = "×";
      });

    const {filtered_holiday_array} = await getCalcTotalUse(props.next_add,user_datas.value);
    filtered_holiday_within.value = filtered_holiday_array;

     
    });

  

    const handleDataDelete = async (id) => {
      await projectFirestore
        .collection("holiday_application")
        .doc(id)
        .delete()
        .then(() => {
          const deletedIndex = filtered_holiday_within.value.findIndex(
            (user_data) => user_data.id === id
          );
          if (deletedIndex !== -1) {
            // データが見つかった場合
            filtered_holiday_within.value.splice(deletedIndex, 1);
   
          }
        });
      context.emit("data_delete")
    };

    return {
      user_datas,
      filtered_holiday_within,
      handleDataDelete,
    };
  },

  methods: {
    handleDataClose() {
      this.$emit("holiday_data_close");
    },
  },
};
</script>

<style>
.user_holiday_table_box {
  margin-top: 20px;
  width: 400px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  border: 5px solid;
  padding: 60px 0 30px 0px;
  position: relative;
  flex-direction: column;
}
.yukyu_box {
  width: 50px;
}
.user_holiday_table td {
  border-top: 0.5px solid #ddd;
  padding: 10px 10px;
}

.user_holiday_table table {
  border-collapse: collapse;
}

.batsu_button {
  position: absolute;
  right: 10px;
  top: 0px;
  font-size: 30px;
  cursor: pointer;
}

.paid_text {
  color: #d9376e;
  font-weight: bold;
  width: 50px;
}
.absent_text {
  color: #2cb67d;
  font-weight: bold;
  font-size: 13px;
  width: 50px;
}
.get_mark {
  background: #72757e;
  color: white;
  font-weight: bold;
  border-radius: 30px;
  width: 40px;
}

.unget_mark {
  background: #ffd803;
  color: white;
  font-weight: bold;
  border-radius: 30px;
  width: 40px;
}
</style>
