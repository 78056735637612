<template>
  <div class="all_data">
    <div class="data_title_box">
      <h2>All Data</h2>
      <button class="filter">
        Filter<span class="material-symbols-outlined"> filter_alt </span>
      </button>
    </div>

    <table>
      <tr>
        <th>#</th>
         <th>Engineer Name</th>

        <th>所属地域</th>
        <th>担当営業</th>

        <th>入社日</th>
        <th>会社メールアドレス</th>
        <th>連絡先メールアドレス</th>
        <th>連絡先電話番号</th>
        <th>住所</th>
        <th>生年月日</th>
        <th>Status</th>
        <th>緊急連絡先</th>
        <th>緊急連絡先の方のお名前・族柄</th>
        <th>更新日</th>
      </tr>

      <tr v-for="(defaultdata, index) in defaultdatas" :key="defaultdata.id">
        <td>
          
          <div v-if="!isSelect">
            {{ (index + 1).toString().padStart(2, "0") }}
          </div>
        </td>
        <td>
          <div
            class="engineer_name_frame"
            @click="engineerNameCheck(defaultdata.id)"
          >
            <div v-if="defaultdata.face_url">
              <img :src="defaultdata.face_url" class="face" />
            </div>
            <div v-else><img src="@/assets/mik_green.png" class="face" /></div>
            <div class="engineer_name">
              {{ defaultdata.last_name }} {{ defaultdata.first_name }}
            </div>
          </div>
          <div class="komoji_data">{{ defaultdata.company_id }}</div>
        </td>
        <td>{{ defaultdata.area }}</td>
        <td>{{ defaultdata.pic }}</td>
        <td>
          {{ defaultdata.enter_year }}/{{ defaultdata.enter_month }}/{{
            defaultdata.enter_day
          }}
        </td>
        <td>{{ defaultdata.company_mail }}</td>
        <td>{{ defaultdata.major_mail }}</td>
        <td>{{ defaultdata.phone }}</td>
        <td>{{ defaultdata.address }}</td>
        <td>  {{ defaultdata.birth_year }}/{{ defaultdata.birth_month }}/{{
                defaultdata.birth_day
              }}</td>
              <td><DefaultStatus :status_number="defaultdata.status" /></td>
              <td>{{ defaultdata.emergency_phone }}</td>
              <td>{{ defaultdata.emergency_name }}:関係{{ defaultdata.emergency_relation }}</td>
       
        
        
   
      </tr>
    </table>

    <div class="data_footer">
      <div class="previous_frame">
        <button
          class="previous_button"
          @click="prevPage"
          v-if="currentPage !== 1"
        >
          <span class="material-symbols-outlined"> west </span>Previous
        </button>
      </div>

      <ul>
        <li v-for="page in totalPages" :key="page" @click="changePage(page)">
          <button>{{ page }}</button>
        </li>
      </ul>
      <div class="next_frame">
        <button
          class="next_button"
          @click="nextPage"
          v-if="currentPage !== totalPages"
        >
          Next<span class="material-symbols-outlined"> east </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultStatus from "./DefaultStatus.vue";
import { ref, onMounted, watch, toRef } from "vue";

export default {
  props: {
    isSelect: Boolean,
    defaultdatas: {
      type: Array,
      default: () => [],
    },
    is_onEdit: Boolean,
    is_onDelete: Boolean,
    reloadDefaultData: Function,
  },
  name: "EngineerDefaultData",
  components: {
    DefaultStatus,
  },
  data() {
    return {
      itemsPerPage: 6,
      currentPage: 1,
      item_num: 1,
      selectedItems: [],
    };
  },
  computed: {
    currentPageItems() {
      if (this.defaultdatas) {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        return this.defaultdatas.slice(startIndex, endIndex);
      } else {
        return [];
      }
    },

    totalPages() {
      if (this.defaultdatas) {
        return Math.ceil(this.defaultdatas.length / this.itemsPerPage);
      }
      return 0;
    },
  },
  setup(props) {
    if (props.isFix) {
      console.log(props.defaultdatas);
      watch(
        () => props.defaultdatas.value,
        (newDefaultDatas) => {
          if (newDefaultDatas !== null) {
            props.defaultdatas.value = newDefaultDatas;
          }
        }
      );
      onMounted(() => {
        if (props.reloadDefaultData) {
          props.reloadDefaultData();
          console.log("mount");
        }
      });
    }
  },

  methods: {
    changePage(pageNumber) {
      this.currentPage = pageNumber;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    engineerCheck(id) {
      if (this.selectedItems.length === 0) {
        this.selectedItems.push(id);
      } else {
        this.selectedItems.shift();
        this.selectedItems.push(id);
      }
      this.currentPageItems.forEach((defaultdata) => {
        if (!this.selectedItems.includes(defaultdata.id)) {
          defaultdata.isSelected = false;
        }
      });
      this.$emit("selected_engineer_check", this.selectedItems[0]);
      console.log(this.selectedItems);
    },
    engineerNameCheck(id) {
      if (this.selectedItems.length === 0) {
        this.selectedItems.push(id);
      } else {
        this.selectedItems.shift();
        this.selectedItems.push(id);
      }
      this.currentPageItems.forEach((defaultdata) => {
        if (!this.selectedItems.includes(defaultdata.id)) {
          defaultdata.isSelected = false;
        }
      });

      console.log(this.selectedItems);
      this.$emit("selected_engineer_name", this.selectedItems[0]);
    },
  },
};
</script>

<style>
.all_data .data_title_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.data_title_box button {
  width: 102px;
  height: 44px;
  border-radius: 12px;
  border: 1px solid #cdcde3;
  color: #a8a8bd;
  line-height: 19.6px;
  word-wrap: break-word;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter .material-symbols-outlined {
  color: #a8a8bd;
  font-size: 20px;
  margin-left: 5px;
}
.all_data {
  width: 100%;
  height: 652px;
  border-radius: 16px;
  background: white;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
}
.all_data table {
  width: 100%;
  border-collapse: collapse;
  column-width: 100px;
  font-size: 14px;
}
.all_data td {
  border-top: 1px solid #ddd;
}
.all_data .engineer_name_frame {
  display: flex;
  align-items: center;
}
.all_data .engineer_name {
  width: 150px;
  display: flex;
  justify-content: flex-start;
  margin-left: 5px;
  cursor: pointer;
}

.all_data .face {
  width: 30px;
  height: 30px;

  border-radius: 20px;
}

.data_footer {
  display: flex;
  margin-top: 22px;
  width: 1042px;
  height: 40px;

  justify-content: space-between;
  align-items: center;
}
.data_footer ul {
  display: flex;
  list-style: none;
  width: 292px;
  padding: 0;
  justify-content: space-around;
}
.data_footer li {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
}
.data_footer button {
  appearance: none;
  border: none;
  background: white;
  font-size: 16px;
}
.data_footer li :hover {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #006aff;
  background: rgba(0, 106, 255, 0.5);
  border: none;
}
.previous_button {
  width: 114px;
  height: 36px;
  color: #2c3e50;
  border: 1px solid #cdcde3;
  background: white;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.previous_frame {
  width: 114px;
}
.next_button {
  width: 88px;
  height: 36px;
  color: #2c3e50;
  border: 1px solid #cdcde3;
  background: white;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.next_frame {
  width: 88px;
}

.material-symbols-outlined {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
}
.data_footer .material-symbols-outlined {
  font-size: 16px;
  color: #2c3e50;
  width: 18px;
  display: flex;
  align-items: center;
}

.edit_check {
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #2c3e50;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.edit_check:checked {
  background: #58769f;
}
.komoji_data{
  font-size: 12px;
  margin-top:-10px;

}
</style>
