<template>
  <div class="out_user_loginform_style">

    <h4>MIKイントラサイト用IDでログインしてください。</h4>
    <div class="microsoft_button_wrapper">


      <button class="microsoft_input_box" @click="handleMicrosoftLogin"> <img
          src="@/assets/Microsoft-logo_rgb_c-gray.png" class="microsoft_logo"></button>

      <div v-if="error">{{ error }}</div>
    </div>




  </div>
</template>

<script>
import { ref } from "vue";
import useLogin from "../composables/useLogin";
import { useRouter } from "vue-router";
import { projectAuth, provider } from "@/firebase/config";

export default {
  data() {
    return {
      error: null,
    }
  },
  setup(props, context) {
    const email = ref("");
    const password = ref("");

    const { error, login } = useLogin();
    const router = useRouter();

    const handleSubmit = async () => {
      await login(email.value, password.value);
      if (!error.value) {
        console.log("ユーザーはログインしました。");
        // if (password.value === "mik-s.jp") {
        //   router.push({ name: "PassChange" });
        // } else {
        router.push({ name: "User_Home" });
        // }
      }
    };

    return { email, password, handleSubmit, error, router };
  },
  methods: {
    async handleMicrosoftLogin() {
      provider.setCustomParameters({ prompt: 'consent' })
      projectAuth.signInWithPopup(provider).then((result) => {
        let credential = result.credential;
        let accessToken = credential.accessToken;
        let idToken = credential.idToken;

        const graphApiUrl = 'https://graph.microsoft.com/v1.0/me';
        fetch(graphApiUrl, {
          method: 'GET',
          headers: {
            'Authorization': accessToken,
            'Content-Type': `application/json`,
          }
        })
          .then(response => response.json())
          .then(data => {
            console.log(data)
            this.error = null;
            let user_mail = null;
            if (data.mail) {
              user_mail = data.mail;
            }
            this.router.push({ name: "User_Home", props: user_mail })
          })
          .catch(error => {
            console.error('Microsft Graph Apiからフェッチするときにエラーがはっせいしました', error)
          })



      })
        .catch((error) => {
          this.error = error.message

        })

    }
  }
};
</script>

<style>
.out_user_loginform_style {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  flex-direction: column;
  align-items: center;
}

.error {
  color: crimson;
  font-size: 14px;
}

.microsoft_logo {
  width: 100px;
  height: auto;

}

.microsoft_input_box {
  width: 230px;
  height: auto;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  appearance: none;
  background: white;
  cursor: pointer;
}

.border {
  border-bottom: 1.5px solid #d9d9d9;
  width: 220px;
  margin-bottom: 50px;
}

.microsoft_button_wrapper {
  font-size: 15px;
  margin-bottom: 50px;
  text-align: center;
}
</style>
