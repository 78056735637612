import { ref } from "vue";

const num_200 = ref(0);
const num_100 = ref(0);
const num_50 = ref(0);
const num_10 = ref(0);

const restpoint = ref(0);
const changemoney = ref(0);
const can_change_point = ref(0);


const getChangeMoney = (total_point) => {
  restpoint.value = total_point;
  //200ポイント単位の金額を確認

  if (restpoint.value >= 200) {

    if (restpoint.value === 200) {
      num_10.value = 0;
      num_50.value = 0;
      num_100.value = 0;
    }
    num_200.value = Math.floor(restpoint.value / 200);
    restpoint.value = restpoint.value % 200;
  }

  if (restpoint.value >= 100) {

    if (restpoint.value === 100) {
      num_10.value = 0;
      num_50.value = 0;
    }
    num_100.value = Math.floor(restpoint.value / 100);
    restpoint.value = restpoint.value % 100;
  }

  if (restpoint.value >= 50) {
  
    if (restpoint.value === 50) {
      num_10.value = 0;
    }
    num_50.value = Math.floor(restpoint.value / 50);
    restpoint.value = restpoint.value % 50;
  }

  if (restpoint.value >= 10 ) {
    num_10.value = Math.floor(restpoint.value / 10);
    restpoint.value = restpoint.value % 10;
  }

  if(num_50.value >= 1){

  }
  changemoney.value =( num_200.value * 50000 ) + ( num_100.value * 23000 ) + ( num_50.value * 11000 ) + ( num_10.value * 2000 );
   
  can_change_point.value = total_point - restpoint.value;
  console.log("num_10:", num_10.value);
  console.log("num_50:", num_50.value);
  console.log("num_100:", num_100.value);
  console.log("num_200:", num_200.value);

  num_10.value = 0;
  num_50.value = 0;
  num_100.value = 0;
  num_200.value = 0;
  
  return {
    changemoney,
    restpoint,
    can_change_point,
  };
};

export default getChangeMoney;
