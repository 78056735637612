<template>
  <div><button class="delete_return_btn" @click="pushReturn">戻る</button></div>
  <div class="delete_engineer_box">
    <div v-if="data_error">{{ data_error }}</div>
    <h3>エンジニアデータの削除</h3>
    <div v-if="defaultdata">
      <div class="full_part">
        <div class="entry_part">
          <div class="entry_item_name">
            <p>お名前</p>
            <div v-if="defaultdata">
              {{ defaultdata.last_name }}{{ defaultdata.first_name }}
            </div>
          </div>
          <div class="entry_item_name">
            <p>ふりがな</p>
            <div v-if="defaultdata">
              {{ defaultdata.last_name_kana }}{{ defaultdata.first_name_kana }}
            </div>
          </div>
          <div class="entry_item">
            <p>会社メールアドレス</p>
            <div v-if="defaultdata">{{ company_mail }}</div>
          </div>

          <div class="entry_item">
            <p>社員ID</p>
            <div v-if="defaultdata">{{ defaultdata.company_id }}</div>
          </div>

          <div class="entry_item">
            <p>所属地域</p>
            <div v-if="defaultdata">{{ defaultdata.area }}</div>
          </div>

          <div class="entry_item">
            <p>担当営業者</p>
            <div v-if="defaultdata">{{ defaultdata.pic }}</div>
          </div>

          <div class="entry_item">
            <p>入社日</p>
            <div v-if="defaultdata">
              {{ defaultdata.enter_year }}年{{ defaultdata.enter_month }}月{{
                defaultdata.enter_day
              }}日
            </div>
          </div>

          <div class="entry_item">
            <p>主要連絡メールアドレス</p>
            <div v-if="defaultdata">{{ defaultdata.major_mail }}</div>
          </div>

          <div class="entry_item">
            <p>連絡先電話番号</p>
            <div v-if="defaultdata">{{ defaultdata.phone }}</div>
          </div>
        </div>
        <div class="face_part">
          <div class="entry_item">
            <p>顔写真</p>
          </div>
        </div>
      </div>
      <div class="entry_part2">
        <div class="entry_item_address">
          <p>住所</p>
          <div v-if="defaultdata">{{ defaultdata.address }}</div>
        </div>

        <div class="entry_item">
          <p>生年月日</p>
          <div v-if="defaultdata">
            {{ defaultdata.birth_year }}年{{ defaultdata.birth_month }}月{{
              defaultdata.birth_day
            }}日
          </div>
        </div>

        <div class="entry_item">
          <p>緊急連絡先</p>
          <div v-if="defaultdata">{{ defaultdata.emergency_phone }}</div>
        </div>

        <div class="emergency">
          <div class="entry_item">
            <p>緊急連絡先の方のお名前</p>
            <div v-if="defaultdata">{{ defaultdata.emergency_name }}</div>
          </div>

          <div class="entry_item">
            <p>族柄</p>
            <div v-if="defaultdata">{{ defaultdata.emergency_relation }}</div>
          </div>
        </div>
        <button class="delete_button" @click="deleteEngineer">削除</button>
      </div>

    
    </div>
    <div v-else class="pulse-box"><Pulse /></div>
  </div>
</template>

<script>
import getDefaultData from "../composables/getDefaultData";
import getDefaultDatas from "../composables/getDefaultDatas";
import { projectFirestore } from "@/firebase/config";
import { ref } from "vue";
import Pulse from "../Pulse.vue";

export default {
  props: ["engineer_doc_id"],
  components: { Pulse },
  name: "DeleteEngineer",
  data() {
    return {};
  },

  setup(props,context) {
    const filtered_holiday_app = ref([]);
    const filtered_points = ref([]);
    const engineer = ref("");

    const {
      defaultdatas: holiday_app_datas,
      datas_error,
      datas_load: holidayapp_load,
    } = getDefaultDatas("holiday_application");
    holidayapp_load();

    const { defaultdatas: points_datas, datas_load: points_load } =
      getDefaultDatas("points");
    points_load();

    const { defaultdata, data_error, data_load } = getDefaultData(
      "engineers",
      props.engineer_doc_id
    );
    data_load();

    const deleteEngineer = async () => {
      //engineersデータからの削除
      try {
        engineer.value = defaultdata.value.company_id.replace(
          "@mik-s.work",
          ""
        );
        //defaultdataの削除
        await projectFirestore
          .collection("engineers")
          .doc(props.engineer_doc_id)
          .delete();

        //holidayDataの削除
        await projectFirestore
          .collection("holidayData")
          .doc(engineer.value)
          .delete();

        //skillDataの削除
        await projectFirestore
          .collection("skillData")
          .doc(engineer.value)
          .delete();
        //pointDataの削除
        await projectFirestore
          .collection("pointData")
          .doc(engineer.value)
          .delete();

        //各種データの削除
        //holiday_applicationデータの削除
        filtered_holiday_app.value = holiday_app_datas.value.filter((item) => {
          return item.userid === engineer.value;
        });
        console.log("engineer.value:", engineer.value);
        console.log("holiday_app_datas.value:", holiday_app_datas.value);
        console.log("filtered_holiday_app:", filtered_holiday_app.value);
        filtered_holiday_app.value.forEach(async (item) => {
          try {
            await projectFirestore
              .collection("holiday_application")
              .doc(item.id)
              .delete();

            console.log(item.id);
          } catch (err) {
            console.error("Error deleting item:", err);
          }
        });
        //該当ユーザーのポイントデータの削除
        filtered_points.value = points_datas.value.filter((item) => {
          return item.point_user_id === engineer.value;
        });

        filtered_points.value.forEach(async (item) => {
          try {
            await projectFirestore.collection("points").doc(item.id).delete();
          } catch (err) {
            console.error("Error deleting item:", err);
          }
        });

        // 該当ユーザーのログインアカウントの削除
        //TO DO:Firebase SDKを用いて実装の必要あり

        //削除画面を閉じる
        deleteClose(emit);
      } catch (error) {
        console.error("エンジニアの削除中にエラーが発止しました", error);
      }
    };

    const emit = context.emit;
    const deleteClose = (emit) => {
      emit("delete_view_close");
    };

    return { defaultdata, data_error, deleteEngineer };
  },
  methods: {
    pushReturn() {
      this.$emit("return-button-click");
    },
  },
};
</script>

<style>


.delete_engineer_box {
  width: 100%;
  background: white;
  border-radius: 10px;
  margin:20px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  text-align: center;

}

.delete_engineer_box h3 {
  align-self: flex-start;
  margin-top:50px;
  margin-left: 62px;
}

.entry_part {
  margin-left: 62px;
  width: 500px;
}
.entry_part2 {
  margin-left: 62px;
}
.entry_item {
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}
.entry_item p {
  font-size: 14px;
  margin: 0;
}

.entry_item input {
  width: 259px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}
.entry_item_name p {
  font-size: 14px;
  margin: 0;
}
.entry_item_name {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}

.entry_item_name input {
  width: 159px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
}

.entry_item_address {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}
.entry_item_address p {
  font-size: 14px;
  margin: 0;
}

.entry_item_address input {
  width: 550px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.emergency {
  display: flex;
}
.emergency .entry_item {
  width: 270px;
}

.face_up_box {
  width: 260px;
  height: 200px;
  background: #e3f5ff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #105ef5;
  font-size: 14px;
}

.delete_button {
  width: 241px;
  height: 33px;
  align-self: center;
  background: #f53e10;
  border: none;
  color: white;
  border-radius: 10px;
  margin-top: 62px;
  margin-bottom: 41px;
  cursor: pointer;
}

.full_part {
  display: flex;
  margin-top: 30px;
}
.delete_return_btn {
  width: 139px;
  height: 37px;
  background: #2c3e5062;
  color: white;
  border: none;
  border-radius: 7px;
  margin: 20px 0 0 20px;
}


</style>
