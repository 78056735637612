<template>
    <div class="page">
        <div class="content">
            <h1>給与明細データ</h1>

            <p>該当月のボタンを押すと、PDFが別タブに表示されます。</p>
            <div class="line"></div>
            <div class="button_frame">

                <div>
                    <h3>{{ currentYear }}</h3>
                </div>
                <div class="buttons">
                    <div v-for="(item, index) in months" :key="index">
                        <button class="month_button" @click="handleOpenPdf(currentYear, item.month)"
                            :disabled="!item.enabled">{{ item.month }}月</button>


                    </div>
                </div>
            </div>
            <div @click="gotoUserHome" class="return_button">
                <h5>戻る</h5>
            </div>
        </div>
    </div>
</template>

<script>
import getDatas from '@/components/composables/getDatas';
import getDefaultData from '@/components/composables/getDefaultData';
import router from '@/router';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

export default {
    props: ["engineer_id"],
    data() {
        return {};
    },
    setup(props) {


        const route = useRoute();
        const { datas, load } = getDatas("pay_data_urls", route.query.id);
        const months = ref(Array.from({ length: 12 }, (_, i) => ({ month: i + 1, enabled: false })));
        const checkDataAvalability = () => {
            months.value.forEach(item => {
                item.enabled = checkMonthData(currentYear, item.month)
            })
        };

        const checkMonthData = (year, month) => {
            const paydata = datas.value.find(data => {
                const dataYear = Number(data.year)
                const dataMonth = Number(data.month)
                return dataYear === year && dataMonth === month
            });
            return !!paydata;
        }

        onMounted(async () => {
            await load();
            checkDataAvalability();
        })


        const currentYear = new Date().getFullYear();

        const handleOpenPdf = async (year, month) => {
            await load();
            const paydata = datas.value.find(data => {
                const dataYear = Number(data.year)
                const dataMonth = Number(data.month)
                return dataYear === year && dataMonth === month
            })

            console.log("paydata", paydata)
            if (paydata) {
                console.log("url", paydata.url)

                window.open(paydata.url, "_blank");
            } else {
                console.log("選択されたPaydataが見つかりません。")
            }

        }

        const gotoUserHome = () => {
            router.push({ name: "User_Home" })
        }

        return {
            months,
            currentYear,
            handleOpenPdf,
            gotoUserHome,


        }
    }
}
</script>

<style scoped>
.page {
    width: 100%;
    height: 100vh;
    background-color: #f8ffe4;
    display: flex;
    justify-content: center;
}

.content {
    width: 600px;
    height: auto;
    background-color: #fff;
    text-align: center;

}

.line {
    width: 90%;
    border-top: 1px solid #ddd;
}

.month_button {
    width: 170px;
    height: 50px;
    background: #c9e265;
    margin: 20px;
    border-radius: 5px;
    border: none;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

}

.month_button:disabled {
    width: 170px;
    height: 50px;
    background: #e6e6e3;
    margin: 20px;
    border-radius: 5px;
    border: none;
    font-size: 15px;
    font-weight: bold;
    cursor: not-allowed;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

}

.button_frame {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
    flex-direction: column;

}

.buttons {
    width: 550px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.return_button {
    cursor: pointer;
}
</style>