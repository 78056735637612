const getCountHolidayforAdd = (enter_date, calc_year) => {
  let enter = null;

  if (typeof enter_date === "string") {
    enter = new Date(enter_date);
  } else {
    const enterDate = enter_date.seconds;
    enter = new Date(enterDate * 1000);
  }

  console.log("enter_date",enter_date)
  console.log("enter",enter)

  const base = new Date(enter.setMonth(enter.getMonth() + 6));
  const today = new Date();
  const base_date = base.toLocaleDateString("sv-SE");

  const fixed_base = new Date(calc_year, base.getMonth(), base.getDate());
  console.log("calc_year:", calc_year);

  console.log("fixed_base:", fixed_base);

  let next_add_date = "";
  let diff_year = 0;


  diff_year = fixed_base.getFullYear() - base.getFullYear();
 

  console.log("next_add_date", next_add_date);

  let num_of_add = 0;
  if (diff_year < 0) {
    num_of_add = 0;
  } else {
    if (diff_year <= 6) {
      switch (diff_year) {
        case 0:
          //6ヶ月目
          num_of_add = 10;
          break;
        case 1:
          //１年6ヶ月目
          num_of_add = 11;
          break;
        case 2:
          //2年6ヶ月目
          num_of_add = 12;
          break;
        case 3:
          //3年6ヶ月目
          num_of_add = 14;
          break;
        case 4:
          //4年6ヶ月目
          num_of_add = 16;
          break;
        case 5:
          //5年6ヶ月目
          num_of_add = 18;
          break;
        case 6:
          //6年6ヶ月目
          num_of_add = 20;
          break;
        default:
          num_of_add = 0;
          break;
      }
    } else {
      num_of_add = 20;
    }
  }

  console.log("diff_year:", diff_year);
  console.log("num_of_add:", num_of_add);

  return { base_date, next_add_date, num_of_add,diff_year };
};

export default getCountHolidayforAdd
