<template>
  <div class="status_box">
    <select
      v-model="user_pic"
      class="select"
      :style="{ background: activeColor }"
      @change="handlePicChange(user_pic)"
    >
      <option value="kiyanagi">鬼柳</option>
      <option value="hata">畑</option>
      <option value="oda">小田</option>
    </select>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import useUpdateData from '../composables/userUpdateData';
export default {
  name: "DefaultStatus",
  props: ["id","pic"],
  setup(props, context) {
    const user_pic = ref(props.pic);
    const activeColor = ref("");
 

    if (user_pic.value === "kiyanagi") {
      activeColor.value = "#2cb67d";
    } else if (user_pic.value === "oda") {
      activeColor.value = "#e16162";
    } else if (user_pic.value === "hata") {
      activeColor.value = "#6246ea";
    }

    watch(
      () => user_pic.value,
      (newPIC) => {
        if (newPIC === "kiyanagi") {
          activeColor.value = "#2cb67d";
        } else if (newPIC === "oda") {
          activeColor.value = "#e16162";
        } else if (newPIC === "hata") {
          activeColor.value = "#6246ea";
        }
      }
    );

    const handlePicChange = (pic) =>{
      const post = {
        pic:pic,
      }
       const collection_array = ["engineers","holidayData","skillData","pointData","payData"]

      const {error, updateData} = useUpdateData()
      updateData(collection_array,post,props.id);
    }

    return {
      user_pic,
      activeColor,
      handlePicChange,
    };
  },
};
</script>

<style>
.select {
  appearance: none;
  border: none;
  width: 70px;
  height: 34px;
  border-radius: 30px;
  text-align: center;
  color: white;
  font-weight: bold;
  outline: none;
}
.status_box {
  display: flex;
  justify-content: center;
  align-items: center;
}


</style>
