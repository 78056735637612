<template>
  <div><button class="delete_return_btn" @click="pushReturn">戻る</button></div>
  <div class="add_engineer_box">
    <div v-if="data_error">{{ data_error }}</div>
    <h3>エンジニアデータの修正</h3>
    <div v-if="defaultdata">
      <form @submit.prevent="handleSubmit">
        <div class="full_part">
          <div class="entry_part">
            <div class="entry_item_name">
              <label>お名前</label>
              <div v-if="defaultdata">
                <input type="text" v-model="defaultdata.last_name" />
                <input type="text" v-model="defaultdata.first_name" />
              </div>
            </div>
            <div class="entry_item_name">
              <label>ふりがな</label>
              <div v-if="defaultdata">
                <input type="text" v-model="defaultdata.last_name_kana" />
                <input type="text" v-model="defaultdata.first_name_kana" />
              </div>
            </div>
            <div class="entry_item" v-if="defaultdata">
              <label>会社メールアドレス</label>
              <input type="text" v-model="defaultdata.company_mail" />
            </div>

            <div class="entry_item" v-if="defaultdata">
              <label>社員ID</label>
              <input type="text" v-model="defaultdata.company_id" />
            </div>

            <div class="radio_item">
              <label>所属地域</label>
              <div class="radio">
                <input
                  type="radio"
                  name="area"
                  value="sapporo"
                  v-model="defaultdata.area"
                />札幌
              </div>
              <div class="radio">
                <input
                  type="radio"
                  name="area"
                  value="morioka"
                  v-model="defaultdata.area"
                />盛岡
              </div>
              <div class="radio">
                <input
                  type="radio"
                  name="area"
                  value="oosaka"
                  v-model="defaultdata.area"
                />大阪
              </div>
              <div class="radio">
                <input
                  type="radio"
                  name="area"
                  value="fukuoka"
                  v-model="defaultdata.area"
                />福岡
              </div>
            </div>

            <div class="entry_item" v-if="defaultdata">
              <label>担当営業者</label>
              <input type="text" v-model="defaultdata.pic" />
            </div>

            <div class="entry_item" v-if="defaultdata">
              <label>入社日</label>
              <input type="date" v-model="defaultdata.enter_date" />
              <div class="enter_date">
                <input
                  type="number"
                  min="2019"
                  max="2050"
                  v-model="defaultdata.enter_year"
                />年
                <input
                  type="number"
                  v-model="defaultdata.enter_month"
                  min="1"
                  max="12"
                />月
                <input
                  type="number"
                  v-model="defaultdata.enter_day"
                  min="1"
                  max="31"
                />日
              </div>
            </div>

            <div class="radio_item" v-if="defaultdata">
              <label>現在のステータス</label>
              <div class="radio">
                <input
                  type="radio"
                  name="status"
                  value="1"
                  v-model="defaultdata.status"
                  @input="updateStatus(1)"
                />就業中
              </div>
              <div class="radio">
                <input
                  type="radio"
                  name="status"
                  value="2"
                  v-model="defaultdata.status"
                  @input="updateStatus(2)"
                />就業先選定中
              </div>
              <div class="radio">
                <input
                  type="radio"
                  name="status"
                  value="3"
                  v-model="defaultdata.status"
                  @input="updateStatus(3)"
                />研修期間中
              </div>
              <div class="radio">
                <input
                  type="radio"
                  name="status"
                  value="4"
                  v-model="defaultdata.status"
                  @input="updateStatus(4)"
                />休職中
              </div>
            </div>

            <div class="entry_item" v-if="defaultdata">
              <label>主要連絡メールアドレス</label>
              <input type="text" v-model="defaultdata.major_mail" />
            </div>

            <div class="entry_item" v-if="defaultdata">
              <label>連絡先電話番号</label>
              <input type="text" v-model="defaultdata.phone" />
            </div>
          </div>
          <div class="face_part">
            <div class="entry_item">
              <label>顔写真</label>
              <div class="face_up_box">
                <p>顔写真を選択してください png/jpeg</p>
                <input
                  type="file"
                  id="avater"
                  name="avater"
                  accept="image/png, image/jpeg"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="entry_part2">
          <div class="entry_item_address" v-if="defaultdata">
            <label>住所</label>
            <input type="text" v-model="defaultdata.address" />
          </div>

          <div class="entry_item" v-if="defaultdata">
            <label>生年月日</label>
            <div class="enter_date">
              <input
                type="number"
                min="1960"
                max="2050"
                v-model="defaultdata.birth_year"
              />年
              <input
                type="number"
                v-model="defaultdata.birth_month"
                min="1"
                max="12"
              />月
              <input
                type="number"
                v-model="defaultdata.birth_day"
                min="1"
                max="31"
              />日
            </div>
          </div>

          <div class="entry_item" v-if="defaultdata">
            <label>緊急連絡先</label>
            <input type="text" v-model="defaultdata.emergency_phone" />
          </div>

          <div class="emergency" v-if="defaultdata">
            <div class="entry_item">
              <label>緊急連絡先の方のお名前</label>
              <input type="text" v-model="defaultdata.emergency_name" />
            </div>

            <div class="entry_item" v-if="defaultdata">
              <label>族柄</label>
              <input type="text" v-model="defaultdata.emergency_relation" />
            </div>
          </div>
        </div>
        <button class="fix_button">修正する</button>
      </form>
    </div>
    <div v-else class="pulse-box"><Pulse /></div>
  </div>
</template>

<script>
import { ref } from "vue";
import getDefaultData from "../composables/getDefaultData";
import { projectFirestore } from "@/firebase/config";
import Pulse from "../Pulse.vue";
import useStorage from "../composables/useStorage";

export default {
  name: "FixDefaultData",
  props: ["engineer_id"],
  components: { Pulse },
  data() {
    return {};
  },
  setup(props, context) {
    const { defaultdata, data_error, data_load } = getDefaultData(
      "engineers",
      props.engineer_id
    );

    let comp_fix = false;
    const emit = context.emit;
    const fixClose = (emit) => {
      const is_close = true;
      emit("fix_view_close", is_close);
    };

    data_load();

    const updateStatus = (newStatus) => {
      defaultdata.value.status = newStatus;
    };

    const handleSubmit = async () => {
      //顔写真データの設定
      const file = ref(null);
      const fileError = ref(null);

      const picture = document.getElementById("avater");
      const selected = picture.files[0];
      console.log(selected);

      if (selected && defaultdata.company_id) {
        const { url, uploadImage } = useStorage(defaultdata.value.company_id);

        file.value = selected;
        fileError.value = null;

        if (file.value) {
          await uploadImage(file.value);
          defaultdata.face_url = url.value;
          console.log("image uploaded, url:", url.value);
        }
      } else {
        file.value = null;
        defaultdata.face_url = null;
      }

      if (defaultdata.value) {
        const engineer_data = {
          last_name: defaultdata.value.last_name,
          first_name: defaultdata.value.first_name,
          last_name_kana: defaultdata.value.last_name_kana,
          first_name_kana: defaultdata.value.first_name_kana,
          address: defaultdata.value.address,
          area: defaultdata.value.area,
          face_url: defaultdata.value.face_url,
          phone: defaultdata.value.phone,
          status: defaultdata.value.status,
          pic: defaultdata.value.pic,
          enter_year: defaultdata.value.enter_year,
          enter_month: defaultdata.value.enter_month,
          enter_day: defaultdata.value.enter_day,
          enter_date:defaultdata.value.enter_date,
          birth_year: defaultdata.value.enter_year,
          birth_month: defaultdata.value.enter_month,
          birth_day: defaultdata.value.enter_day,
          company_id: defaultdata.value.company_id,
          company_mail: defaultdata.value.company_mail,
          major_mail: defaultdata.value.major_mail,
          emergency_name: defaultdata.value.emergency_name,
          emergency_phone: defaultdata.value.emergency_phone,
          emergency_relation: defaultdata.value.emergency_relation,
        };

        // 各フィールドが `undefined` でないことを確認
        for (const key in engineer_data) {
          if (engineer_data[key] === undefined) {
            delete engineer_data[key]; // `undefined` フィールドを削除
          }
        }

        if (Object.keys(engineer_data).length > 0) {
          try {
            const res = await projectFirestore
              .collection("engineers")
              .doc(props.engineer_id)
              .update(engineer_data);
            console.log("書き込みに成功しました。");
            console.log({ res });

            comp_fix = true;

            fixClose(emit);
          } catch (err) {
            console.error("データ書き込みエラー：", err);
          }
        }
      }
    };

    return {
      comp_fix,
      defaultdata,
      data_error,
      handleSubmit,
      updateStatus,
    };
  },
  methods: {
    pushBack() {
      const on_push = true;
      this.$emit("edit-to-description", on_push);
    },
    pushReturn() {
      this.$emit("return-button-click");
    },
  },
};
</script>

<style>
.entry_part {
  margin-left: 62px;
  width: 500px;
}
.entry_part2 {
  margin-left: 62px;
}
.entry_item {
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}
.entry_item p {
  font-size: 14px;
  margin: 0;
}

.entry_item input {
  width: 259px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}
.entry_item_name p {
  font-size: 14px;
  margin: 0;
}
.entry_item_name {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}

.entry_item_name input {
  width: 159px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
}

.entry_item_address {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}
.entry_item_address p {
  font-size: 14px;
  margin: 0;
}

.entry_item_address input {
  width: 550px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.emergency {
  display: flex;
}
.emergency .entry_item {
  width: 270px;
}

.face_up_box {
  width: 260px;
  height: 200px;
  background: #e3f5ff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #105ef5;
  font-size: 14px;
}

.add_engineer_box button {
  width: 241px;
  height: 33px;
  align-self: center;
  background: #f5c310;
  border: none;
  color: white;
  border-radius: 10px;
  margin-top: 62px;
  margin-bottom: 41px;
}

.full_part {
  display: flex;
  margin-top: 30px;
}

.radio_item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.radio {
  margin: 5px 0;
  font-size: 13px;
}

.radio_item p {
  font-size: 14px;
  margin: 0 0 10px 0;
}

.enter_date {
  display: flex;
  align-items: center;
  width: 30%;
}

.discription_circle_button {
  position: absolute;
  right: 80px;
  top: 120px;
  background: #8d8c89;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  font-size: 24px;
}

.fix_button {
}
</style>
