<template>
  <div class="default_data">
    <div class="data_title_box">
      <h2>Engineer Data</h2>

      <button class="filter" @click="handleFilter">
        Filter<span class="material-symbols-outlined"> filter_alt </span>
      </button>
    </div>

    <table>
      <tr>
        <th>#</th>
        <th>Engineer Name</th>
        <th>
          <div v-if="!isFilter">Area</div>
          <div v-else>
            Area<select name="area" v-model="area_selected" @change="handleAreaChange" class="table_title_select">
              <option disabled value="">選択</option>
              <option value="sapporo">札幌</option>
              <option value="morioka">盛岡</option>
              <option value="oosaka">大阪</option>
              <option value="fukuoka">福岡</option>
              <option value="all">すべて</option>
            </select>
          </div>
        </th>
        <th>
          <div v-if="!isFilter">担当者</div>
          <div v-else>
            担当者<select name="pic" v-model="pic_selected" @change="handlePICChange" class="table_title_select">
              <option disabled value="">選択</option>
              <option value="kiyanagi">鬼柳</option>
              <option value="hata">畑</option>
              <option value="oda">小田</option>
              <option value="all">すべて</option>
            </select>
          </div>
        </th>
        <th>EngineerId</th>
        <th>
          <div v-if="!isFilter">Enter Date</div>
          <div v-else>
            Enter Date<select name="enter_date" v-model="enter_selected" @change="handleEnterDate"
              class="table_title_select">
              <option disabled value="">選択</option>
              <option value="1">降順↓</option>
              <option value="2">昇順↑</option>
            </select>
          </div>
        </th>
        <th>
          <div v-if="!isFilter">Status</div>
          <div v-else>
            Status<select name="status" @change="handleChangeStatus" v-model="status_selected"
              class="table_title_select">
              <option disabled value="">選択</option>
              <option value="1">稼働中</option>
              <option value="2">待機中</option>
              <option value="3">研修中</option>
              <option value="4">休職中</option>
              <option value="5">すべて</option>
            </select>
          </div>
        </th>
      </tr>

      <tr v-for="(defaultdata, index) in currentPageItems" :key="defaultdata.id">
        <td>
          <div v-if="!isSelect">
            {{ (index + 1).toString().padStart(2, "0") }}
          </div>
          <div v-if="is_onEdit || is_onDelete">
            <input name="engineer_check" type="checkbox" class="edit_check" v-model="defaultdata.isSelected"
              @change="engineerCheck(defaultdata.id)" />
          </div>
        </td>
        <td>
          <div class="table_item">
            <div class="default_engineer_name_frame" @click="engineerNameCheck(defaultdata.id)">
              <div v-if="defaultdata.face_url">
                <img :src="defaultdata.face_url" class="face" />
              </div>
              <div v-else>
                <img src="@/assets/mik_green.png" class="face" />
              </div>
              <div class="engineer_name">
                {{ defaultdata.last_name }} {{ defaultdata.first_name }}
              </div>
            </div>
          </div>
        </td>
        <td>
          <div class="table_item">
            <AreaStatus :id="defaultdata.id" :area="defaultdata.area" />
          </div>
        </td>
        <td>
          <div class="table_item">
            <PICStatus :id="defaultdata.id" :pic="defaultdata.pic" />
          </div>
        </td>
        <td>
          <div class="table_item">{{ defaultdata.company_id }}</div>
        </td>
        <td>
          <div class="table_item">
            <div v-if="defaultdata && defaultdata.enter_date">
              <div v-if="typeof defaultdata.enter_date === 'string'">
                {{ defaultdata.enter_date }}
              </div>
              <div v-else>
                {{
                  defaultdata.enter_date.toDate().toLocaleDateString("sv-SE")
                }}
              </div>
            </div>
          </div>
        </td>
        <td>
          <div class="table_item">
            <DefaultStatus :id="defaultdata.id" :status="defaultdata.status" />
          </div>
        </td>
      </tr>
    </table>

    <div class="data_footer">
      <div class="previous_frame">
        <button class="previous_button" @click="prevPage" v-if="currentPage !== 1">
          <span class="material-symbols-outlined"> west </span>Previous
        </button>
      </div>
      <div class="pagenation">
        <ul>
          <li v-for="page in totalPages" :key="page" @click="changePage(page)" :class="{ 'active': page === currentPage }">
            <div class="page_number">{{ page }}</div>
          </li>
        </ul>

      </div>
      <div class="next_frame">
        <button class="next_button" @click="nextPage" v-if="currentPage !== totalPages">
          Next<span class="material-symbols-outlined"> east </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultStatus from "./DefaultStatus.vue";
import AreaStatus from "./AreaStatus.vue";
import { ref, onMounted, watch, toRef } from "vue";
import PICStatus from "./PICStatus.vue";

export default {
  props: {
    isSelect: Boolean,
    defaultdatas: {
      type: Array,
      default: () => [],
    },
    is_onEdit: Boolean,
    is_onDelete: Boolean,
    reloadDefaultData: Function,
    now_page: Number,
    search_item: Boolean,
  },
  name: "EngineerDefaultData",
  components: {
    DefaultStatus,
    AreaStatus,
    PICStatus,
  },
  data() {
    return {
      itemsPerPage: 6,

      item_num: 1,
      selectedItems: [],
      sortedItems: [],
      isFilter: false,
      area_selected: null,
      pic_selected: null,
      enter_selected: null,
      status_selected: 0,
    };
  },
  computed: {
    currentPageItems() {
      if (this.defaultdatas) {
        console.log("this.defulta", this.defaultdatas);
        if (!this.enter_selected) {
          this.defaultdatas.sort((b, a) => b.enter_date - a.enter_date);
        }

        // let startIndex;
        // let endIndex;
        // if (this.search_item.length > 0) {
        //   if (this.is_firstsearch) {
        //     startIndex = 0;
        //     this.is_firstsearch = false;

        //   }else{
        //   startIndex = (this.currentPage - 1) * this.itemsPerPage;
        //   }
        //   endIndex = startIndex + this.itemsPerPage;
        // } else {

        // }

        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        console.log("startIndex", startIndex);
        console.log("endIndex", endIndex);
        console.log("is_firstsearch", this.is_firstsearch);
        return this.defaultdatas.slice(startIndex, endIndex);
      } else {
        return [];
      }
    },

    totalPages() {
      if (this.defaultdatas) {
        return Math.ceil(this.defaultdatas.length / this.itemsPerPage);
      }
      return 0;
    },
  },

  setup(props) {
    const currentPage = ref(1);
    const is_firstsearch = ref(true);

    const changePage = (pageNumber) => {
      currentPage.value = pageNumber;
      console.log("page", pageNumber);
      is_firstsearch.value = true;
    };
    if (props.now_page === 0) {
      changePage(1);
    } else {
      changePage(props.now_page);
    }

    // const handleDataPicChange = async (pic,id) =>{
    //   const post = {
    //     pic:pic,
    //   }
    //   console.log("edd_pic",pic)
    //   console.log("edd_id",id)

    //   try{
    //     await projectFirestore
    //     .collection("engineers")
    //     .doc(id)
    //     .update(post)

    //     await projectFirestore
    //     .collection("holidayData")
    //     .doc(id)
    //     .update(post)

    //     await projectFirestore
    //     .collection("skillData")
    //     .doc(id)
    //     .update(post)

    //     await projectFirestore
    //     .collection("pointData")
    //     .doc(id)
    //     .update(post)

    //     await projectFirestore
    //     .collection("payData")
    //     .doc(id)
    //     .update(post)
    //   }catch(err){
    //     console.log("担当者を変更できませんでした",err)
    //   }

    // }

    // const handleDataAreaChange = async (area,id) =>{
    //   console.log("EDD area")
    //   const post= {
    //     area:area
    //   }

    //   const collection_array = ["engineers","holidayData","skillData","pointData","payData"]

    //   const {error, updateData} = useUpdateData()
    //   updateData(collection_array,post,id);

    // }

    watch(
      () => props.defaultdatas.value,
      (newDefaultDatas) => {
        if (newDefaultDatas !== null) {
          props.defaultdatas.value = newDefaultDatas;
        }
      }
    );
    // onMounted(() => {
    //   if (props.reloadDefaultData) {
    //     props.reloadDefaultData();
    //     console.log("mount");
    //   }
    // });

    return {
      currentPage,
      changePage,
      is_firstsearch,
    };
  },

  methods: {
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    engineerCheck(id) {
      if (this.selectedItems.length === 0) {
        this.selectedItems.push(id);
      } else {
        this.selectedItems.shift();
        this.selectedItems.push(id);
      }
      this.currentPageItems.forEach((defaultdata) => {
        if (!this.selectedItems.includes(defaultdata.id)) {
          defaultdata.isSelected = false;
        }
      });
      this.$emit("selected_engineer_check", this.selectedItems[0]);
      console.log(this.selectedItems);
    },
    engineerNameCheck(id) {
      if (this.selectedItems.length === 0) {
        this.selectedItems.push(id);
      } else {
        this.selectedItems.shift();
        this.selectedItems.push(id);
      }
      this.currentPageItems.forEach((defaultdata) => {
        if (!this.selectedItems.includes(defaultdata.id)) {
          defaultdata.isSelected = false;
        }
      });
      console.log("current", this.currentPage);
      this.$emit(
        "selected_engineer_name",
        this.selectedItems[0],
        this.currentPage
      );
    },
    handleFilter() {
      this.isFilter = !this.isFilter;
    },
    handleAreaChange() {
      this.$emit("area_selected", this.area_selected);
    },
    handlePICChange() {
      this.$emit("pic_selected", this.pic_selected);
    },
    handleEnterDate() {
      this.$emit("enter_date_selected", this.enter_selected);
    },
    handleChangeStatus() {
      this.$emit("status_selected", this.status_selected);
    },
  },
};
</script>

<style>
.data_title_box {
  width: 1042px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.data_title_box button {
  width: 102px;
  height: 44px;
  border-radius: 12px;
  border: 1px solid #cdcde3;
  color: #a8a8bd;
  line-height: 19.6px;
  word-wrap: break-word;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter .material-symbols-outlined {
  color: #a8a8bd;
  font-size: 20px;
  margin-left: 5px;
}

.default_data {
  width: 1135px;
  height: 652px;
  border-radius: 16px;
  background: white;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.default_data table {
  width: 942px;
  height: 492px;
  border-collapse: collapse;
  column-width: 100px;
}

.default_data td {
  border-top: 1px solid #ddd;
}

.default_data .engineer_name_frame {
  display: flex;
  align-items: center;
}

.default_engineer_name_frame {
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin: 0 30px 0 30px;
  justify-content: center;
  cursor: pointer;
}

.default_engineer_name_frame :hover {
  font-weight: bold;
}

.default_data .engineer_name {
  width: 120px;
  display: flex;
  justify-content: flex-start;
  margin-left: 5px;
  cursor: pointer;
}

.default_data .face {
  width: 40px;
  height: 40px;

  border-radius: 20px;
  cursor: pointer;
}

.data_footer {
  display: flex;
  margin-top: 22px;
  width: 1042px;
  height: 40px;

  justify-content: space-between;
  align-items: center;
}

.data_footer ul {
  display: flex;
  list-style: none;
  width: 292px;
  padding: 0;
  justify-content: space-around;
}

.data_footer li {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
}

.data_footer button {
  appearance: none;
  border: none;
  background: white;
  font-size: 16px;
}

.data_footer li :hover {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #006aff;
  background: rgba(0, 106, 255, 0.5);
  border: none;
}

.previous_button {
  width: 114px;
  height: 36px;
  color: #2c3e50;
  border: 1px solid #cdcde3;
  background: white;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.previous_frame {
  width: 114px;
}

.next_button {
  width: 88px;
  height: 36px;
  color: #2c3e50;
  border: 1px solid #cdcde3;
  background: white;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.next_frame {
  width: 88px;
}

.material-symbols-outlined {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
}

.data_footer .material-symbols-outlined {
  font-size: 16px;
  color: #2c3e50;
  width: 18px;
  display: flex;
  align-items: center;
}

.edit_check {
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #2c3e50;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.edit_check:checked {
  background: #58769f;
}

.table_item {
  text-align: center;
}

.table_title_select {
  border: none;
  outline: none;
}

.pagenation li {
  margin: 0 10px;
}

.page_number {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.pagenation li.active .page_number {
  font-weight: bold;
}
</style>
