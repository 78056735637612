<template>
  <div class="status_box">
    <select
      v-model="user_status"
      class="select"
      :style="{ background: activeColor }"
      @change="handleStatusChange(user_status)"
    >
    <option value="enter">今月入社</option>
      <option value="work">稼働中</option>
      <option value="stay1">待機中</option>
      <option value="stay2">研修中</option>
      <option value="stay3">休職中</option>
      <option value="retire">退職</option>
      <option value="other">その他</option>
    </select>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import useUpdateData from "../composables/userUpdateData";
export default {
  name: "DefaultStatus",
  props: ["id", "status"],
  setup(props, context) {
    const user_status = ref(props.status);
    const activeColor = ref("");

    const { error, updateData } = useUpdateData();

    if (user_status.value === "work") {
      activeColor.value = "#ffd803";
    } else if (user_status.value === "enter") {
      activeColor.value = "#EBA5E5";
    } else if (user_status.value === "stay1") {
      activeColor.value = "#c3f0ca";
    } else if (user_status.value === "stay2") {
      activeColor.value = "#8bd3dd";
    } else if (user_status.value === "stay3") {
      activeColor.value = "#72757e";
    } else if (user_status.value === "other") {
      activeColor.value = "#82837A";
    } else if (user_status.value === "retire") {
      activeColor.value = "black";
   
    }

    watch(
      () => user_status.value,
      (newStatus) => {
        if (newStatus === "work") {
          activeColor.value = "#ffd803";
        } else if (user_status.value === "enter") {
      activeColor.value = "#EBA5E5";
        } else if (newStatus === "stay1") {
          activeColor.value = "#c3f0ca";
        } else if (newStatus === "stay2") {
          activeColor.value = "#8bd3dd";
        } else if (newStatus === "stay3") {
          activeColor.value = "#72757e";
        } else if (user_status.value === "other") {
      activeColor.value = "#82837A";
        } else if (user_status.value === "retire") {
          activeColor.value = "black";
     
        }
      }
    );

    const handleStatusChange = (status) => {
      const collection_array = ["engineers","skillData","pointData","payData"];

      const post = {
        status: status,
      };
      updateData(collection_array, post, props.id);

      const second_data_array = ["holidayData"];

      const holiday_post = {
      work_status: status,
    };
    updateData(second_data_array, holiday_post, props.id);
    };

    return {
      user_status,
      activeColor,
      handleStatusChange,
    };
  },
};
</script>

<style>
.status_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.status_working {
  width: 70px;
  height: 34px;
  border-radius: 30px;
  background: #ffd803;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}
.status_learning {
  width: 70px;
  height: 34px;
  border-radius: 30px;
  background: #c3f0ca;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}
.status_stay {
  width: 70px;
  height: 34px;
  border-radius: 30px;
  background: #8bd3dd;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}
.status_resting {
  width: 70px;
  height: 34px;
  border-radius: 30px;
  background: #72757e;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}
</style>
