<template>
  <div class="holiday_data">
  
    <div class="data_title_box">
      <h2>有給データ</h2>
    

      <button class="filter">
        Filter<span class="material-symbols-outlined"> filter_alt </span>
      </button>
    </div>
   
    <table>
      <tr>
        <th>#</th>
        <th>Engineer Name</th>
        <th>有給残日数</th>
        <th>次回有給付与日</th>
        <th>次回付与日数</th>
        <th>使用状況</th>
      </tr>
      <tr
        v-for="(defaultdata, index) in currentPageItems"
        :key="defaultdata.id"
      >
        <td>
          {{ (index + 1).toString().padStart(2, "0") }}
        </td>
        <td>
          <div
            class="default_engineer_name_frame"
            @click="engineerCheck(defaultdata.id)"
          >
            <div v-if="defaultdata.face_url">
              <img :src="defaultdata.face_url" class="face" />
            </div>
            <div v-else><img src="@/assets/mik_green.png" class="face" /></div>
            <div class="engineer_name">
              {{ defaultdata.last_name }} {{ defaultdata.first_name }}
            </div>
          </div>
        </td>
        <td>
          <div v-if="defaultdata.remain_holiday">
            <div class="table_item">{{ defaultdata.remain_holiday }}日</div>
          </div>
          <div v-else class="table_item">未設定</div>
        </td>
        <td>
          <div v-if="defaultdata.next_add" class="table_item">
            {{ defaultdata.next_add }}
          </div>
          <div v-else class="table_item">未設定</div>
        </td>

        <td>
          <div v-if="defaultdata.num_of_add" class="table_item">
            {{ defaultdata.num_of_add }}日
          </div>
          <div v-else class="table_item">未設定</div>
        </td>

        <td>
          <div class="table_item">
            <HolidayStatus :status_number="defaultdata.status" />
          </div>
        </td>
      </tr>
    </table>

    <div class="data_footer">
      <div class="previous_frame">
        <button
          class="previous_button"
          @click="prevPage"
          v-if="currentPage !== 1"
        >
          <span class="material-symbols-outlined"> west </span>Previous
        </button>
      </div>

      <ul>
        <li v-for="page in totalPages" :key="page" @click="changePage(page)">
          <button>{{ page }}</button>
        </li>
      </ul>
      <div class="next_frame">
        <button
          class="next_button"
          @click="nextPage"
          v-if="currentPage !== totalPages"
        >
          Next<span class="material-symbols-outlined"> east </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import HolidayStatus from "./HolidayStatus.vue";
import DefaultStatus from "./HolidayStatus.vue";
import { ref, onMounted, watch, toRef } from "vue";

export default {
  props: {
    isSelect: Boolean,
    defaultdatas: {
      type: Array,
      default: () => [],
    },
    searchdatas: {
      type: Array,
      default: () => [],
    },
    isFix: Boolean,
    reloadDefaultData: Function,
  },
  name: "EngineerDefaultData",
  components: {
    DefaultStatus,
    HolidayStatus,
  },
  data() {
    return {
      itemsPerPage: 6,
      currentPage: 1,
      item_num: 1,
      selectedItems: [],
    };
  },
  computed: {
    currentPageItems() {
      if (this.defaultdatas) {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        return this.defaultdatas.slice(startIndex, endIndex);
      } else {
        return [];
      }
    },

    totalPages() {
      if (this.defaultdatas) {
        return Math.ceil(this.defaultdatas.length / this.itemsPerPage);
      }
      return 0;
    },
  },
  setup(props) {
    if (props.isFix) {
      console.log(props.defaultdatas);
      watch(
        () => props.defaultdatas.value,
        (newDefaultDatas) => {
          if (newDefaultDatas !== null) {
            props.defaultdatas.value = newDefaultDatas;
          }
        }
      );
      onMounted(() => {
        if (props.reloadDefaultData) {
          props.reloadDefaultData();
          console.log("mount");
        }
      });
    }
  },

  methods: {
    changePage(pageNumber) {
      this.currentPage = pageNumber;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    engineerCheck(id) {
      if (this.selectedItems.length === 0) {
        this.selectedItems.push(id);
      } else {
        this.selectedItems.shift();
        this.selectedItems.push(id);
      }
      this.currentPageItems.forEach((defaultdata) => {
        if (!this.selectedItems.includes(defaultdata.id)) {
          defaultdata.isSelected = false;
        }
      });

      console.log(this.selectedItems);
      this.$emit("selected_engineer_id", this.selectedItems[0]);
    },
  },
};
</script>

<style>
.data_title_box {
  width: 1042px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.data_title_box button {
  width: 102px;
  height: 44px;
  border-radius: 12px;
  border: 1px solid #cdcde3;
  color: #a8a8bd;
  line-height: 19.6px;
  word-wrap: break-word;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter .material-symbols-outlined {
  color: #a8a8bd;
  font-size: 20px;
  margin-left: 5px;
}
.holiday_data {
  width: 1135px;
  height: 652px;
  border-radius: 16px;
  background: white;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
}
.holiday_data table {
  width: 1042px;
  height: 492px;
  border-collapse: collapse;
}
.holiday_data td {
  border-top: 1px solid #ddd;
}
.holiday_data .engineer_name_frame {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  cursor: pointer;
}
.holiday_data .engineer_name {
  width: 150px;
  display: flex;
  justify-content: flex-start;
  margin-left: 5px;
}
.photo {
  width: 30px;
  height: 30px;
  background: #ddd;
  border-radius: 15px;
}

.holidaydata_button {
  width: 110px;
  height: 30px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.data_footer {
  display: flex;
  margin-top: 22px;
  width: 1042px;
  height: 40px;

  justify-content: space-between;
  align-items: center;
}
.data_footer ul {
  display: flex;
  list-style: none;
  width: 292px;
  padding: 0;
  justify-content: space-around;
}
.data_footer li {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
}
.data_footer button {
  appearance: none;
  border: none;
  background: white;
  font-size: 16px;
}
.data_footer li :hover {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #006aff;
  background: rgba(0, 106, 255, 0.5);
  border: none;
}
.previous_button {
  width: 114px;
  height: 36px;
  color: #2c3e50;
  border: 1px solid #cdcde3;
  background: white;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.previous_frame {
  width: 114px;
}
.next_button {
  width: 88px;
  height: 36px;
  color: #2c3e50;
  border: 1px solid #cdcde3;
  background: white;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.next_frame {
  width: 88px;
}

.material-symbols-outlined {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
}
.data_footer .material-symbols-outlined {
  font-size: 16px;
  color: #2c3e50;
  width: 18px;
  display: flex;
  align-items: center;
}

.edit_check {
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #2c3e50;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.edit_check:checked {
  background: #58769f;
}


</style>
