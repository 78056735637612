<template>


<div id="app">
  <router-view/>
</div>
 

</template>

<script>

export default {
  components: {


  },
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serrif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  color: #2c3e50;
  margin:0px;
}

#nav{
  padding: 30px;
}
</style>