<template>
  <form @submit.prevent="handleAbsentSubmit" class="absent_app_form">
    <h4>有給・欠勤登録</h4>

    <div>
      <div class="ab_form_content">
        <div class="ab_form_item">
          <h5>対象日</h5>
          <input type="date" v-model="day_of_absent" />
        </div>

        <div class="ab_form_item">
          <h5>有休の使用</h5>
          <div class="ab_form_column">
            <div>
              <input type="radio" name="is_holiday" :value="true" v-model="is_use_holiday" />使用する
            </div>
            <div>
              <input type="radio" name="is_holiday" :value="false" v-model="is_use_holiday" />使用しない
            </div>
          </div>
        </div>

        <div v-if="is_use_holiday === true" class="div_flex">
          <div class="ab_item_wrapper">
            <h5>使用方法</h5>
            <div class="ab_form_column">
              <div>
                <input type="radio" name="use_holiday" value="1" v-model="num_use_holiday" />全休取得
              </div>
              <div>
                <input type="radio" name="use_holiday" value="0.5" v-model="num_use_holiday" />半休取得
              </div>
            </div>
          </div>

          <div class="ab_form_item">
            <h5>理由</h5>
            <textarea v-model="absent_reason" class="ab_reason_area"></textarea>
          </div>

          <div class="ab_form_content">
            <div class="ab_form_item">
              <h5>申請日</h5>
              <input type="date" v-model="submit_application" />
            </div>
          </div>
        </div>

        <div v-if="is_use_holiday === false" class="div_flex">
          <div class="ab_form_item">
            <h5>種別</h5>
            <div class="ab_form_column">
              <div>
                <input type="radio" name="absent_type" value="1" v-model="type_of_absent" />全日休
              </div>
              <div>
                <input type="radio" name="absent_type" value="2" v-model="type_of_absent" />遅刻
              </div>
              <div>
                <input type="radio" name="absent_type" value="3" v-model="type_of_absent" />早退
              </div>
              <div>
                <input type="radio" name="absent_type" value="4" v-model="type_of_absent" />中抜け
              </div>
            </div>
          </div>

          <div>
            <h5>理由/詳細</h5>
            <div class="ab_item_wrapper">
              <textarea v-model="absent_reason" class="ab_reason_area"></textarea>
            </div>
          </div>
          <div class="ab_form_content">
            <div class="ab_form_item">
              <h5>申請日</h5>
              <input type="date" v-model="submit_application" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="is_use_holiday === true || is_use_holiday === false" class="ab_reg_button">
        <button>登録</button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref, watchEffect } from "vue";
import getUser from "../composables/getUser";
import { projectFirestore } from "@/firebase/config";
import getAdminDatas from "../composables/getAdminDatas";
export default {
  props: ["passedfilterData", "futurefilterData", "company_id"],

  setup(props, context) {
    const day_of_absent = ref("");
    const type_of_absent = ref("");
    const absent_reason = ref("");
    const holiday_type = ref("");
    const is_holidays = ref("");
    const is_use_holiday = ref("");
    const num_use_holiday = ref("");
    const submit_application = ref("");
    const future_duplication_check_array = ref([]);
    const passed_duplication_check_array = ref([]);
    const absentfilterData = ref([]);
    const absent_duplication_check_array = ref([]);
    const passedfilterData = ref([]);
    const futurefilterData = ref([]);
    const { user: admin_user } = getUser();

    passedfilterData.value = props.passedfilterData;
    futurefilterData.value = props.futurefilterData;

    watchEffect(() => {
      submit_application.value = day_of_absent.value;
    });

    const { admindatas, load } = getAdminDatas();
    const login_admin_name = ref("");

    const fetchData = async () => {
      await load();

      const login_admin = admindatas.value.filter((admin) => {
        console.log("admin_id", admin.admin_id);
        console.log("user", admin_user.value.email);
        return admin.admin_id === admin_user.value.email;
      });

      login_admin_name.value = login_admin[0].displayname;
    };
    fetchData();

    const handleAbsentSubmit = () => {
      console.log(props.company_id);

      if (day_of_absent.value) {
        try {
          //すでに休暇申請されていないか
          if (
            futurefilterData.value ||
            passedfilterData.value ||
            absentfilterData.value
          ) {
            future_duplication_check_array.value =
              futurefilterData.value.filter((data) => {
                return data.holiday_date === day_of_absent.value;
              });

            passed_duplication_check_array.value =
              passedfilterData.value.filter((data) => {
                return data.holiday_date === day_of_absent.value;
              });
            absent_duplication_check_array.value =
              absentfilterData.value.filter((data) => {
                return data.holiday_date === day_of_absent.value;
              });

            if (
              future_duplication_check_array.value.length > 0 ||
              passed_duplication_check_array.value.length > 0 ||
              absent_duplication_check_array.value.length > 0
            ) {
              console.log(future_duplication_check_array.value.length);
              console.log(passed_duplication_check_array.value.length);
              alert("この日はすでに申請されています。取り消し後、再度入力してください。")
              throw Error("この日はすでに申請されています。");
            } else {
              //チェックに問題なければデータ入力

              if (is_use_holiday.value) {
                holiday_type.value = "paid";

                // if (use_holiday_num.value === 1) {
                //   //全日休のときのみ１をいれる。
                //   num_use_holiday.value = 1;
                // } else {
                //   //遅刻、早退は0.5をいれる
                //   num_use_holiday.value = 0.5;
                // }
              } else {
                //有休を使用しない場合は0
                holiday_type.value = "absent";
                num_use_holiday.value = 0;
              }
              console.log("is_use:", is_use_holiday.value);
              console.log("type:", holiday_type.value);

              console.log(is_use_holiday.value);
              console.log(holiday_type.value);
              const post = {
                userid: props.company_id,
                holiday_date: day_of_absent.value,
                absent_type: type_of_absent.value,
                holiday_formated: new Date(day_of_absent.value),
                use_holiday_num: num_use_holiday.value,
                holiday_reason: absent_reason.value,

                holiday_type: holiday_type.value,
                createdAt: new Date(submit_application.value),
                created: login_admin_name.value,
              };

              submitHolidaydata(post);
              closeAbsentFrom();
            }
          }
        } catch (err) {
          console.log("error:", err.message);
        }
      }
    };

    const submitHolidaydata = async (post) => {
      try {
        const res = await projectFirestore
          .collection("holiday_application")
          .add(post);
        console.log("有給申請データの書き込みに成功しました。");
      } catch (err) {
        console.error("有給申請データ書き込みエラー:", err);
      }
    };

    const closeAbsentFrom = () => {

      day_of_absent.value = "";
      type_of_absent.value = "";
      absent_reason.value = "";
      holiday_type.value = "";
      submit_application.value = "";
      is_use_holiday.value = "";

      context.emit("absent_form_close");
    };

    return {
      is_use_holiday,
      is_holidays,
      day_of_absent,
      type_of_absent,
      absent_reason,
      holiday_type,
      num_use_holiday,
      submit_application,
      handleAbsentSubmit,
    };
  },
};
</script>

<style>
.ab_form_item {
  margin-right: 20px;
}

.ab_form_column {
  display: flex;
  flex-direction: column;
}

.div_flex {
  display: flex;
}

.ab_item_wrapper {
  margin: 0 30px 0 10px;
}
</style>
