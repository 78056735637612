import {  ref } from "vue";
import getAdminDatas from "./getAdminDatas";
import getUser from "./getUser";

const current_admin_role = ref("");
const pic_filter_data = ref("");
const admin_email = ref("");

const getPicDatas = async (datas) => {
  await fetchAdminData();

  if (current_admin_role.value === "sales") {
    const pic = ref("");
    const current_admin_id = admin_email.value.replace("@mik-s.work", "");
    switch (current_admin_id) {
      case "kiyanagi":
        pic.value = "kiyanagi";
        break;
      case "t-oda":
        pic.value = "oda";
        break;
      case "hata":
        pic.value = "hata";
        break;
    }
   

    pic_filter_data.value = datas.filter((data) => {

      return data.pic === pic.value;
    });
  } else {
    pic_filter_data.value = datas;
  }

  return pic_filter_data;
};

const fetchAdminData = async () => {
  const current_admin_data = ref([]);
  const { admindatas, load } = getAdminDatas();
  await load();

  const { user } = getUser();

  admin_email.value = user.value.email;


  current_admin_data.value = admindatas.value.filter((admin) => {
    return admin.admin_id === admin_email.value;
  });

  current_admin_role.value = current_admin_data.value[0].role;
  console.log("role", current_admin_role.value);
};

export default getPicDatas;
