<template>
  <div class="top-screen">
    <AsideVue screen_name="Point Data" @screen_reset="handleResetScreen" />
    <div class="home">
      <div class="point_button_container" v-if="isShowButtonBox">
      
        <button class="point_top_buttons" @click="handleShowAddPoint">
          {{ add_button_title }}
        </button>
        <button class="point_top_buttons" @click="handleShowAll">{{ all_button_title }}</button>

        <div class="serach_box_wrapper">
          <div class="search_box">
            <span class="material-symbols-outlined"> search </span>
            <input type="search" placeholder="search" v-model="search_item" />
          </div>
        </div>
      </div>

      <div v-if="isShowAddform">
        <PointAddForm
          :users="selectedUsers"
          :defaultdatas="defaultdatas"
          :now_page="pageNo"
          @add_form_close="handleAddFormClose"
        />
      </div>

      <div v-if="isShowAll">
        <AllEngineersPoint
        :datas="local_pic_filter_data" />
      </div>

      <div v-if="isShowEDD">
        <EngineerDefaultData
          :is_show_table="is_show_table"
          :isSelect="isSelect"
          :defaultdatas="searchedDatas"
          :isFix="isFix"
          :now_page="pageNo"
          :is_show_addform="isShowAddform"
          @checked-users="handleCheckUsers"
          @selected_engineer_id="handleChildData"
          @area_selected="handleFilterArea"
          @pic_selected="handleFilterPIC"
          @hold_point_selected="handleSortPoint"
        />
      </div>

      <div v-if="receiveId && isShowDetail">
        <DetailSelectedEngineer
          :engineer_id="receiveId"
          @return-button-click="handleReturnButtonClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AsideVue from "../components/Aside.vue";
import "@material/web/checkbox/checkbox.js";
import EngineerDefaultData from "../components/point_comp/EngineerDefaultData.vue";

import getDefaultDatas from "../components/composables/getDefaultDatas";
import FixDefaultData from "../components/point_comp/FixDefaultData.vue";
import DetailSelectedEngineer from "../components/point_comp/DetailSelectedEngineer.vue";
import { onBeforeMount, onMounted, ref, watch } from "vue";
import PointAddForm from "@/components/point_comp/PointAddForm.vue";
import getUsersPoint from "@/components/composables/getUsersPoint";
import getPicDatas from '@/components/composables/getPicDatas';
import AllEngineersPoint from "@/components/point_comp/AllEngineersPoint.vue";


export default {
  name: "Point",
  components: {
    AsideVue,
    EngineerDefaultData,
    FixDefaultData,
    DetailSelectedEngineer,
    PointAddForm,
    AllEngineersPoint
},
  data() {
    return {
      isShowAddEngineer: false,
      isShowConfirmEngineer: false,
      isShowEDD: true,
      is_onEdit: false,
      is_onDetail: false,
      is_onDelete: false,
      isSelect: false,
      edit_button_text: "編集",
      delete_button_text: "削除",
      detail_button_text: "詳細",
      defaultdata: null,
      receiveId: null,
      isShowDetail: false,
      isShowDelete: false,
      isShowFix: false,
      isDetailItem: false,
      isShowButtonBox: true,
      isFix: false,
      on_edit_name: null,
      selectedUsers: [],
      isShowForm: false,
      isShowAddform: false,
      add_button_title: "一括ポイント付与",
      is_show_table: true,
      pageNo:0,
      isShowAll:false,
      isAllButton:true,
      isAllPointButton:true,
      all_button_title:"エンジニア一覧表示"
  
    };
  },
  setup() {
    const search_item = ref("");
    const fixted_search_item = ref("");
    const searchedDatas = ref([]);
    const local_pic_filter_data = ref([]);

    const { defaultdatas, datas_error, datas_load } =
      getDefaultDatas("pointData");

    onBeforeMount(async () => {
      await getUsersPoint();
      await datas_load();
      const pic_filter_data = await getPicDatas(defaultdatas.value);
      local_pic_filter_data.value = pic_filter_data.value;
      searchedDatas.value = local_pic_filter_data.value.slice().sort((a, b) => {
        if (a.is_submit && !b.is_submit) {
          return -1;
        } else if (!a.is_submit && b.is_submit) {
          return 1;
        } else {
          return a.enter_date - b.enter_date;
        }
      });
    });

    watch(search_item, () => {
      if (local_pic_filter_data.value) {
        if (search_item.value) {
          fixted_search_item.value = search_item.value.toLowerCase();
          searchedDatas.value = local_pic_filter_data.value.filter((data) => {
            const fieldsToSearch = [
              "last_name",
              "first_name",
              "last_name_kana",
              "first_name_kana",
              "company_id",
            ];

            return fieldsToSearch.some((field) => {
              return (
                data[field] &&
                data[field].toLowerCase().includes(fixted_search_item.value)
              );
            });
          });
        } else {

          searchedDatas.value = local_pic_filter_data.value.slice().sort(
            (a, b) => {
              if (a.is_submit && !b.is_submit) {
                return -1;
              } else if (!a.is_submit && b.is_submit) {
                return 1;
              } else {
                return a.enter_date - b.enter_date;
              }
            },
            { deep: true }
          );

        }
      }
    });

    const handleFilterArea = (area_selected) => {
      console.log("area_selected:", area_selected);
      if (area_selected !== null && area_selected !== "all") {
        searchedDatas.value = local_pic_filter_data.value.filter((data) => {
          return data.area === area_selected;
        });
      } else {
        searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
        if (a.is_submit && !b.is_submit) {
          return -1;
        } else if (!a.is_submit && b.is_submit) {
          return 1;
        } else {
          return a.enter_date - b.enter_date;
        }
      });
      }
    };

    const handleFilterPIC = (pic_selected) => {
      if (pic_selected !== null && pic_selected !== "all") {
        searchedDatas.value = local_pic_filter_data.value.filter((data) => {
          return data.pic === pic_selected;
        });
      } else {
        searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
        if (a.is_submit && !b.is_submit) {
          return -1;
        } else if (!a.is_submit && b.is_submit) {
          return 1;
        } else {
          return a.enter_date - b.enter_date;
        }
      });
      }
    };

    const handleSortPoint = (hold_point_selected) => {
      if (hold_point_selected !== "0") {
        searchedDatas.value = local_pic_filter_data.value.slice().sort((a, b) => {
          const PointA = parseInt(a.hold_point);
          const PointB = parseInt(b.hold_point);

          console.log("pointA", PointA);
          console.log("pointB", PointB);

          if (isNaN(PointA)) {
            return 1;
          } else if (isNaN(PointB)) {
            return -1;
          }

          if (hold_point_selected === "1") {
            return PointB - PointA;
          } else if (hold_point_selected === "2") {
            return PointA - PointB;
          } else {
            return 0;
          }
        });
      } else {
        searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
        if (a.is_submit && !b.is_submit) {
          return -1;
        } else if (!a.is_submit && b.is_submit) {
          return 1;
        } else {
          return a.enter_date - b.enter_date;
        }
      });
      }
    };

    return {
      defaultdatas,
      datas_load,
      datas_error,
      search_item,
      searchedDatas,
      local_pic_filter_data,

      handleFilterArea,
      handleFilterPIC,
      handleSortPoint,
      getUsersPoint,
    };
  },

  methods: {
    handleChildData(data_id,page_no) {
      this.receiveId = data_id;
      this.isShowDetail = !this.isSHowDetail;
      this.isShowEDD = !this.isShowEDD;
      this.isShowButtonBox = !this.isShowButtonBox;
      this.pageNo = page_no;
    },
    async handleReturnButtonClick() {
      await this.datas_load();
      const pic_filter_data = await getPicDatas(this.defaultdatas);
      this.searchedDatas = pic_filter_data.value.sort(
            (a, b) => {
              if (a.is_submit && !b.is_submit) {
                return -1;
              } else if (!a.is_submit && b.is_submit) {
                return 1;
              } else {
                return a.enter_date - b.enter_date;
              }
            })
      this.isShowEDD = true;
      this.isShowDetail = false;
      this.isShowButtonBox = true;
    },
    handlePushEditButton(is_push) {
      this.isShowDetail = !is_push;
      this.isShowFix = is_push;
      this.isShowButtonBox = false;
    },
    handlePushDescription(is_push) {
      this.isShowDetail = is_push;
      this.isShowFix = !is_push;
    },
    handleFixClose(is_close) {
      this.isShowFix = !is_close;
      this.isShowEDD = is_close;
      this.isShowButtonBox = true;
      this.isFix = true;
      this.reloadDefaultData();
    },
    handleCheckUsers(selectedUsers) {
      this.selectedUsers = selectedUsers;
      console.log("point_selectedUsers", this.selectedUsers);
    },
    async handleResetScreen() {
      await this.datas_load();
      const pic_filter_data = await getPicDatas(this.defaultdatas);
      this.searchedDatas = pic_filter_data.value.sort(
            (a, b) => {
              if (a.is_submit && !b.is_submit) {
                return -1;
              } else if (!a.is_submit && b.is_submit) {
                return 1;
              } else {
                return a.enter_date - b.enter_date;
              }
            })

      this.isShowAddEngineer = false;
      this.isShowButtonBox = true;
      this.isShowEDD = true;
      this.isReturnButton = false;
      this.isShowDetail = false;
    },
    handleShowAddPoint() {
      this.isShowAddform = !this.isShowAddform;
      if (this.isShowAddform === true) {
        this.add_button_title = "キャンセル";
      } else {
        this.add_button_title = "ポイント付与";
      }
    },
    async handleAddFormClose(page_no) {
      this.isShowEDD = false;
      this.pageNo = page_no;
      await this.getUsersPoint();
      await this.datas_load()
      const pic_filter_data = await getPicDatas(this.defaultdatas);
      this.searchedDatas = pic_filter_data.value.sort((a, b) => {
        return a.enter_date - b.enter_date;
      });
  
        //this.handleShowAddPoint();
        this.isShowEDD = true;

    },
    handleShowAll(){
      this.isShowAll = !this.isShowAll;
      this.isShowEDD = !this.isShowEDD;
      if(this.isShowAll){
        this.all_button_title = "通常表示に戻す"
      }else{
        this.all_button_title = "エンジニア一覧表示"
      }
    }
  },
};
</script>

<style>
.top-screen {
  display: flex;
}
.home {
  background: #f8ffe4;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.button_box {
  margin-top: 71px;
  margin-left: 20px;
  margin-bottom: 25px;
  display: flex;
}
.point_top_buttons {
  width: 230px;
  height: 32px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 5px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
