<template>
  <div class="welcome_page" >
    <div class="login_frame">
    <img src="@/assets/MIK.png" class="welcome_logo">
    <h1>パスワード変更</h1>
    <ChangePassForm />
  </div>
  </div>
</template>

<script>

import ChangePassForm from "@/components/user_comp/ChangePassForm.vue";
import { ref } from "vue";
import { useRouter } from 'vue-router';


export default {
  name:'PassChange',
  components: {ChangePassForm },
  setup() {
  
  
    return {  };
  },
};
</script>

<style>
.welcome_page {
  background: #f8ffe4;
  height: 100vh;
  padding-top: 120px;
  display:flex;
  justify-content:center;
  margin:0px;
  text-align: center;
}
.welcome_logo{
  width:180px;
}
.login_frame{
  background:white;
  width:480px;
  margin-bottom:150px;
  padding: 50px 0 50px 0;
}
</style>
