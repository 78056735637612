import AsideVue from '@/components/Aside.vue';
<template>
  <div class="top-screen">
    <AsideVue screen_name="Engineer Data" 
    :isDetail="isShowDetail"
    :user_id="receiveId"
    :page_no="pageNo"
    @screen_reset="handleResetScreen"
    @detail_screen_reset="handleDetailResetScreen"
    />
    <div class="home">
      <div>
        <div class="home_button_container" v-if="isShowButtonBox">
          <button
            class="top_button_long"
            @click="showAddEngineer"
            :disabled="is_onEdit || is_onDelete || is_onDetail"
          >
            <span class="material-symbols-outlined"> add </span>エンジニアを追加
          </button>
          <!-- ローンチのため一時的に使用不可 -->
          <!-- <button class="top_button" @click="showAll">一覧表示</button> -->

          <button class="top_button" @click="handleRegShowCSV">
            CSV一括登録
          </button>
          <div class="home_serach_box_wrapper">
            <div class="search_box">
              <span class="material-symbols-outlined"> search </span>
              <input type="search" placeholder="search" v-model="search_item" />
            </div>
          </div>
        </div>

        <div v-if="isShowRegCSV">
          <RegistCSV />
        </div>
        <div v-if="isShowEDD">
          <EngineerDefaultData
            :isSelect="isSelect"
            :defaultdatas="searchedDatas"
            :is_onEdit="is_onEdit"
            :is_onDelete="is_onDelete"
            :now_page="pageNo"
            :search_item="search_item"
            @selected_engineer_name="handleChildName"
            @selected_engineer_check="handleChildData"
            @area_selected="handleFilterArea"
            @pic_selected="handleFilterPIC"
            @enter_date_selected="handleFilterEnter"
            @status_selected="handleFilterStatus"
          />
        </div>
      </div>

      <!-- <div v-if="isShowAll">
        <AllEngineerData :defaultdatas="defaultdatas" />
      </div> -->

      <div v-if="isShowFix">
        <div v-if="receiveId">
          <FixDefaultData
            :engineer_id="receiveId"
            @fix_view_close="handleFixClose"
            @edit-to-description="handlePushDescription"
            @return-button-click="handleFixClose"
          />
        </div>
      </div>

      <div v-if="isShowAddEngineer">
        <AddEngineer
          @add_view_close="handleAddClose"
          @add_user_return="handleReturnTop"
        />
      </div>
      <!-- <div v-if="isShowConfirmEngineer"><ConfirmEngineer /></div> -->

      <div v-if="receiveId && isShowDetail">
        <DetailSelectedEngineer
          :engineer_id="receiveId"
          @return-button-click="handleReturnButtonClick"
          @switch-edit-screen="handlePushEditButton"
          @switch-delete-screen="handlePushDeleteButton"
        />
      </div>

      <div v-if="receiveId && isShowDelete">
        <DeleteEngineer
          :engineer_doc_id="receiveId"
          @return-button-click="handleDeleteClose"
          @delete_view_close="handleDeleteClose"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AsideVue from "@/components/Aside.vue";
import "@material/web/checkbox/checkbox.js";
import EngineerDefaultData from "../components/home_comp/EngineerDefaultData.vue";
import AddEngineer from "../components/home_comp/AddEngineer.vue";
import ConfirmEngineer from "../components/home_comp/ConfirmEngineer.vue";
import getDefaultDatas from "../components/composables/getDefaultDatas";
import FixDefaultData from "../components/home_comp/FixDefaultData.vue";
import DetailSelectedEngineer from "../components/home_comp/DetailSelectedEngineer.vue";
import DeleteEngineer from "../components/home_comp/DeleteEngineer.vue";
import Spinner from "@/components/Pulse.vue";
import AllEngineerData from "@/components/home_comp/AllEngineerData.vue";
import RegistCSV from "@/components/home_comp/RegistCSV.vue";
import { onMounted, ref, watch } from "vue";

import getPicDatas from "@/components/composables/getPicDatas";

export default {
  name: "Home",
  components: {
    AsideVue,
    EngineerDefaultData,
    AddEngineer,
    ConfirmEngineer,
    FixDefaultData,
    DetailSelectedEngineer,
    DeleteEngineer,
    Spinner,
    AllEngineerData,
    RegistCSV,
  },
  data() {
    return {
      isShowAddEngineer: false,
      isShowConfirmEngineer: false,
      isShowEDD: true,
      is_onEdit: false,
      is_onDetail: false,
      is_onDelete: false,
      isSelect: false,
      edit_button_text: "編集",
      delete_button_text: "削除",
      detail_button_text: "詳細",
      defaultdata: null,
      receiveId: null,
      isShowDetail: false,
      isShowDelete: false,
      isShowFix: false,
      isDetailItem: false,
      isShowButtonBox: true,
      isShowAll: false,
      isFix: false,
      on_edit_name: null,
      isReturnButton: false,
      isShowRegCSV: false,
      pageNo: 0,
    };
  },
  props:['user_id','page_no'],
  setup() {
    const search_item = ref("");
    const fixted_search_item = ref("");
    const searchedDatas = ref([]);
    const local_pic_filter_data = ref([]);

    const { defaultdatas, datas_error, datas_load } =
      getDefaultDatas("engineers");

    onMounted(async () => {
      await datas_load();
      const pic_filter_data = await getPicDatas(defaultdatas.value);
      local_pic_filter_data.value = pic_filter_data.value;
      searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
      
        return a.enter_date - b.enter_date;
      });
      console.log("local_pic_filter_data",local_pic_filter_data.value)
    });

    watch(search_item, async () => {
      if (local_pic_filter_data.value) {
        if (search_item.value) {
          fixted_search_item.value = search_item.value.toLowerCase();
          const pic_filter_data = await getPicDatas(defaultdatas.value);
          local_pic_filter_data.value = pic_filter_data.value;
          searchedDatas.value = local_pic_filter_data.value.filter((data) => {
            const fieldsToSearch = [
              "last_name",
              "first_name",
              "last_name_kana",
              "first_name_kana",
              "company_id",
            ];

            return fieldsToSearch.some((field) => {
              return (
                data[field] &&
                data[field].toLowerCase().includes(fixted_search_item.value)
              );
            });
          });

          console.log("searchDatas",searchedDatas.value)
        } else {
          const pic_filter_data = await getPicDatas(defaultdatas.value);
          local_pic_filter_data.value = pic_filter_data.value;
          searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
            return a.enter_date - b.enter_date;
          });
          console.log("searchDatas",searchedDatas.value)
        }
      }
      console.log("watch function");
    });

    const handleFilterArea = (area_selected) => {
      console.log("area_selected:", area_selected);
      if (area_selected !== null && area_selected !== "all") {
        searchedDatas.value = local_pic_filter_data.value.filter((data) => {
          return data.area === area_selected;
        });
      } else {
        searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
          return a.enter_date - b.enter_date;
        });
      }
    };

    const handleFilterPIC = (pic_selected) => {
      if (pic_selected !== null && pic_selected !== "all") {
        searchedDatas.value = local_pic_filter_data.value.filter((data) => {
          return data.pic === pic_selected;
        });
      } else {
        searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
          return a.enter_date - b.enter_date;
        });
      }
    };

    const handleFilterEnter = (enter_selected) => {
      console.log("enter_setected:", enter_selected);
      if (enter_selected !== "0") {
        searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
          const enterDateA = a.enter_date;
          const enterDateB = b.enter_date;

          if (enter_selected === "1") {
            //降順
            return enterDateB - enterDateA;
          } else if (enter_selected === "2") {
            //昇順
            return enterDateA - enterDateB;
          }
        });
      } else {
        searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
          return a.enter_date - b.enter_date;
        });
      }
    };

    const handleFilterStatus = (status_selected) => {
      const status = ref("");

      if (status_selected !== 0) {
        if (status_selected === "1") {
          status.value = "work";
          searchedDatas.value = local_pic_filter_data.value
            .filter((data) => {
              console.log("data.stauts", data.status);
              console.log("stauts", status.value);
              return data.status === status.value;
            })
            .sort((a, b) => {
              return a.enter_date - b.enter_date;
            });
        } else if (status_selected === "2") {
          status.value = "stay1";
          searchedDatas.value = local_pic_filter_data.value
            .filter((data) => {
              console.log("data.stauts", data.status);
              console.log("stauts", status.value);
              return data.status === status.value;
            })
            .sort((a, b) => {
              return a.enter_date - b.enter_date;
            });
        } else if (status_selected === "3") {
          status.value = "stay2";
          searchedDatas.value = local_pic_filter_data.value
            .filter((data) => {
              console.log("data.stauts", data.status);
              console.log("stauts", status.value);
              return data.status === status.value;
            })
            .sort((a, b) => {
              return a.enter_date - b.enter_date;
            });
        } else if (status_selected === "4") {
          status.value = "stay3";
          searchedDatas.value = local_pic_filter_data.value
            .filter((data) => {
              console.log("data.stauts", data.status);
              console.log("stauts", status.value);
              return data.status === status.value;
            })
            .sort((a, b) => {
              return a.enter_date - b.enter_date;
            });
        } else {
          searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
            return a.enter_date - b.enter_date;
          });
        }
      }
    };

    return {
      defaultdatas,
      datas_load,
      search_item,
      searchedDatas,
      handleFilterArea,
      handleFilterPIC,
      handleFilterEnter,
      handleFilterStatus,
    };
  },
  computed: {},
  methods: {
    showAddEngineer() {
      this.isShowAddEngineer = !this.isShowAddEngineer;
      this.isShowEDD = !this.isShowEDD;
      this.isShowButtonBox = false;
      this.isReturnButton = true;
    },

    showAll() {
      this.isShowEDD = !this.isShowEDD;
      this.isShowAll = !this.isShowAll;
    },

    selectDetail(receiveId) {
      this.on_edit_name = "detail";
      this.is_onDetail = true;
      this.isSelect = !this.isSelect;
      if (this.on_edit_name === "detail" && this.isSelect) {
        this.detail_button_text = "このユーザーの詳細を見る";
      } else {
        if (receiveId !== null) {
          this.isShowDetail = !this.isSHowDetail;
          this.isShowEDD = !this.isShowEDD;
          this.isShowButtonBox = !this.isShowButtonBox;
          this.detail_button_text = "詳細";
        } else {
          this.detail_button_text = "詳細";
          this.is_onDetail = false;
        }
      }
    },
    disableOtherButton() {
      if (this.isSelect) {
        if (isDetailItem) {
        }
      }
    },

    handleChildName(data_id, page_no) {
      this.receiveId = data_id;
      this.isShowDetail = !this.isShowDetail;
      this.isShowEDD = !this.isShowEDD;
      this.isShowButtonBox = !this.isShowButtonBox;
      this.pageNo = page_no;
      console.log("pageno", this.pageNo);
    },
    handleChildData(data_id) {
      this.receiveId = data_id;
    },
    async handleReturnButtonClick(is_close) {
      await this.datas_load();
      const pic_filter_data = await getPicDatas(this.defaultdatas);
      this.searchedDatas = pic_filter_data.value.sort((a, b) => {
        return a.enter_date - b.enter_date;
      });
      this.isShowEDD = is_close;
      this.isShowDetail = !is_close;
      this.isShowButtonBox = true;
      this.is_onDetail = false;
    },
    handlePushEditButton() {
      this.isShowDetail = false;
      this.isShowFix = true;
      this.isShowButtonBox = false;
    },
    handlePushDeleteButton() {
      this.isShowDetail = false;
      this.isShowDelete = true;
      this.isShowButtonBox = false;
    },
    handlePushDescription(is_push) {
      this.isShowDetail = is_push;
      this.isShowFix = !is_push;
    },
    handleFixClose() {
      this.isShowFix = false;
      this.isShowEDD = true;
      this.isShowButtonBox = true;
      this.isFix = true;
    },

    handleDeleteClose() {
      this.isShowDelete = false;
      this.isShowEDD = true;
      this.isShowButtonBox = true;
      this.isFix = true;
    },

    handleAddClose(is_close) {
      this.isShowAddEngineer = false;
      this.isShowEDD = true;
      this.isShowButtonBox = true;
    },

    handleReturnTop() {
      this.isShowAddEngineer = false;
      this.isShowButtonBox = true;
      this.isShowEDD = true;
      this.isReturnButton = false;
    },
    handleRegShowCSV() {
      this.isShowRegCSV = !this.isShowRegCSV;
    },
    handleResetScreen() {
      this.isShowAddEngineer = false;
      this.isShowButtonBox = true;
      this.isShowEDD = true;
      this.isReturnButton = false;
      this.isShowDetail = false;
    },
    handleDetailResetScreen(){
      this.handleChildName(this.data.user_id, this.data.page_no)
      this.isShowEDD = false;
      this.isShowButtonBox = false;
      this.isShowDetail = true;
    }
  },
};
</script>

<style>
.top-screen {
  display: flex;
}
.home {
  background: #f8ffe4;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.button_box {
  margin-top: 71px;
  margin-left: 20px;
  margin-bottom: 25px;
  display: flex;
}

.return_btn {
  width: 139px;
  height: 37px;
  background: #2c3e5062;
  color: white;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 30px 0 0 30px;
}
.edit_button {
  width: 139px;
  height: 37px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.on_edit_button {
  width: 200px;
  height: 37px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.home_button_container {
  display: flex;
  margin: 20px;
}

.home_serach_box_wrapper {
  width: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
</style>
