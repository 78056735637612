<template>
    <button class="pay_new_button" :disabled="!is_enabled" @click="handleOpenPdf(find_year, find_month)">{{
        reslutButtonName }}</button>
</template>
<script>
import { onMounted, ref } from 'vue';
import getDatas from '../composables/getDatas';

export default {
    props: ['member_id'],
    name: "NewPayButton",
    setup(props) {
        const { datas, load } = getDatas("pay_data_urls", props.member_id);
        const reslutButtonName = ref("")
        const is_enabled = ref(true);
        const find_year = ref(0);
        const find_month = ref(0);


        onMounted(async () => {
            console.log(props.member_id)
            await load();
            const { year, month, found } = findLatestMonthWithData(currentYear, currentMonth);

            if (found) {
                reslutButtonName.value = `${year}年${month}月分`;
                find_year.value = year;
                find_month.value = month;
                is_enabled.value = true;
            } else {
                reslutButtonName.value = "データなし";
                is_enabled.value = false;
            }

        })

        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;

        const checkMonthData = (year, month) => {
            const paydata = datas.value.find(data => {
                const dataYear = Number(data.year)
                const dataMonth = Number(data.month)
                return dataYear === year && dataMonth === month
            });
            return !!paydata;
        }
        const findLatestMonthWithData = (year, month) => {
            while (year > 2022) {
                if (checkMonthData(year, month)) {
                    return { year, month, found: true };
                }
                // 前の月に移動
                month -= 1;
                if (month === 0) {
                    month = 12;
                    year -= 1;
                }
            }
            return { year: null, month: null, found: false };
        };

        const handleOpenPdf = async (year, month) => {


            const paydata = datas.value.find(data => {
                const dataYear = Number(data.year)
                const dataMonth = Number(data.month)
                return dataYear === year && dataMonth === month
            })
            console.log("paydata", paydata)
            if (paydata) {
                console.log("url", paydata.url)

                window.open(paydata.url, "_blank");
            } else {
                console.log("選択されたPaydataが見つかりません。")
            }

        }

        return {
            reslutButtonName,
            is_enabled,
            find_year,
            find_month,
            handleOpenPdf
        }

    }

}</script>
<style>
.pay_new_button {
    width: 110px;
    height: 30px;
    background: rgba(245, 195, 16, 1);
    color: white;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.pay_new_button:disabled {
    background: #ddd;
    cursor: default;
}
</style>