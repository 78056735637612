<template>
  <div class="userpoint_add_form">
    <h4>ポイント付与</h4>
    <h5>ポイント対象者</h5>
    <div v-if="user_data">
      <div>{{ user_data.last_name }}{{ user_data.first_name }}</div>
    </div>
    <h5>ポイント付与理由</h5>
    <select v-model="point_content_num">
      <option value="1">皆勤賞</option>
      <option value="2">対象アンケートの回答</option>
      <option value="3">がんばったMVPエントリー</option>
      <option value="4">がんばったMVPへの投票</option>
      <option value="5">友人紹介</option>
      <option value="6">紹介した友人の入社</option>
      <option value="7">新規派遣先紹介</option>
      <option value="8">紹介いただいた派遣先との契約締結（50）</option>
      <option value="9">紹介いただいた派遣先との契約締結（100）</option>
      <option value="10">紹介いただいた派遣先との契約締結（200）</option>
      <option value="11">その他</option>
    </select>

    <div v-if="point_content_num === '1'">
      <input type="number" min="1" max="12" v-model="point_month" />月分
    </div>

    <div v-if="point_content_num === '11'">
      <div>
        <div>
          <label>付与ポイント</label>
          <input type="number" v-model="input_point" />ポイント
        </div>
        <div>
          <label>ポイント付与理由</label>
          <input type="text" v-model="input_comment" />
        </div>
      </div>
    </div>

    <h5>ポイント付与日</h5>
    <input type="date" v-model="point_date" />

    <h5>備考</h5>
    <input type="text" v-model="point_other" class="point_reason_area"/>
    <div>
      <button @click="handleAddpoint">登録</button>
      <button @click="handleResetPoint">クリア</button>
    </div>

    <div class="point_add_close" @click="handleClose">
     ×
    </div>
  </div>
</template>

<script>
import { ref, reactive, watch } from "vue";
import { projectFirestore } from "@/firebase/config";
export default {
  props: ["users", "user_data"],
  setup(props, context) {
    const local_users = ref(props.users);
    const point_user_id = ref("");
    const point_content_num = ref("");
    const point_month = ref("");
    const point_content = ref("");
    const points = ref("");
    const point_date = ref("");
    const point_other = ref("");
    const input_point = ref(0);
    const input_comment = ref("");

    watch(
      () => props.users,
      (newUsers, oldUsers) => {
        local_users.value = newUsers;
        console.log("local", local_users.value);
      }
    );

    const handleResetPoint = () => {
      local_users.value = [];
      point_content_num.value = null;
      point_date.value = null;
      point_other.value = null;
      points.value = null;
      point_month.value = null;
      point_content.value = null;
      input_point.value = null;
      input_comment.value = null;
    };

    const closeAddform = () => {
      console.log("click1");
      context.emit("add_form_close");
    };

    const handleAddpoint = async () => {
      const userData = reactive({
        points: 0,
        point_content: "",
      });
      if (point_content_num.value) {
        switch (point_content_num.value) {
          case "1":
            const month = point_month.value;
            userData.point_content = `${month}月分皆勤賞`;
            userData.points = 5;
            break;
          case "2":
            userData.point_content = "対象アンケートの回答";
            userData.points = 1;
            break;
          case "3":
            userData.point_content = "がんばったMVPエントリー";
            userData.points = 3;
            break;
          case "4":
            userData.point_content = "がんばったMVPへの投票";
            userData.points = 3;
            break;
          case "5":
            userData.point_content = "友人紹介";
            userData.points = 5;
            break;
          case "6":
            userData.point_content = "紹介した友人の入社";
            userData.points = 200;
            break;
          case "7":
            userData.point_content = "新規派遣先紹介";
            userData.points = 5;
            break;
          case "8":
            userData.point_content = "紹介いただいた派遣先との契約締結（50）";
            userData.points = 50;
            break;
          case "9":
            userData.point_content = "紹介いただいた派遣先との契約締結（100）";
            userData.points = 100;
            break;
          case "10":
            userData.point_content = "紹介いただいた派遣先との契約締結（200）";
            userData.points = 200;
            break;
          case "11":
            userData.point_content = input_comment.value;
            console.log("comment", input_comment.value);
            userData.points = input_point.value;
            break;
          default:
            console.log("unknown point_content_num:", point_content_num.value);
            break;
        }
      }

      const currentday = new Date();

      const point_addpost = {
        point_user_id: props.user_data.id,
        point_status: "add",
        point_content: userData.point_content,
        points: userData.points,
        point_date: point_date.value,
        point_other: point_other.value,
        createdAt: currentday,
      };
      console.log(point_addpost);

      try {
        const res = await projectFirestore
          .collection("points")
          .add(point_addpost);
        console.log("ポイントの書き込みに成功しました。");
        context.emit("point_add_finish");
        handleResetPoint();
      } catch (err) {
        console.error("ポイントの書き込みに失敗しました:", err);
      }
    };

    return {
      point_content_num,
      point_content,
      point_month,
      points,
      point_date,
      point_other,
      local_users,
      input_point,
      input_comment,

      handleAddpoint,
      handleResetPoint,
    };
  },
  methods:{
    handleClose(){
      this.$emit("close_add_point")
    },
  },
};
</script>

<style>


.userpoint_add_form {
  position: relative;
  width: 480px;
  background: white;
  border-radius: 20px;

  border: 3px solid;
  padding: 0 20px 20px;
  font-size: 15px;
  margin: 20px;
}

.userpoint_add_form h4 {
  margin-bottom: 25px;
}

.userpoint_add_form h5 {
  margin-bottom: 5px;
}

.point_form_content {
  display: flex;
  padding-bottom: 20px;
}

.point_form_select {
  display: flex;
}

.point_form_item {
  margin-right: 30px;
}

.point_reason_area {
  width: 250px;
  height: 50px;
  margin-bottom: 10px;
}

.point_add_close {
  position: absolute;
  right: 15px;
  top: 10px;
  color: #2c3e50;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}
</style>
