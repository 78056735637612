<template>
  <div class="point_add_form">
    <h3>今月の休暇取得者</h3>
    <div>
      <table>
        <tr>
          <th>#</th>
          <th>氏名</th>
          <th>休暇取得日</th>
          <th>有休利用</th>
          <th>有休の種別</th>
          <th>欠勤の種別</th>
          <th>休暇取得理由</th>
          <th>個人データ</th>
        </tr>
        <tr v-for="(data, index) in this_month_datas" :key="data.id">
          <td>{{ (index + 1).toString().padStart(2, "0") }}</td>
          <td>
            <div class="table_item">{{ data.name }}</div>
          </td>
          <td>
            <div class="table_item">{{ data.holiday_date }}</div>
          </td>
          <td>
            <div class="table_item" v-if="data.holiday_type === 'paid'">
              <div class="paid_text">有休</div>
            </div>
            <div class="table_item" v-else>
              <div class="absent_text">欠勤</div>
            </div>
          </td>
          <td>
            <div v-if="data.holiday_type === 'paid'">
              <div class="table_item" v-if="Number(data.use_holiday_num) === 1">
                <div class="all_rest_text">全休取得</div>
              </div>
              <div class="table_item" v-if="Number(data.use_holiday_num)=== 0.5">
                <div class="half_rest_text">半休取得</div>
              </div>
          
            </div>
            <div class="table_item" v-else>-</div>
          </td>
          <td>
            <div v-if="data.holiday_type === 'absent'">
              <div class="table_item" v-if="data.absent_type === 1">全日休</div>
              <div class="table_item" v-if="data.absent_type === 2">遅刻</div>
              <div class="table_item" v-if="data.absent_type === 3">早退</div>
              <div class="table_item" v-if="data.absent_type === 4">中抜け</div>
              <div class="table_item" v-else><div class="no_data">登録なし</div></div>
            </div>
            <div class="table_item" v-else>-</div>
          </td>
          <td>
            <div class="table_item">{{ data.holiday_reason }}</div>
          </td>
          <td>
            <div class="table_item">
              <button
                class="top_button"
                @click="handleShowDetail(data.userid, 1)"
              >
                個人データへ
              </button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import getSubmittedLastMonthHolidayData from "../composables/getSubmittedLastManthHolidayData.js"

export default {
  props:["submitted_selected_datas"],
  setup(props, context) {
    const this_month_datas = ref([]);

    onMounted(async () => {
      const monthly_data_list = await getSubmittedLastMonthHolidayData(props.submitted_selected_datas);
      this_month_datas.value = monthly_data_list;

      // const { submit_this_month_data } = await getSubmittedHolidayData();
      // this_month_datas.value = submit_this_month_data;
      // console.log("submitdata", this_month_datas.value);
    });

    const handleShowDetail = (userid, page_no) => {
      const id = userid.replace("@mik-s.work", "");
      context.emit("selected_engineer", id, page_no);
    };

    return {
      this_month_datas,
      handleShowDetail,
    };
  },
};
</script>

<style>
.paid_text {
  color: #d9376e;
  font-weight: bold;
  font-size: 14px;
}

.absent_text {
  color: gray;
  font-weight: bold;
  font-size: 14px;
}

.all_rest_text {
  color: black;
  font-weight: bold;
  font-size: 14px;
}

.half_rest_text {
  color: gray;
  font-weight: bold;
  font-size: 14px;
}

.no_data{
  font-size:12px;
  color:#ddd;
}
</style>
