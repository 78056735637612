<template>
  <div class="welcome_page">
    <div class="login_frame">
      <img src="@/assets/MIK.png" class="welcome_logo">
      <h1>Welcome！</h1>
      <div v-if="showLogin">
        <h2>ログイン</h2>

        <EngineerLoginForm />
        <p class="p_font">
          MIKイントラサイト用IDでMicrosoft認証ができない方は、<br />
          営業担当までご連絡ください。
          <!--アカウント作成ページリンクは、
        安全性確保のためコメントアウト  -->
          <!-- <span @click="showLogin = false">こちら</span> -->
        </p>
      </div>
      <div v-else>
        <h2>Sign up</h2>
        <SignupForm @signup="enterTop" />
        <p>
          Already account yet?
          <span @click="showLogin = true">Log in</span>instead
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import SignupForm from "@/components/SignupForm.vue";
import EngineerLoginForm from "@/components/user_comp/EngineerLoginForm.vue";
import { ref } from "vue";
import { useRouter } from 'vue-router';


export default {
  components: { SignupForm, EngineerLoginForm },
  setup() {
    const showLogin = ref(true)

    return { showLogin };
  },
};
</script>

<style>
.welcome_page {
  background: #f8ffe4;
  width: 100%;
  height: auto;
  padding-top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  text-align: center;
}

.welcome_logo {
  width: 180px;
}

.login_frame {
  background: white;
  width: 480px;
  height: 700px;

}

.p_font {
  font-size: 14px;
}
</style>
