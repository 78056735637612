<template>
  <div class="detail_button_container">
    <div>
      <button class="top_return_button" @click="pushReturn">もどる</button>
    </div>
    <div class="detail_button_right">
      <div v-if="isShowDeleteButton">
        <button class="top_button_long_gray" @click="pushDelete">
          <span class="material-symbols-outlined"> delete </span
          >このエンジニアを削除
        </button>
      </div>
    </div>
  </div>

  <div class="detail_engineer_box">
    <div v-if="data_error">{{ data_error }}</div>

    <h3>エンジニアの詳細</h3>
    <div v-if="defaultdata">
      <div v-if="!show_input_array[12]">
        <div class="home_data_row_face">
          <div v-if="defaultdata && defaultdata.face_url">
            <img :src="defaultdata.face_url" class="face_box" />
          </div>
          <div v-else><div class="face_image_prev"></div></div>
        </div>
      </div>
      <button @click="handleShowInputFace">{{ button_title_array[12] }}</button>
      <div v-if="show_input_array[12]" class="home_data_row_face">
        <div class="face_select_back">
          <input
            type="file"
            accept="image/png, image/jpeg"
            id="avater"
            name="avater"
          />
          <button @click="handleRegistFace">登録</button>
        </div>
      </div>

      <div class="home_data_row">
        <div class="home_data_box">
          <div class="home_data_title">
            <h5>お名前</h5>
            <span
              class="material-symbols-outlined"
              @click="handleShowInputName"
            >
              edit_square
            </span>
          </div>

          <div v-if="!show_input_array[0]" class="data_item">
            <div v-if="defaultdata">
              {{ defaultdata.last_name }}{{ defaultdata.first_name }}
            </div>
          </div>
          <div v-else>
            <div class="data_item">
              <input type="text" v-model="defaultdata.last_name" />
              <input type="text" v-model="defaultdata.first_name" />
            </div>
            <button class="data_regist_button" @click="handleRegistName">
              登録
            </button>
          </div>
        </div>

        <div class="home_data_box">
          <div class="home_data_title">
            <h5>ふりがな</h5>
            <span
              class="material-symbols-outlined"
              @click="handleShowInputKana"
            >
              edit_square
            </span>
          </div>

          <div v-if="!show_input_array[1]" class="data_item">
            <div v-if="defaultdata">
              {{ defaultdata.last_name_kana }}{{ defaultdata.first_name_kana }}
            </div>
          </div>
          <div v-else>
            <div class="data_item">
              <input type="text" v-model="defaultdata.last_name_kana" />
              <input type="text" v-model="defaultdata.first_name_kana" />
            </div>
            <button class="data_regist_button" @click="handleRegistKana">
              登録
            </button>
          </div>
        </div>

        <div class="home_data_box">
          <div class="home_data_title">
            <h5>稼働ステータス</h5>
          </div>
          <div class="data_item" v-if="!show_input_array[13]">
            <div v-if="defaultdata">
              <DefaultStatus
                :id="defaultdata.id"
                :status="defaultdata.status"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="home_data_row">
        <div class="home_data_box">
          <div class="home_data_title">
            <h5>所属地域</h5>
          </div>
          <div class="data_item">
            <div v-if="defaultdata">
              <AreaStatus :id="defaultdata.id" :area="defaultdata.area" />
            </div>
          </div>
        </div>

        <div class="home_data_box">
          <div class="home_data_title">
            <h5>社員ID</h5>

            <span
              class="material-symbols-outlined"
              @click="handleShowInputCampanyId"
            >
              edit_square
            </span>
          </div>

          <div v-if="!show_input_array[3]" class="data_item">
            <div v-if="defaultdata">{{ defaultdata.company_id }}</div>
          </div>
          <div v-else>
            <div class="data_input_item">
              <input type="email" v-model="defaultdata.company_id" />
            </div>
            <button class="data_regist_button" @click="handleRegistCompanyId">
              登録
            </button>
          </div>
        </div>

        <div class="home_data_box">
          <div class="home_data_title">
            <h5>担当営業者</h5>
          </div>
          <div class="data_item">
            <div v-if="defaultdata">
              <PICStatus :id="defaultdata.id" :pic="defaultdata.pic" />
            </div>
          </div>
        </div>
      </div>

      <div class="home_data_row">
        <div class="home_data_box">
          <div class="home_data_title">
            <h5>入社日</h5>
            <span
              class="material-symbols-outlined"
              @click="handleShowInputEnterDate"
            >
              edit_square
            </span>
          </div>

          <div v-if="!show_input_array[6]" class="data_item">
            <div v-if="defaultdata.enter_date">
              <div v-if="typeof defaultdata.enter_date === 'string'">
                {{ defaultdata.enter_date }}
              </div>
              <div v-else>
                {{ defaultdata.enter_date.toDate().toLocaleDateString("sv-SE")
                }}{{ enter_date_timestamp }}
              </div>
            </div>
          </div>
          <div v-else>
     
              <input type="date" v-model="defaultdata.enter_date" />
        
            <div>
              <button class="data_regist_button" @click="handleRegistEnterDate">
                登録
              </button>
            </div>
          </div>
        </div>
        <div class="home_data_box">
          <div class="home_data_title">
            <h5>会社メールアドレス</h5>

            <span
              class="material-symbols-outlined"
              @click="handleShowInputCampanyMail"
            >
              edit_square
            </span>
          </div>

          <div v-if="!show_input_array[2]" class="data_item">
            <div v-if="defaultdata">{{ defaultdata.company_mail }}</div>
          </div>
          <div v-else>
            <div class="data_input_item">
              <input type="email" v-model="defaultdata.company_mail" />
            </div>
            <button class="data_regist_button" @click="handleRegistCompanyMail">
              登録
            </button>
          </div>
        </div>

        <div class="home_data_box">
          <div class="home_data_title">
            <h5>主要連絡メールアドレス</h5>

            <span
              class="material-symbols-outlined"
              @click="handleShowInputMajorMail"
            >
              edit_square
            </span>
          </div>
          <div v-if="!show_input_array[7]" class="data_item">
            <div v-if="defaultdata">{{ defaultdata.major_mail }}</div>
          </div>
          <div v-else>
            <div class="data_input_item">
              <input type="email" v-model="defaultdata.major_mail" />
            </div>
            <button class="data_regist_button" @click="handleRegistMajorMail">
              登録
            </button>
          </div>
        </div>
      </div>

      <div class="home_data_row">
        <div class="home_data_box">
          <div class="home_data_title">
            <h5>連絡先電話番号</h5>

            <span
              class="material-symbols-outlined"
              @click="handleShowInputPhone"
            >
              edit_square
            </span>
          </div>
          <div v-if="!show_input_array[8]" class="data_item">
            <div v-if="defaultdata">{{ formPhone(defaultdata.phone) }}</div>
          </div>
          <div v-else>
            <div class="data_input_item">
              <input type="text" v-model="defaultdata.phone" />
            </div>
            <button class="data_regist_button" @click="handleRegistPhone">
              登録
            </button>
          </div>
        </div>

        <div class="home_data_box">
          <div class="home_data_title">
            <h5>住所</h5>

            <span
              class="material-symbols-outlined"
              @click="handleShowInputAddress"
            >
              edit_square
            </span>
          </div>
          <div v-if="!show_input_array[9]" class="data_item">
            <div v-if="defaultdata">{{ defaultdata.address }}</div>
          </div>
          <div v-else>
            <div class="data_input_item">
              <input type="text" v-model="defaultdata.address" />
            </div>
            <button class="data_regist_button" @click="handleRegistAddress">
              登録
            </button>
          </div>
        </div>

        <div class="home_data_box">
          <div class="home_data_title">
            <h5>生年月日</h5>

            <span
              class="material-symbols-outlined"
              @click="handleShowInputBirthDay"
            >
              edit_square
            </span>
          </div>
          <div v-if="!show_input_array[10]" class="data_item">
            <div v-if="defaultdata">
              {{ defaultdata.birth_date }}
            </div>
          </div>
          <div v-else>
            <div class="data_input_item">
              <input type="date" v-model="defaultdata.birth_date" />
            </div>
            <button class="data_regist_button" @click="handleRegistBirthDate">
              登録
            </button>
          </div>
        </div>
      </div>

      <div class="home_data_row">
        <div class="home_data_box">
          <div class="home_data_title">
            <h5>緊急連絡先</h5>

            <span
              class="material-symbols-outlined"
              @click="handleShowInputEmergency"
            >
              edit_square
            </span>
          </div>
          <div v-if="!show_input_array[11]" class="data_item">
            <div v-if="defaultdata">
              {{ formPhone(defaultdata.emergency_phone) }}
            </div>
            <h6>緊急連絡先の方のお名前</h6>
            <div v-if="defaultdata">
              {{ defaultdata.emergency_name }}-{{
                defaultdata.emergency_relation
              }}
            </div>
          </div>
          <div v-else class="data_input_item">
            <div>
              <div>
                <h6>電話番号</h6>
                <input
                  type="text"
                  v-model="defaultdata.emergency_phone"
                  class="data_input_reguler"
                />

                <div>
                  <h6>ご親族のお名前</h6>
                  <input
                    type="text"
                    v-model="defaultdata.emergency_name"
                    class="data_input_reguler"
                  />
                </div>
                <div>
                  <h6>関係</h6>
                  <input
                    type="text"
                    v-model="defaultdata.emergency_relations"
                    class="data_input_mini"
                  />
                </div>
              </div>
              <button class="data_regist_button" @click="handleRegistEmergency">
                登録
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="pulse-box"><Pulse /></div>
  </div>
</template>

<script>
import { ref } from "vue";
import getDefaultData from "../composables/getDefaultData";
import Pulse from "../Pulse.vue";
import AreaStatus from "./AreaStatus.vue";
import { projectFirestore } from "@/firebase/config";
import useStorage from "../composables/useStorage";
import DefaultStatus from "./DefaultStatus.vue";
import PICStatus from "./PICStatus.vue";

export default {
  props: ["engineer_id"],
  name: "DetailSelectedEngineer",
  components: {
    Pulse,
    AreaStatus,
    DefaultStatus,
    PICStatus,
  },
  data() {
    return {
      isShowEditButton: true,
      isShowDeleteButton: true,
       
    };
  },
  computed: {},

  setup(props) {
    const show_input_array = ref(Array.from({ length: 15 }, () => false));
    const button_title_array = ref(Array.from({ length: 15 }, () => "修正"));
    const enter_date_timestamp = ref("")

    const { defaultdata, data_error, data_load } = getDefaultData(
      "engineers",
      props.engineer_id
    );
    data_load();

    
    const formPhone = (number) => {
      if (!number) return "";
      const match = number.match(/^(\d{3})(\d{4})(\d{4})$/);
      return match ? `${match[1]}-${match[2]}-${match[3]}` : number;
    };

    const handleShowInputName = () => {
      //名前は0番
      const targetIndex = 0;
      const res = changeShowInput(targetIndex);
      changeButtonName(res, targetIndex);
    };

    const handleRegistFace = async () => {
      const id = defaultdata.value.company_id.replace("@mik-s.work", "");

      const file = ref(null);
      const fileError = ref(null);
      const picture = document.getElementById("avater");
      const selected = picture.files[0];

      if (selected && id) {
        const { url, uploadImage, deleteImage } = useStorage("faces", id);

        deleteImage(id);

        file.value = selected;
        fileError.value = null;

        if (file.value) {
          await uploadImage(file.value);
          defaultdata.value.face_url = url.value;
          console.log("image uploaded, url:", url.value);
        }
      } else {
        file.value = null;
        face_url.value = null;
      }

      const post = {
        face_url: defaultdata.value.face_url,
      };
      updateData("name", post);
    };

    const handleRegistName = () => {
      const post = {
        last_name: defaultdata.value.last_name,
        first_name: defaultdata.value.first_name,
      };
      updateData("name", post);
      changeShowInput(0);
    };

    const handleRegistKana = () => {
      const post = {
        last_name_kana: defaultdata.value.last_name_kana,
        first_name_kana: defaultdata.value.first_name_kana,
      };
      updateData("kana", post);
      changeShowInput(1);
    };

    const handleRegistCompanyId = () => {
      const post = {
        company_id: defaultdata.value.company_id,
      };
      updateData("company_id", post);
      changeShowInput(3);
    };

    const handleRegistEnterDate = () => {
      const post = {
        enter_date: new Date(defaultdata.value.enter_date),
      };
      updateData("enter_date", post);
      changeShowInput(6);
    };

    const handleRegistCompanyMail = () => {
      const post = {
        company_mail: defaultdata.value.company_mail,
      };
      updateData("company_mail", post);
      changeShowInput(2);
    };

    const handleRegistMajorMail = () => {
      const post = {
        major_mail: defaultdata.value.major_mail,
      };
      updateData("major_mail", post);
      changeShowInput(7);
    };

    const handleRegistPhone = () => {
      const post = {
        phone: defaultdata.value.phone,
      };
      updateData("phone", post);
      changeShowInput(8);
    };

    const handleRegistAddress = () => {
      const post = {
        address: defaultdata.value.address,
      };
      updateData("address", post);
      changeShowInput(9);
    };

    const handleRegistBirthDate = () => {
      const post = {
        birth_date: defaultdata.value.birth_date,
      };
      updateData("birth_date", post);
      changeShowInput(10);
    };

    const handleRegistEmergency = () => {
      const post = {
        emergency_phone: defaultdata.value.emergency_phone,
        emergency_name: defaultdata.value.emergency_name,
        emergency_relation: defaultdata.value.emergency_relation,
      };
      updateData("emergency", post);
      changeShowInput(11);
    };

    const updateData = async (name, post) => {
      try {
        await projectFirestore
          .collection("engineers")
          .doc(props.engineer_id)
          .update(post);

        await projectFirestore
          .collection("skillData")
          .doc(props.engineer_id)
          .update(post);

        await projectFirestore
          .collection("holidayData")
          .doc(props.engineer_id)
          .update(post);

        await projectFirestore
          .collection("pointData")
          .doc(props.engineer_id)
          .update(post);

        await projectFirestore
          .collection("payData")
          .doc(props.engineer_id)
          .update(post);
      } catch (err) {
        console.log(`${name}の変更に失敗しました:`, err.value);
      }
    };

    const handleShowInputKana = () => {
      //ふりがなは1番
      const targetIndex = 1;
      const res = changeShowInput(targetIndex);
      changeButtonName(res, targetIndex);
    };

    const handleShowInputCampanyMail = () => {
      //会社メールは2番
      const targetIndex = 2;
      const res = changeShowInput(targetIndex);
      changeButtonName(res, targetIndex);
    };

    const handleShowInputCampanyId = () => {
      //会社IDは3番
      const targetIndex = 3;
      const res = changeShowInput(targetIndex);
      changeButtonName(res, targetIndex);
    };

    const handleShowInputArea = () => {
      //エリアは4番
      const targetIndex = 4;
      const res = changeShowInput(targetIndex);
      changeButtonName(res, targetIndex);
    };

    const handleShowInputPIC = () => {
      //担当は5番
      const targetIndex = 5;
      const res = changeShowInput(targetIndex);
      changeButtonName(res, targetIndex);
    };

    const handleShowInputEnterDate = () => {
      //入社日は6番
      const targetIndex = 6;
      const res = changeShowInput(targetIndex);
      changeButtonName(res, targetIndex);
    };

    const handleShowInputMajorMail = () => {
      //主要連絡先アドレスは7番
      const targetIndex = 7;
      const res = changeShowInput(targetIndex);
      changeButtonName(res, targetIndex);
    };

    const handleShowInputPhone = () => {
      //電話番号は8番
      const targetIndex = 8;
      const res = changeShowInput(targetIndex);
      changeButtonName(res, targetIndex);
    };

    const handleShowInputAddress = () => {
      console.log("click");
      //住所は9番
      const targetIndex = 9;
      const res = changeShowInput(targetIndex);
      changeButtonName(res, targetIndex);
    };

    const handleShowInputBirthDay = () => {
      //生年月日は10番
      const targetIndex = 10;
      const res = changeShowInput(targetIndex);
      changeButtonName(res, targetIndex);
    };

    const handleShowInputEmergency = () => {
      //緊急連絡先は11番
      const targetIndex = 11;
      const res = changeShowInput(targetIndex);
      changeButtonName(res, targetIndex);
    };

    const handleShowInputFace = () => {
      //顔写真は12番
      const targetIndex = 12;
      const res = changeShowInput(targetIndex);
      changeButtonName(res, targetIndex);
    };

    const handleShowInputStatus = () => {
      //ステータスは13番
      const targetIndex = 13;
      const res = changeShowInput(targetIndex);
      changeButtonName(res, targetIndex);
    };

    const changeShowInput = (targetIndex) => {
      show_input_array.value[targetIndex] =
        !show_input_array.value[targetIndex];

      return !show_input_array.value[targetIndex];
    };
    const changeButtonName = (is_show, targetIndex) => {
      if (is_show) {
        button_title_array.value[targetIndex] = "キャンセル";
      } else {
        button_title_array.value[targetIndex] = "修正";
      }
    };

    return {

      defaultdata,
      data_error,
      button_title_array,
      show_input_array,

      handleRegistFace,
      handleShowInputName,
      handleShowInputKana,
      handleShowInputCampanyMail,
      handleShowInputCampanyId,

      handleShowInputEnterDate,
      handleShowInputMajorMail,
      handleShowInputPhone,
      handleShowInputAddress,
      handleShowInputBirthDay,
      handleShowInputEmergency,
      handleShowInputFace,

      handleRegistName,
      handleRegistKana,

      handleRegistCompanyId,

      handleRegistEnterDate,
      handleRegistCompanyMail,
      handleRegistMajorMail,
      handleRegistPhone,
      handleRegistAddress,
      handleRegistBirthDate,
      handleRegistEmergency,

      formPhone,
    };
  },
  methods: {
    pushReturn() {
      const is_close = true;
      this.$emit("return-button-click", is_close);
      console.log("emit1");
    },
    pushEdit() {
      this.$emit("switch-edit-screen");
    },
    pushDelete() {
      this.$emit("switch-delete-screen");
    },
  },
};
</script>

<style>
.detail_engineer_box {
  width: 900px;
  background: white;
  border-radius: 10px;
  margin: 20px 20px 50px 20px;

  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 50px 0 50px 0;
}

.detail_engineer_box h3 {
  align-self: flex-start;
  margin-left: 62px;
}

.entry_part {
  margin-left: 62px;
  width: 400px;
}
.entry_part2 {
  margin-left: 62px;
}
.entry_item {
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}
.entry_item p {
  font-size: 14px;
  margin: 0;
}

.entry_item input {
  width: 259px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}
.default_item_name p {
  font-size: 14px;
  margin: 0;
}
.default_item_name {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}

.default_item_name input {
  width: 110px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
}

.entry_item_address {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}
.entry_item_address p {
  font-size: 14px;
  margin: 0;
}

.entry_item_address input {
  width: 550px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.emergency {
  display: flex;
}
.emergency .entry_item {
  width: 270px;
}

.face_up_box {
  width: 260px;
  height: 200px;
  background: #e3f5ff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #105ef5;
  font-size: 14px;
}

.add_engineer_box button {
  width: 241px;
  height: 33px;
  align-self: center;
  background: #f5c310;
  border: none;
  color: white;
  border-radius: 10px;
  margin-top: 62px;
  margin-bottom: 41px;
}

.pulse-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.face_box {
  width: 260px;
  height: auto;
  background: #e3f5ff;
  border-radius: 16px;
}

.return_btn2 {
  width: 139px;
  height: 37px;
  background: #2c3e5062;
  color: white;
  border: none;
  border-radius: 7px;
  margin-right: 20px;
}

.detail_button {
  width: 200px;
  height: 37px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.detail_button2 {
  width: 200px;
  height: 37px;
  background: gray;
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.detail_button_container {
  width: 900px;
  display: flex;
  margin: 20px;
  justify-content: space-between;
}

.detail_button_right {
  display: flex;
}

.default_item input {
  width: 120px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}
.data_fix_button_wrapper {
  margin-left: 30px;
}

.home_radio_item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.home_radio {
  margin: 5px 0;
  font-size: 13px;
  display: flex;
  align-items: center;
}
.home_radio_item input[type="radio"] {
  width: 20px;
  height: 20px;
}

.home_radio_item p {
  font-size: 10px;
  margin: 0 0 10px 0;
}
.face_image_prev {
  width: 200px;
  height: 200px;
  background-image: url("@/assets/mik_green.png");
  background-size: 200px;
  border-radius: 100px;
}
.home_data_row {
  display: flex;
  width: 100%;
  justify-content: center;
}

.home_data_row_face {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.home_data_box {
  border-radius: 30px;

  width: 230px;
  padding-bottom: 20px;

  text-align: center;
  margin: 20px;

  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.05);
}

.home_data_title {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c8e265;
  border-radius: 28px 28px 0 0;
}

.home_data_box input {
  width: 80px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.data_item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.data_input_item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.data_input_item input {
  width: 200px;
  display: flex;
  align-items: center;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.data_regist_button {
  align-self: center;
  margin-top: 10px;
}
.data_input_reguler {
  width: 200px;
  display: flex;
  align-items: center;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.data_input_mini {
  width: 50px;
  display: flex;
  align-items: center;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.face_select_back {
  width: 250px;
  height: 200px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ddd;
  flex-direction: column;
}

.filter {
  cursor: pointer;
}

.detail_engineer_box .material-symbols-outlined {
  font-size: 20px;
  cursor: pointer;
}
</style>
