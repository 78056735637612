<template>
  <div class="add_engineer_box">
    <div v-if="data_error">{{ data_error }}</div>


    <div v-if="defaultdata">

      <div class="identity_field">
        <h3>給与明細データ</h3>
        <div v-if="defaultdata" class="entry_item">
          <h5>エンジニア氏名</h5>
          {{ defaultdata.last_name }}{{ defaultdata.first_name }}
        </div>


        <div class="entry_item">
          <h5>ID</h5>
          <div v-if="defaultdata">{{ defaultdata.company_id }}</div>
        </div>

      </div>

      <div class="title_bar">
        <h2>{{ currentYear }}年</h2>
      </div>
      <div class="paybuttton_box">
        <div v-for="(item, index) in months" :key="index">

          <button class="month_button" @click="handleOpenPdf(currentYear, item.month)" :disabled="!item.enabled">{{
            item.month }}月</button>

        </div>
      </div>
      <div class="title_bar">
        <h2>過去分</h2>
      </div>
      <div class="past_year">

        <h5>{{ currentYear - 1 }}年</h5>

        <h5>{{ currentYear - 2 }}年</h5>
      </div>




      <button @click="pushReturn">戻る</button>
    </div>
    <div v-else class="pulse-box">
      <Pulse />
    </div>
  </div>
</template>

<script>
import getDefaultData from "../composables/getDefaultData";
import Pulse from "../Pulse.vue";
import getDatas from "../composables/getDatas";
import { onMounted, ref } from "vue";

export default {
  props: ["engineer_id"],
  name: "DetailSelectedEngineer",
  components: {
    Pulse,
  },
  data() {
    return {};
  },

  setup(props) {
    const { datas, load } = getDatas("pay_data_urls", props.engineer_id);

    const { defaultdata, data_error, data_load } = getDefaultData("engineers",
      props.engineer_id
    );
    const months = ref(Array.from({ length: 12 }, (_, i) => ({ month: i + 1, enabled: false })));
    const checkDataAvalability = () => {
      months.value.forEach(item => {
        item.enabled = checkMonthData(currentYear, item.month)
      })
    };

    const checkMonthData = (year, month) => {
      const paydata = datas.value.find(data => {
        const dataYear = Number(data.year)
        const dataMonth = Number(data.month)
        return dataYear === year && dataMonth === month
      });
      return !!paydata;
    }

    onMounted(async () => {
      await load();
      checkDataAvalability();
    })
    data_load();

    const currentYear = new Date().getFullYear();

    const handleOpenPdf = async (year, month) => {

      await load();
      const paydata = datas.value.find(data => {
        const dataYear = Number(data.year)
        const dataMonth = Number(data.month)
        return dataYear === year && dataMonth === month
      })
      console.log("paydata", paydata)
      if (paydata) {
        console.log("url", paydata.url)

        window.open(paydata.url, "_blank");
      } else {
        console.log("選択されたPaydataが見つかりません。")
      }

    }

    return { defaultdata, data_error, currentYear, months, handleOpenPdf };
  },
  methods: {
    pushReturn() {
      const is_close = true;
      this.$emit("return-button-click", is_close);
      console.log("emit1");
    },
    pushEdit() {
      const is_push = true;
      this.$emit("switch-edit-screen", is_push);
    },
  },
};
</script>

<style scoped>
.title_bar {
  width: 100%;
  height: 30px;
  background-color: rgba(245, 195, 16, 0.8);
  display: flex;

  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 30px;
  align-items: center;
  color: #fff;
  justify-content: center;

}



.identity_field {
  padding: 20px;
}

.past_year {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.paybuttton_box {
  width: 100%;

  flex-wrap: wrap;
  display: flex;
  margin-bottom: 50px;

}

.month_button {
  width: 250px;
  height: 60px;
  background: rgba(245, 195, 16, 1);
  margin: 20px;
  border-radius: 10px;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
}

.month_button:disabled {

  background: #e6e6e3;
  margin: 20px;
  border-radius: 5px;
  border: none;
  font-size: 15px;
  font-weight: bold;
  cursor: not-allowed;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

}
</style>
