<template>
  <div class="out_form_style">
    <form class="form_style" @submit.prevent="handleSubmit">

      <input
        class="input_box"
        type="text"
        required
        placeholder="display name"
        v-model="displayName"
      />
      <input
        class="input_box"
        type="email"
        required
        placeholder="email"
        v-model="email"
      />
      <input
        class="input_box"
        type="password"
        required
        placeholder="password"
        v-model="password"
      />

      <div>{{ error }}</div>
      <button class="button">Sign up</button>
    </form>
  </div>
</template>

<script>
import { ref } from "vue";
import useSignup from "../components/composables/useSignup";
export default {
  setup(props, context) {
    const { error, signup } = useSignup();
    const displayName = ref("");
    const email = ref("");
    const password = ref("");

    const handleSubmit = async () => {
      await signup(email.value, displayName.value, password.value);
      console.log("ユーザーはサインアップしました。");
      context.emit("signup");
    };

    return { displayName, email, password, error, handleSubmit };
  },
};
</script>

<style>
.out_form_style{
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.form_style{
  width:600px;
  display:flex;
  flex-direction: column;
  align-items:center;


}

.input_box {
  width: 220px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding-left:10px;
}

.button {
  width: 220px;
  height: 33px;
  align-self: center;
  background: #f5c310;
  border: none;
  color: white;
  border-radius: 10px;
  margin-top: 62px;
  margin-bottom: 41px;
}
</style>
