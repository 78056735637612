import { ref } from "vue";
import getDefaultDatas from "./getDefaultDatas"

const getReceiveHolidays = async (company_id) =>{
  const receive_holidays = ref([]);
  const total_receive_holiday = ref(0)
  const today = new Date();

  const {defaultdatas, datas_error, datas_load} = getDefaultDatas("holiday_add_delete")

  await datas_load();


  receive_holidays.value = defaultdatas.value
  .filter((user) => {
    return user.user_id === company_id;
  })
  .sort((a, b) => {
    return a.add_date - b.add_date;
  });



const receive = receive_holidays.value;
 total_receive_holiday.value = receive.reduce((total, data) => {
  if (data.delete_date) {
    const delete_day = new Date(data.delete_date.seconds * 1000);

    if (data.is_add && today < delete_day) {
      return total + data.num_of_date;
    } else {
      return total;
    }
  }
}, 0);

return { receive_holidays, total_receive_holiday}

}

export default getReceiveHolidays