<template>
  <div class="skill_data">
    <div class="data_title_box">
      <h2>Skill Data</h2>

      <button class="filter" @click="handleFilter">
        Filter<span class="material-symbols-outlined"> filter_alt </span>
      </button>
    </div>

    <table>
      <tr>
        <th>#</th>
        <th>Engineer Name</th>
        <th>
          <div v-if="!isFilter">エリア</div>
          <div v-else>
            エリア<select
              name="area"
              v-model="area_selected"
              @change="handleAreaChange"
              class="table_title_select"
            >
              <option disabled value="">選択</option>
              <option value="sapporo">札幌</option>
              <option value="morioka">盛岡</option>
              <option value="oosaka">大阪</option>
              <option value="fukuoka">福岡</option>
              <option value="all">すべて</option>
            </select>
          </div>
        </th>

        <th>
          <div v-if="!isFilter">担当者</div>
          <div v-else>
            担当者<select
              name="pic"
              v-model="pic_selected"
              @change="handlePICChange"
              class="table_title_select"
            >
              <option disabled value="">選択</option>
              <option value="kiyanagi">鬼柳</option>
              <option value="hata">畑</option>
              <option value="oda">小田</option>
              <option value="all">すべて</option>
            </select>
          </div>
        </th>
        <th>
          <div v-if="!isFilter">エンジニア領域</div>
          <div v-else>
            エンジニア領域
            <select
              name="pic"
              v-model="type_selected"
              @change="handleEngineerType"
              class="table_title_select"
            >
              <option disabled value="">選択</option>
              <option value="1">開発</option>
              <option value="2">インフラ</option>
              <option value="3">モバイル</option>
              <option value="4">ヘルプデスク</option>
              <option value="5">キッティング</option>
              <option value="6">その他</option>
              <option value="7">未設定</option>
              <option value="8">すべて</option>
            </select>
          </div>
        </th>

        <th>
          <div v-if="!isFilter">実務経験年数</div>
          <div v-else>
            実務経験年数
            <select
              name="pic"
              v-model="experience_selected"
              @change="handleExperience"
              class="table_title_select"
            >
              <option disabled value="">選択</option>
              <option value="0">リセット</option>
              <option value="1">降順↓</option>
              <option value="2">昇順↑</option>
            </select>
          </div>
        </th>
        <th>
          <div v-if="!isFilter">得意分野・言語</div>
          <div v-else>
            得意分野・言語
            <select
              name="specialty"
              v-model="specialty_selected"
              @change="handleSpecialty"
              class="table_title_select"
            >
              <option disabled value="">選択</option>
              <option v-for="option in specialtyOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
              <option value="none">すべて</option>
              </select>
          </div>
        </th>

        <th>
          <div v-if="!isFilter">経歴書確認</div>
          <div v-else>
            経歴書確認
            <select
              name="pic"
              v-model="is_document_selected"
              @change="handleIsDocument"
              class="table_title_select"
            >
              <option disabled value="">選択</option>
              <option value="1">経歴書あり</option>
              <option value="2">経歴書なし</option>
              <option value="0">すべて</option>
            </select>
          </div>
        </th>
      </tr>

      <tr
        v-for="(defaultdata, index) in currentPageItems"
        :key="defaultdata.id"
      >
        <td>
          {{ (index + 1).toString().padStart(2, "0") }}
        </td>
        <td>
          <div
            class="default_engineer_name_frame"
            @click="engineerCheck(defaultdata.id)"
          >
            <div v-if="defaultdata.face_url">
              <img :src="defaultdata.face_url" class="face" />
            </div>
            <div v-else><img src="@/assets/mik_green.png" class="face" /></div>
            <div class="engineer_name">
              {{ defaultdata.last_name }} {{ defaultdata.first_name }}
            </div>
          </div>
        </td>
        <td>
          <div v-if="defaultdata.area">
            <AreaStatus 
            :id="defaultdata.id"
            :area="defaultdata.area" />
          </div>
        </td>
        <td>
          <div v-if="defaultdata.pic">
            <PICStatus 
            :id="defaultdata.id"
            :pic="defaultdata.pic" />
          </div>
        </td>
        <td>
          <div class="skill_table_item">
          
              <JobStatus
              :id="defaultdata.id"
              :job="defaultdata.engineer_type"
              @change_job="handleLoad"
              />
  
        
          </div>
        </td>
        <td>
          <div class="skill_table_item">
            <div
              v-if="
                defaultdata.work_experience_year ||
                defaultdata.work_experience_month
              "
            >
              {{ defaultdata.work_experience_year }}年{{
                defaultdata.work_experience_month
              }}ヶ月
            </div>
            <div v-else>未登録</div>
          </div>
        </td>
        <td>
          <div class="skill_table_item">
            <div v-if="defaultdata.specialty" class="specialty_box">
              <div v-for="specialty in defaultdata.specialty" :key="specialty">
                {{ specialty }}/
              </div>
            </div>
          </div>
        </td>

        <td>
          <div v-if="defaultdata.data_url" class="button_wrapper">
            <button
              class="skilldata_button"
              @click="openPDF(defaultdata.data_url)"
            >
              技術経歴書
            </button>
          </div>
          <div v-else class="button_wrapper">経歴書なし</div>
        </td>
      </tr>
    </table>

    <div class="data_footer">
      <div class="previous_frame">
        <button
          class="previous_button"
          @click="prevPage"
          v-if="currentPage !== 1"
        >
          <span class="material-symbols-outlined"> west </span>Previous
        </button>
      </div>

      <ul>
        <li v-for="page in totalPages" :key="page" @click="changePage(page)">
          <button>{{ page }}</button>
        </li>
      </ul>
      <div class="next_frame">
        <button
          class="next_button"
          @click="nextPage"
          v-if="currentPage !== totalPages"
        >
          Next<span class="material-symbols-outlined"> east </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, toRef } from "vue";
import AreaStatus from "../home_comp/AreaStatus.vue";
import PICStatus from "../home_comp/PICStatus.vue";
import JobStatus from "./JobStatus.vue";

export default {
  props: {
    isSelect: Boolean,
    defaultdatas: {
      type: Array,
      default: () => [],
    },
    isFix: Boolean,
    reloadDefaultData: Function,
    now_page: Number,
  },
  name: "EngineerDefaultData",
  components: { JobStatus, AreaStatus, PICStatus },
  data() {
    return {
      itemsPerPage: 6,

      item_num: 1,
      selectedItems: [],
      isFilter: false,
      area_selected: null,
      pic_selected: null,
      enter_selected: null,
      status_selected: 0,
      type_selected: null,
      experience_selected: null,
      is_document_selected: null,
      specialty_selected: null,
      specialtyOptions: [],
    };
  },
  computed: {
    currentPageItems() {
      if (this.defaultdatas) {
        //this.defaultdatas.sort((b, a) => b.enter_date - a.enter_date);
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        return this.defaultdatas.slice(startIndex, endIndex);
      } else {
        return [];
      }
    },

    totalPages() {
      if (this.defaultdatas) {
        return Math.ceil(this.defaultdatas.length / this.itemsPerPage);
      }
      return 0;
    },
  },
  setup(props) {
    const currentPage = ref(1);

    const changePage = (pageNumber) => {
      currentPage.value = pageNumber;
    }
    if(props.now_page === 0 ){
      changePage(1)
    }else{
    changePage(props.now_page)
    }

    if (props.isFix) {
      console.log(props.defaultdatas);
      watch(
        () => props.defaultdatas.value,
        (newDefaultDatas) => {
          if (newDefaultDatas !== null) {
            props.defaultdatas.value = newDefaultDatas;
          }
        }
      );
      onMounted(() => {
        if (props.reloadDefaultData) {
          props.reloadDefaultData();
          console.log("mount");
        }
      });
    }

    return{
      currentPage,
      changePage,
    }
  },

  methods: {
    openPDF(url) {
      window.open(url, "_blank");
    },
   
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    engineerCheck(id) {
      if (this.selectedItems.length === 0) {
        this.selectedItems.push(id);
      } else {
        this.selectedItems.shift();
        this.selectedItems.push(id);
      }
      this.currentPageItems.forEach((defaultdata) => {
        if (!this.selectedItems.includes(defaultdata.id)) {
          defaultdata.isSelected = false;
        }
      });
      this.$emit("selected_engineer_id", this.selectedItems[0],this.currentPage);
    },
    handleFilter() {
      this.isFilter = !this.isFilter;
      this.fetchSpecialtyOptions();
    },
    handleAreaChange() {
      this.$emit("area_selected", this.area_selected);
    },
    handlePICChange() {
      this.$emit("pic_selected", this.pic_selected);
    },
    handleEngineerType() {
      this.$emit("type_selected", this.type_selected);
    },
    handleExperience() {
      this.$emit("experience_selected", this.experience_selected);
    },
    handleSpecialty() {
      this.$emit("specialty_selected", this.specialty_selected);
    },
    handleIsDocument() {
      console.log("handleisdocument");
      this.$emit("is_document_selected", this.is_document_selected);
    },
    handleLoad(){
      this.$emit("need_data_load")
    },
    fetchSpecialtyOptions() {
      if(this.defaultdatas){
      const specialties = new Set();
      this.defaultdatas.forEach((doc) => {
  
        if(doc.specialty) {
          doc.specialty.forEach((sub_data) => specialties.add(sub_data));
        }
      });

      this.specialtyOptions = Array.from(specialties).map((s) => ({
        label: s,
        value: s,
      }));

      console.log("specialty",this.specialtyOptions)
    }
    },
  },

};
</script>

<style>
.data_title_box {
  width: 1042px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.data_title_box button {
  width: 102px;
  height: 44px;
  border-radius: 12px;
  border: 1px solid #cdcde3;
  color: #a8a8bd;
  line-height: 19.6px;
  word-wrap: break-word;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter .material-symbols-outlined {
  color: #a8a8bd;
  font-size: 20px;
  margin-left: 5px;
}
.skill_data {
  width: 1135px;
  height: 652px;
  border-radius: 16px;
  background: white;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
}
.skill_data table {
  width: 1042px;
  height: 492px;
  border-collapse: collapse;
}

.skill_data td {
  border-top: 1px solid #ddd;
}
.skill_data .engineer_name_frame {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
}
.skill_data .engineer_name {
  width: 150px;
  display: flex;
  justify-content: flex-start;
  margin-left: 5px;
  cursor: pointer;
}
.face {
  width: 30px;
  height: 30px;
  background: #ddd;
  border-radius: 15px;
}

.skilldata_button {
  width: 110px;
  height: 30px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.no_skilldata_button {
  width: 110px;
  height: 40px;
  background: #ddd;
  color: white;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.data_footer {
  display: flex;
  margin-top: 22px;
  width: 1042px;
  height: 40px;

  justify-content: space-between;
  align-items: center;
}
.data_footer ul {
  display: flex;
  list-style: none;
  width: 292px;
  padding: 0;
  justify-content: space-around;
}
.data_footer li {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
}
.data_footer button {
  appearance: none;
  border: none;
  background: white;
  font-size: 16px;
}
.data_footer li :hover {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #006aff;
  background: rgba(0, 106, 255, 0.5);
  border: none;
}
.previous_button {
  width: 114px;
  height: 36px;
  color: #2c3e50;
  border: 1px solid #cdcde3;
  background: white;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.previous_frame {
  width: 114px;
}
.next_button {
  width: 88px;
  height: 36px;
  color: #2c3e50;
  border: 1px solid #cdcde3;
  background: white;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.next_frame {
  width: 88px;
}

.material-symbols-outlined {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
}
.data_footer .material-symbols-outlined {
  font-size: 16px;
  color: #2c3e50;
  width: 18px;
  display: flex;
  align-items: center;
}

.edit_check {
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #2c3e50;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.edit_check:checked {
  background: #58769f;
}

.specialty_box {
  display: flex;
  justify-content: center;
}

.skill_table_item {
  text-align: center;
}
</style>
