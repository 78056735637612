import { ref } from "vue";
import { projectFirestore } from "../../firebase/config";

const getAdminDatas = () => {
  const admindatas = ref([]);
  const error = ref(null);

  const load = async () => {
    try {
      const res = await projectFirestore.collection("admins").get();
      admindatas.value = res.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
    } catch (err) {
      error.value = err.message;
    }
  };
  return { admindatas, error, load };
};
export default getAdminDatas;
