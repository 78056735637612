<template>
  <div v-if="user_id" class="user_home">



    <h2>社員データ</h2>
    <div class="face_frame">
      <div v-if="defaultdata">
        <div v-if="defaultdata.face_url">
          <img class="user_photo" :src="defaultdata.face_url" />
        </div>
        <div v-else>
          <img src="@/assets/mik_logo.svg" class="face_image_prev" />
        </div>

        <div>
          <h4>氏名</h4>
          <p>{{ defaultdata.last_name }}{{ defaultdata.first_name }}</p>
        </div>

        <div>
          <h4>ID</h4>
          <p>{{ user_id }}</p>
        </div>


      </div>
    </div>



    <div v-if="skilldata">
      <h4>エンジニア領域</h4>
      <JobStatus :job="skilldata.engineer_type" />

    </div>

    <div v-if="defaultdata">
      <h4>入社日</h4>
      <div v-if="typeof defaultdata.enter_date === 'string'">
        <p>
          {{ defaultdata.enter_date }}
        </p>
      </div>
      <div v-else>{{ defaultdata.enter_date.toDate().toLocaleDateString("sv-SE") }}</div>

    </div>
    <div v-if="holiday_data">
      <h4>次回有休付与日</h4>
      <p>
        {{ holiday_data.next_add }}
      </p>

      <h4>次回の有休付与日数</h4>
      <p>{{ holiday_data.num_of_add }}日</p>
    </div>

    <div v-if="holiday_data">
      <h4>あなたの有休残日数</h4>
      <p>{{ holiday_data.receive_num_of_day + holiday_data.twoyear_before_remain - totalHoliday }}日</p>

      <div class="caution_box">
        <div class="caution_title">
          <h5>注意</h5>
        </div>
        <div class="caution_string">有休付与日直後から約2週間程度、<br />有休残日数が未確定となっています。<br />
          この期間に正確な有休残日数を確認されたい場合は、<br />担当営業までご連絡ください。
        </div>
      </div>

      <!-- <div class="caution_box2">
        <div class="caution_title">
          <h5>夏季休暇について</h5>
        </div>
        <div class="caution_string">
          MIKの夏季休暇は13日、14日です。<br>
          13日、14日もしくはその振替で夏季休暇を取得される場合は、<br>有給が減らない欠勤・休暇申請よりご申請ください。
        </div>
      </div> -->
    </div>
    <div class="item" v-if="holiday_data">

      <div v-if="isHolidayVue" class="form_wrapper">
        <Holiday :user_name="`${defaultdata.last_name} ${defaultdata.first_name}`" :pic_name="defaultdata.pic"
          :id="user_id" :user_datas="holiday_user_datas"
          :rest_holiday="holiday_data.receive_num_of_day + holiday_data.twoyear_before_remain - totalHoliday"
          :form="form_type" @close_holiday_form="handleCloseHolidayForm" />
      </div>


      <div class="data_button">

        <div
          v-if="!isHolidayVue && (holiday_data.receive_num_of_day + holiday_data.twoyear_before_remain - totalHoliday) > 0">
          <button @click="handleUseholiday" class="holiday_button">有休申請</button>
        </div>

        <div v-if="!isHolidayVue">
          <button @click="handleUseAbsent" class="absent_button">欠勤・休暇申請</button>
        </div>


        <div v-if="!isHolidayDataVue">
          <button @click="handleVueHolidayData" class="holiday_data_button">休暇申請状況</button>
        </div>

      </div>

      <div v-if="isHolidayDataVue" class="data_wrapper">
        <HolidayData :user_id="user_id" :next_add="holiday_data.next_add" @data_delete="handleHolidayDataDelete"
          @holiday_data_close="handleVueHoliDataClose" />
      </div>


    </div>

    <div class="item" v-if="point_data">
      <h4>あなたの社内貢献ポイント</h4>
      <div class="data_button">
        <p>{{ point_data.hold_point }}pt</p>
        <div v-if="!isUsepointVue && point_data.hold_point > 0">
          <button @click="handeleUsepoint" class="holiday_button">利用する</button>
        </div>

        <div v-if="isUsepointVue">
          <UsePoint :point="point_data.hold_point" :user_id="id"
            :user_name="`${defaultdata.last_name} ${defaultdata.first_name}`" :pic_name="defaultdata.pic"
            @point_used="handleUsedPoint" @point_form_close="handleFormClose" />
        </div>

        <div v-if="!isPointDataVue">
          <button class="holiday_data_button" @click="handlePointDataVue">ポイント詳細</button>
        </div>
      </div>

      <div v-if="isPointDataVue">
        <PointData :user_id="id" @point_data_close="handelPointDataClose" />

      </div>

      <div class="caution_box">
        <div class="caution_title">
          <h5>注意</h5>
        </div>
        <div class="caution_string">ポイント利用は10ポイントからです。<br />ポイントの換金の上限は200ポイント/月となります。<br />
          不明な点、確認したいことがございましたら<br />担当営業までご連絡ください。
        </div>
      </div>
    </div>

    <div>
      <h5>MIKイントラサイトが新しくなりました。以下よりログインできます。</h5>
      <a href="https://mik-insite.vercel.app/" target="_blank" rel="noopener noreferrer">
        <img src="@/assets/insite_logo.svg" alt="MIKインサイト" class="insite_logo_image">
      </a>
    </div>


  </div>
</template>

<script>
import HumbergerMenu from "@/components/HumbergerMenu.vue";
import getUser from "../composables/getUser";
import getDefaultData from "../composables/getDefaultData";
import Holiday from "./Holiday.vue";
import UsePoint from "./UsePoint.vue";
import { onMounted, ref } from "vue";
import getDefaultDatas from "../composables/getDefaultDatas";
import HolidayData from "./HolidayData.vue";
import PointData from "./PointData.vue";
import JobStatus from "../user_comp/JobStatus.vue";
import getCalcTotalUse from "../composables/getCalcTotalUse";
import router from "@/router";

export default {
  components: {
    HumbergerMenu,
    Holiday,
    UsePoint,
    HolidayData,
    PointData,
    JobStatus,
  },
  props: ['user_mail'],
  data() {
    return {
      isHolidayVue: false,
      isHolidayDataVue: false,
      isUsepointVue: false,
      isPointDataVue: false,

      holiday_button_title: "利用する",
      form_type: "",
    };
  },
  setup(props) {
    const totalHoliday = ref("");
    const holiday_user_datas = ref([]);
    const rest_holiday = ref(0);
    const user_id = ref("");


    if (!props.user_mail) {
      const { user } = getUser();
      user_id.value = user.value.email;
    } else {
      user_id.value = props.user_mail;
    }

    const id = user_id.value.replace("@mik-s.work", "");
    console.log(id);

    const { defaultdata, error, data_load } = getDefaultData("engineers", id);

    const {
      defaultdata: skilldata,
      error: skill_error,
      data_load: skill_load,
    } = getDefaultData("skillData", id);

    const {
      defaultdata: holiday_data,
      holiday_error,
      data_load: holiday_load,
    } = getDefaultData("holidayData", id);


    const {
      defaultdatas: submitted_all_datas,
      datas_load: submit_datas_load,
    } = getDefaultDatas("holiday_application");

    const UsersHolidayDataLoad = async () => {
      await holiday_load();

      await submit_datas_load();

      if (submitted_all_datas.value) {

        holiday_user_datas.value = submitted_all_datas.value.filter((data) => {
          console.log("user_id", user_id.value)
          return data.userid === user_id.value;
        });


        //提出済みの休暇日数をトータル

        const { totalUseHoliday } = await getCalcTotalUse(holiday_data.value.next_add, holiday_user_datas.value)
        totalHoliday.value = totalUseHoliday;
        console.log("totalHoliday", totalHoliday.value)


        // holiday_user_datas.value.reduce(
        //   (total, data) => total + parseFloat(data.use_holiday_num),
        //   0
        // );
      }

    };
    UsersHolidayDataLoad();


    onMounted(async () => {
      await data_load();
      await skill_load();
      await holiday_load();
      await UsersHolidayDataLoad();

    })



    const {
      defaultdata: point_data,
      error: point_error,
      data_load: point_data_load,
    } = getDefaultData("pointData", id);
    point_data_load();

    const changePayVue = () => {
      router.push({ name: "User_Pay", query: { id: id } })
    }

    return {
      user_id,
      id,

      defaultdata,
      skilldata,
      holiday_data,
      point_data,
      point_error,
      totalHoliday,
      holiday_user_datas,
      rest_holiday,
      submitted_all_datas,

      submit_datas_load,
      point_data_load,
      UsersHolidayDataLoad,
      changePayVue,
    };
  },
  methods: {
    handleUseholiday() {
      this.form_type = "holiday";
      if (this.form_type === "holiday") {
        this.isHolidayVue = !this.isHolidayVue;
        this.isHolidayDataVue = false;
      }
    },
    handleUseAbsent() {
      this.form_type = "absent";
      if (this.form_type === "absent") {
        this.isHolidayVue = !this.isHolidayVue;
        this.isHolidayDataVue = false;
        console.log("formtype", this.form_type)
      }
    },
    handleVueHolidayData() {
      this.isHolidayDataVue = !this.isHolidayDataVue;
    },

    handleVueHoliDataClose() {
      this.isHolidayDataVue = false;
    },
    handeleUsepoint() {
      this.isUsepointVue = true;
    },
    handleFormClose() {
      this.isUsepointVue = false;
    },
    async handleUsedPoint(use_point) {
      await this.point_data_load()
      this.isUsepointVue = false;

      //await new Promise(resolve => setTimeout(resolve, 10));

      alert(`${use_point}ポイント分の利用申請を行いました。`)
    },
    async handleCloseHolidayForm(message) {
      this.isHolidayVue = false;
      this.holiday_button_title = "利用する";

      this.UsersHolidayDataLoad();

      if (message !== null) {
        alert(message)
      }

    },


    async handleHolidayDataDelete() {
      this.UsersHolidayDataLoad();

    },
    handlePointDataVue() {
      this.isPointDataVue = true;
    },
    handelPointDataClose() {
      this.isPointDataVue = false;
    }
  },
};
</script>

<style>
.user_home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;

}

.user_screen {
  background: #c1ff72;

  padding-top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0px;
}

.form_wrapper {
  display: flex;
  justify-content: center;
}

.data_wrapper {
  display: flex;
  justify-content: center;
  width: 350px;
}

.user_screen_hr {
  width: 380px;
}

.user_data {
  position: relative;
  width: 600px;
  height: 1560px;

  background: white;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
}

.face_frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.user_photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: darkgray;
}

.button_frame {
  margin-top: 20px;
  width: 380px;
  display: flex;
  flex-direction: column;
}

.button_frame_row {
  display: flex;
  justify-content: space-between;
  height: 70px;
  align-items: center;
}

.button {
  width: 180px;
  height: 53px;
  align-self: center;
  background: #f5c310;
  border: none;
  color: white;
  border-radius: 10px;
}

.data_button {
  width: 100px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.item p {
  font-size: 18px
}

.humberger_menu {
  position: absolute;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  right: 25px;
}

.humberger_menu .material-symbols-outlined {
  color: #33404e;
  font-size: 50px;
}



.holiday_button {
  width: 200px;
  height: 37px;
  background: #c1ff72;

  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

.absent_button {
  width: 200px;
  height: 37px;
  background: gray;
  color: white;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;

}

.holiday_data_button {
  width: 200px;
  height: 37px;
  background: white;
  color: gray;
  border: 3px solid gray;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: bold;

}

.caution_box {
  display: flex;
  justify-content: center;
  flex-direction: column;
}




.caution_string {
  font-size: 12px;
  margin: 10px 0 40px 0;


}

.caution_title {
  display: flex;
  justify-content: center;

}

.caution_box h5 {
  margin: 0px;
  padding-top: 0px;
  width: 80px;
  height: 20px;
  background-color: #c1ff72;
}

.caution_box2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.caution_box2 h5 {
  margin: 0px;
  padding-top: 0px;
  width: 180px;
  height: 20px;
  background-color: #e265c9;
  ;
}

.pay_frame {
  width: 100%;
  display: flex;
  justify-content: center;
}

.pay_button {

  width: 200px;
  height: 37px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

.pay_button:disabled {
  background-color: #ddd;
  cursor: default;
}

.insite_logo_image {
  width: 400px;
}
</style>
