import { projectAuth, projectStorage } from "@/firebase/config";
import { ref } from "vue";
import getUser from "./getUser";

const useStorage = (folder_name, engineer_id) => {
  const error = ref(null);
  const url = ref(null);
  const filePath = ref(null);

  

  const uploadImage = async (file) => {
    filePath.value = `${folder_name}/${engineer_id}/${file.name}`;
    const storageRef = projectStorage.ref(filePath.value);

    try {
      const res = await storageRef.put(file);
      url.value = await res.ref.getDownloadURL();
    } catch (err) {
      console.log(err.message);
      error.value = err.message;
    }
  };

  const deleteImage = async (id) => {
    filePath.value = `${folder_name}/${id}`;
    console.log("filepath", filePath.value);
    if (filePath.value) {
      let desertRef = projectStorage.ref(filePath.value);

      try {
        const res = await desertRef.getDownloadURL();
        console.log("Fileあり");
        await desertRef.delete();
        url.value = null;
      } catch (err) {
        console.log("Fileなし");
        console.log(err.message);
      }
    }
  };

  return { url, filePath, error, uploadImage, deleteImage };
};

export default useStorage;
