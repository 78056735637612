<template>
  <div class="status_box">
    <div class="status_work" v-if="status_number == 1">
      消化済
    </div>
    <div class="status_pending" v-if="status_number == 2">
      消化予定
    </div>
    <div class="status_selecting" v-if="status_number == 3">
      未消化
    </div>
    <div class="status_rest" v-if="status_number == 4">
      未使用
    </div>
    <div class="status_add_rest" v-if="status_number == 5">
      有休付与待ち
    </div>
  </div>
</template>

<script>
export default {
  name: "HolidayStatus",
  props: ["status_number"],
};
</script>

<style>
.status_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.status_work {
  width: 88px;
  height: 34px;
  border-radius: 30px;
  background: #d9f2dd;
  color: #359742;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}
.status_pending {
  width: 88px;
  height: 34px;
  border-radius: 30px;
  background:  #d0a5836f;
  color: #e97d24;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}
.status_selecting {
  width: 88px;
  height: 34px;
  border-radius: 30px;
  background: #d9e7f2;
  color: #437ac7;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}
.status_rest {
  width: 88px;
  height: 34px;
  border-radius: 30px;
  background: #e7eaec;
  color: #36383a;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}

.status_add_rest {
  width: 88px;
  height: 34px;
  border-radius: 30px;
  background: #e398b6;
  color: #e34b88;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}
</style>
