import { projectFirestore } from "@/firebase/config";
import { ref } from "vue";

const getDefaultData = (collection_name,id) => {
  const defaultdata = ref(null);
  const data_error = ref(null);


  const data_load = async () => {
    if (id === undefined) {
      return;
    }
    try {

      await new Promise(resolve =>{setTimeout(resolve,2000)});
      let res = await projectFirestore.collection(collection_name).doc(id).get();

        
      if (!res.exists) {
        throw Error("データがありません");
      }
      defaultdata.value = { ...res.data(), id: res.id };
      console.log(defaultdata.value)
    } catch (err) {
      data_error.value = err.message;
      console.log(data_error.value);
    }
  };
  return { defaultdata, data_error, data_load };
};

export default getDefaultData;
