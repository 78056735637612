<template>
  <div class="top-screen">
    <AsideVue screen_name="Skill Data" @screen_reset="handleResetScreen" />
    <div class="home">
      <div class="skill_button_area">
        <div v-if="is_all_button">
          <!-- <button class="reg_button">一覧表示</button> -->
        </div>

        <div v-if="is_reg_ret_button">
          <button @click="handleReturnButtonClick" class="reg_ret_button">
            もどる
          </button>
        </div>
        <div v-if="isShowEDD" class="serach_box_wrapper">
          <div class="skill_search_box">
            <span class="material-symbols-outlined"> search </span>
            <input type="search" placeholder="search" v-model="search_item" />
          </div>
        </div>
      </div>

      <div v-if="isShowRegist">
        <div v-if="receiveId">
          <RegistData
            :engineer_id="receiveId"
            @regist_close="handleRegistClose"
            @edit-to-description="handlePushDescription"
          />
        </div>
      </div>

      <div v-if="isShowEDD">
        <EngineerDefaultData
          :isSelect="isSelect"
          :defaultdatas="searchedDatas"
          :isFix="isFix"
          :now_page="pageNo"
          @selected_engineer_id="handleChildData"
          @area_selected="handleFilterArea"
          @pic_selected="handleFilterPIC"
          @type_selected="handleFilterType"
          @experience_selected="handleSortExperience"
          @specialty_selected="handlePickSpecialty"
          @is_document_selected="handleFindDocument"
          @need_data_load="handleLoad"
        />
      </div>

      <div v-if="receiveId && isShowDetail">
        <DetailSelectedEngineer
          :engineer_id="receiveId"
          @return-button-click="handleReturnButtonClick"
          @switch-edit-screen="handlePushEditButton"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AsideVue from "../components/Aside.vue";
import "@material/web/checkbox/checkbox.js";
import EngineerDefaultData from "../components/skill_comp/EngineerDefaultData.vue";
import getDefaultDatas from "../components/composables/getDefaultDatas";
import DetailSelectedEngineer from "../components/skill_comp/DetailSelectedEngineer.vue";
import RegistData from "@/components/skill_comp/RegistData.vue";
import { onMounted, ref, watch } from "vue";
import getPicDatas from "@/components/composables/getPicDatas";

export default {
  name: "Skill",
  components: {
    AsideVue,
    EngineerDefaultData,
    DetailSelectedEngineer,

    RegistData,
  },
  data() {
    return {
      isShowAddEngineer: false,
      isShowConfirmEngineer: false,
      isShowEDD: true,
      is_onEdit: false,
      is_onDetail: false,
      is_onDelete: false,
      isSelect: false,
      edit_button_text: "編集",
      delete_button_text: "削除",
      detail_button_text: "詳細",
      defaultdata: null,
      receiveId: null,
      isShowDetail: false,
      isShowDelete: false,
      isShowFix: false,
      isDetailItem: false,
      isShowButtonBox: true,
      isFix: false,
      on_edit_name: null,
      isShowRegist: false,
      is_reg_button: false,
      is_reg_ret_button: false,
      is_all_button: true,
      pageNo: 0,
    };
  },
  setup() {
    const search_item = ref("");
    const fixted_search_item = ref("");
    const searchedDatas = ref([]);
    const local_pic_filter_data = ref([]);

    const { defaultdatas, datas_error, datas_load } =
      getDefaultDatas("skillData");

    onMounted(async () => {
      await datas_load();
      const pic_filter_data = await getPicDatas(defaultdatas.value);
      local_pic_filter_data.value = pic_filter_data.value;
      searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
        return a.enter_date - b.enter_date;
      });
    });

    watch(
      search_item,
      async () => {
        if (local_pic_filter_data.value) {
          if (search_item.value) {
            fixted_search_item.value = search_item.value.toLowerCase();
            const pic_filter_data = await getPicDatas(defaultdatas.value);
            local_pic_filter_data.value = pic_filter_data.value;
            searchedDatas.value = local_pic_filter_data.value.filter((data) => {
              const fieldsToSearch = [
                "last_name",
                "first_name",
                "last_name_kana",
                "first_name_kana",
                "company_id",
              ];

              return fieldsToSearch.some((field) => {
                return (
                  data[field] &&
                  data[field].toLowerCase().includes(fixted_search_item.value)
                );
              });
            });
          } else {
            const pic_filter_data = await getPicDatas(defaultdatas.value);
            local_pic_filter_data.value = pic_filter_data.value;
            searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
              return a.enter_date - b.enter_date;
            });
          }
        }
        console.log("watch function");
      },
      { deep: true }
    );

    const handleLoad = async () => {
      await datas_load();
      console.log("データロードした");
      searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
        return a.enter_date - b.enter_date;
      });
    };

    const handleFilterArea = (area_selected) => {
      console.log("area_selected:", area_selected);
      if (area_selected !== null && area_selected !== "all") {
        searchedDatas.value = local_pic_filter_data.value.filter((data) => {
          return data.area === area_selected;
        });
      } else {
        searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
          return a.enter_date - b.enter_date;
        });
      }
    };

    const handleFilterPIC = (pic_selected) => {
      if (pic_selected !== null && pic_selected !== "all") {
        searchedDatas.value = local_pic_filter_data.value.filter((data) => {
          return data.pic === pic_selected;
        });
      } else {
        searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
          return a.enter_date - b.enter_date;
        });
      }
    };

    const handleFilterType = (type_selected) => {
      console.log("clickfilter", type_selected);
      const type = ref("");
      if (type_selected !== null && type_selected < "8") {
        switch (type_selected) {
          case "1":
            type.value = "development";
            break;

          case "2":
            type.value = "infra";
            break;

          case "3":
            type.value = "mobile";
            break;

          case "4":
            type.value = "helpdesk";
            break;

          case "5":
            type.value = "kitting";
            break;

          case "6":
            type.value = "other";
            break;

          case "7":
            type.value = "none";
            break;

          default:
            type.value = "none";
        }
        console.log("type:", type.value);

        if (type.value === "none") {
          searchedDatas.value = local_pic_filter_data.value
            .filter((data) => {
              return (
                !data.hasOwnProperty("engineer_type") ||
                data.engineer_type === null
              );
            })
            .sort((a, b) => {
              return a.enter_date - b.enter_date;
            });
        }

        searchedDatas.value = local_pic_filter_data.value.filter((data) => {
          return data.engineer_type === type.value;
        });
      } else {
        searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
          return a.enter_date - b.enter_date;
        });
      }
    };

    const handleSortExperience = (experience_selected) => {
      console.log("experience_selected", experience_selected);
      if (experience_selected !== "0") {
        searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
          const experienceYearA = a.work_experience_year;
          const experienceYearB = b.work_experience_year;
          const experienceMonthA = a.work_experience_month;
          const experienceMonthB = b.work_experience_month;

          if (experienceYearA !== experienceYearB) {
            //年で比較
            if (experience_selected === "1") {
              //降順
              return experienceYearB - experienceYearA;
            } else if (experience_selected === "2") {
              //昇順
              return experienceYearA - experienceYearB;
            }
          }else {
            if (experience_selected === "1") {
              //降順
              return experienceMonthB - experienceMonthA;
            } else if (experience_selected === "2") {
              //昇順
              return experienceMonthA - experienceMonthB;
            }

          }
        });
      } else {
        searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
          return a.enter_date - b.enter_date;
        });
      }
    };

    const handlePickSpecialty = (specialty_selected) => {
      if (specialty_selected !== "none") {
        searchedDatas.value = local_pic_filter_data.value.filter((data) => {
          return data.specialty && data.specialty.includes(specialty_selected);
        });
      } else {
        searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
          return a.enter_date - b.enter_date;
        });
      }
    };

    const handleFindDocument = (is_document_selected) => {
      console.log("find_document", is_document_selected);
      if (is_document_selected === "1") {
        searchedDatas.value = local_pic_filter_data.value
          .filter((data) => {
            return data.data_url;
          })
          .sort((a, b) => {
            return a.enter_date - b.enter_date;
          });
      } else if (is_document_selected === "2") {
        searchedDatas.value = local_pic_filter_data.value
          .filter((data) => {
            return !data.data_url;
          })
          .sort((a, b) => {
            return a.enter_date - b.enter_date;
          });
      } else {
        searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
          return a.enter_date - b.enter_date;
        });
      }
    };

    return {
      search_item,
      searchedDatas,
      defaultdatas,
      local_pic_filter_data,
      datas_load,
      handleFilterArea,
      handleSortExperience,
      handleFilterPIC,
      handleFilterType,
      handleFindDocument,
      handlePickSpecialty,
      handleLoad,
    };
  },
  computed: {},
  methods: {
    showAddEngineer() {
      this.isShowAddEngineer = !this.isShowAddEngineer;
      this.isShowEDD = !this.isShowEDD;
    },

    selectDetail(receiveId) {
      this.on_edit_name = "detail";
      this.is_onDetail = true;
      this.isSelect = !this.isSelect;
      if (this.on_edit_name === "detail" && this.isSelect) {
        this.detail_button_text = "このユーザーの詳細を見る";
      } else {
        if (receiveId !== null) {
          this.isShowDetail = !this.isShowDetail;
          this.isShowEDD = !this.isShowEDD;
          this.isShowButtonBox = !this.isShowButtonBox;
          this.detail_button_text = "詳細";
        } else {
          this.detail_button_text = "詳細";
          this.is_onDetail = false;
        }
      }
    },
    disableOtherButton() {
      if (this.isSelect) {
        if (isDetailItem) {
        }
      }
    },
    selectDelete(receiveId) {
      this.on_edit_name = "delete";
      this.is_onDelete = true;
      this.isSelect = !this.isSelect;

      if (this.on_edit_name === "delete" && this.isSelect) {
        this.delete_button_text = "このユーザーを削除する";
      } else {
        if (receiveId !== null) {
          this.isShowDelete = true;
          this.delete_button_text = "削除";
        } else {
          this.delete_button_text = "削除";
          this.is_onDelete = false;
        }
      }
    },
    handleChildData(data_id, page_no) {
      this.receiveId = data_id;
      this.isShowDetail = !this.isShowDetail;
      this.isShowEDD = !this.isShowEDD;
      this.is_all_button = false;
      this.is_reg_ret_button = true;
      this.isShowRegist = false;
      this.is_reg_button = true;
      this.pageNo = page_no;
    },
    async handleReturnButtonClick() {
      await this.datas_load();
      const pic_filter_data = await getPicDatas(this.defaultdatas);
      this.searchedDatas = pic_filter_data.value.sort((a, b) => {
        return a.enter_date - b.enter_date;
      });
      console.log("this.local_pic_filter", this.local_pic_filter_data);
      this.isShowEDD = true;
      this.isShowDetail = false;
      this.isShowRegist = false;
      this.is_reg_ret_button = false;
      this.is_reg_button = false;
      this.is_all_button = true;

      this.receiveId = "";
    },
    handlePushEditButton(is_push) {
      this.isShowDetail = !is_push;
      this.isShowFix = is_push;
      this.isShowButtonBox = false;
    },
    handlePushDescription(is_push) {
      this.isShowDetail = is_push;
      this.isShowFix = !is_push;
    },
    handleFixClose(is_close) {
      this.isShowFix = !is_close;
      this.isShowEDD = is_close;
      this.isShowButtonBox = true;
      this.isFix = true;
    },
    handleRegistClose() {
      this.isShowRegist = false;
      this.isShowEDD = true;
      this.is_reg_ret_button = false;
      this.isShowButtonBox = true;
    },
    handleShowRegist() {
      this.isShowRegist = true;
      this.isShowDetail = false;
      this.is_reg_button = false;
      this.is_all_button = false;
      this.is_reg_ret_button = true;
    },
    handleResetScreen() {
      this.isShowAddEngineer = false;
      this.isShowButtonBox = true;
      this.isShowEDD = true;
      this.isReturnButton = false;
      this.isShowDetail = false;
      this.is_reg_ret_button = false;
      this.is_all_button = true;
    },
  },
};
</script>

<style>
.top-screen {
  display: flex;
}
.home {
  background: #f8ffe4;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.button_box {
  margin-top: 71px;
  margin-left: 20px;
  margin-bottom: 25px;
  display: flex;
}
.edit_button {
  width: 150px;
  height: 32px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 5px;
  margin-right: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.on_edit_button {
  width: 200px;
  height: 37px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.skill_button_area {
  display: flex;
}

.reg_button {
  width: 150px;
  height: 32px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 5px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.reg_button_long {
  width: 180px;
  height: 32px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 5px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.reg_ret_button {
  width: 150px;
  height: 32px;
  background: #2c3e5062;
  color: white;
  border: none;
  border-radius: 5px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.skill_search_box {
  width: 250px;
  height: 23px;
  border-radius: 40px;
  border: 1px solid #cdcde3;
  display: flex;
  align-items: center;
  background: white;

  padding-left: 10px;

  margin: 20px;
}

.skill_search_box input {
  border: none;
  outline: none;
  width: 200px;
}
.skill_search_box input::placeholder {
  color: #cdcde3;
}

.skill_search_box .material-symbols-outlined {
  color: #cdcde3;
  font-size: 20px;
  margin-left: 5px;
}
</style>
