<template>
  <div class="status_box">
    <select
      v-model="user_area"
      class="select"
      :style="{ background: activeColor }"
      @change="handleAreaChange(user_area)"
      
    >
      <option value="sapporo">札幌</option>
      <option value="morioka">盛岡</option>
      <option value="tokyo">東京</option>
      <option value="oosaka">大阪</option>
      <option value="fukuoka">福岡</option>
    </select>

   
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import useUpdateData from '../composables/userUpdateData';
export default {
  name: "DefaultStatus",
  props: ["id","area"],
  setup(props,context){
    const user_area = ref(props.area);
    const activeColor = ref("");

    if (user_area.value === "sapporo") {
      activeColor.value = "#3da9fc";
    } else if (user_area.value === "morioka") {
      activeColor.value = "#f582ae";
    } else if (user_area.value === "tokyo") {
      activeColor.value = "#44B5EA";
    } else if (user_area.value === "oosaka") {
      activeColor.value = "#ff8e3c";
    }else if (user_area.value === "fukuoka") {
      activeColor.value = "#6246ea";
    }

    watch(
      ()=> user_area.value,
      (newArea) => {
        
        if (newArea === "sapporo") {
      activeColor.value = "#3da9fc";
    } else if (newArea === "morioka") {
      activeColor.value = "#f582ae";
    } else if (user_area.value === "tokyo") {
      activeColor.value = "#44B5EA";
    } else if (newArea === "oosaka") {
      activeColor.value = "#ff8e3c";
    }else if (newArea === "fukuoka") {
      activeColor.value = "#6246ea";
    }
      }
    )

    const handleAreaChange = (area) =>{
      const post= {
        area:area
      }

      const collection_array = ["engineers","holidayData","skillData","pointData","payData"]

      const {error, updateData} = useUpdateData()
      updateData(collection_array,post,props.id);
    }

    return {
      user_area,
      activeColor,
      handleAreaChange,
    }
  }
 
  
};
</script>

<style>
.status_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.status_sapporo {
  width: 70px;
  height: 34px;
  border-radius: 30px;
  background: #3da9fc;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:14px;
  font-weight:bold;
}
.status_morioka {
  width: 70px;
  height: 34px;
  border-radius: 30px;
  background: #f582ae;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:14px;
  font-weight:bold;
}

.status_oosaka {
  width: 70px;
  height: 34px;
  border-radius: 30px;
  background: #ff8e3c;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:14px;
  font-weight:bold;
}

.status_fukuoka {
  width: 70px;
  height: 34px;
  border-radius: 30px;
  background: #6246ea;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:14px;
  font-weight:bold;
}


</style>
