import { projectFirestore } from "@/firebase/config";
import { ref } from "vue";

const getDatas = (collection_name, id) => {
  const datas = ref([]);
  const error = ref(null);

  const load = async () => {
    if (id === undefined) {
      console.log("idが見つかりません");
      return;
    }
    try {
      // await new Promise((resolve) => {
      //   setTimeout(resolve, 2000);
      // });
      let res = await projectFirestore
        .collection(collection_name)
        .doc(id)
        .get();

      if (!res.exists) {
        throw Error("データがありません");
      }
      const data = res.data();
      if (data.pay_urls && Array.isArray(data.pay_urls)) {
        datas.value = data.pay_urls;
      } else {
        throw Error("pay_urlsが存在しないか、配列ではありません");
      }
    } catch (err) {
      error.value = err.message;
      console.log(error.value);
    }
  };
  return { datas, error, load };
};

export default getDatas;
