<template>
  <div class="status_box">
    <div class="status_work" v-if="status_number == 1">
      就業中
    </div>
    <div class="status_pending" v-if="status_number == 2">
      会社選定中
    </div>
    <div class="status_selecting" v-if="status_number == 3">
      研修中
    </div>
    <div class="status_rest" v-if="status_number == 4">
      休職中
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultStatus",
  props: ["status_number"],
};
</script>

<style>
.status_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.status_work {
  width: 88px;
  height: 34px;
  border-radius: 30px;
  background: #d9f2dd;
  color: #359742;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status_pending {
  width: 88px;
  height: 34px;
  border-radius: 30px;
  background:  #d0a5836f;
  color: #e97d24;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status_selecting {
  width: 88px;
  height: 34px;
  border-radius: 30px;
  background: #d9e7f2;
  color: #437ac7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status_rest {
  width: 88px;
  height: 34px;
  border-radius: 30px;
  background: #e7eaec;
  color: #36383a;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
