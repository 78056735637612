import { ref } from "vue";
import { projectFirestore } from "../../firebase/config";

const getDefaultDatas = (collection_name) => {
  const defaultdatas = ref([]);
  const datas_error = ref(null);

  const datas_load = async () => {
    try {
      const res = await projectFirestore.collection(collection_name).get();

    

      if(collection_name !== "engineers"){
        //engineersではないとき
        if(collection_name === "holidayData"){
          //holidayDataの時 退職者、入社直後、その他のメンバーを除外

          defaultdatas.value = res.docs.map((doc) => {
            const data = { ...doc.data(), id: doc.id};

            if(data.work_status !== "retire" && data.work_status !== "enter" &&data.work_status !== "other" ){
              return data;
            }
          })
          .filter((item) => item !== undefined);
        }else{
          //engineers,holidayData以外の時　退職者、入社直後、その他のメンバーを除外
          defaultdatas.value = res.docs.map((doc) => {
            const data = { ...doc.data(), id: doc.id};

            if(data.status !== "retire" && data.status !== "enter" &&data.status !== "other" ){
              return data;
            }
          })
          .filter((item) => item !== undefined);
        }
      

      }else{

        //engineersの時 全ての登録エンジニアを表示
        defaultdatas.value = res.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });

      }
    } catch (err) {
      datas_error.value = err.message;
    }
  };
  return { defaultdatas, datas_error, datas_load };
};
export default getDefaultDatas;
