import { ref } from "vue";
import { projectAuth } from "@/firebase/config";
import useLogin from "./useLogin";

const error = ref(null);
const {error: login_error, login} = useLogin()

const changepassword = async (new_password) => {
 
  const  user = projectAuth.currentUser;
  console.log("new_pass:",new_password)
  console.log("user:",user)
  await user.updatePassword( new_password ).then(() =>{console.log("パスワード変更成功")}).catch((err) => {console.log(err.message)})

   try{  
   error.value  = null
   
   if(!pass_res){
    throw new Error('パスワードを変更できませんでした。')
   }
   return pass_res}catch(err){
    
    error.value = err.message
   }
   
 
 
}

const useChangePassword = () => {
 
  return { error, changepassword}
}

export default useChangePassword