import { projectFirestore, projectStorage } from "@/firebase/config";
import { ref } from "vue";

const uploadPDF = (folder_name) => {
  const error = ref(null);
  const url = ref(null);
  const filePath = ref(null);

  const createFolder = async (engineer_id) => {
    const dummyFilePath = `${folder_name}/${engineer_id}/.dummy`;
    const dummyFile = new Blob(["This is a dummy file"], {
      type: "text/plain",
    });

    const storageRef = projectStorage.ref();
    const folderRef = storageRef.child(dummyFilePath);
    try {
      await folderRef.getMetadata();
      console.log(`フォルダ名"${dummyFilePath}"はすでに存在します。`);
      return;
    } catch (error) {
      if (error.code === "storage/object-not-found") {
        try {
          await folderRef.put(dummyFile);
          console.log(`フォルダ名"${folder_name}/${engineer_id}";
      "の作成に成功しました。`);
          await createMonthlyFolder(engineer_id);
        } catch (error) {
          console.error("エラーが起きました", error);
        }
      } else {
        console.error("エラーが起きました", error);
      }
    }
  };

  const createMonthlyFolder = async (engineer_id) => {
    const currentYear = new Date().getFullYear();
    let year = currentYear;
    const dummy = new Blob(["This is a dummy file"], {
      type: "text/plain",
    });

    for (let i = 1; i <= 3; i++) {
      const YearFilePath = `${folder_name}/${engineer_id}/${year}`;
      for (let t = 1; t <= 12; t++) {
        const month = t;
        const monthlyFilePath = `${YearFilePath}/${month}/.dummy`;

        const monthlyStorageRef = projectStorage.ref();
        const monthlyFolderRef = monthlyStorageRef.child(monthlyFilePath);

        try {
          await monthlyFolderRef.getMetadata();
          console.log(`フォルダ名"${monthlyFilePath}"はすでに存在します。`);
          return;
        } catch (error) {
          if (error.code === "storage/object-not-found") {
            try {
              await monthlyFolderRef.put(dummy);
              console.log(`フォルダ名"${monthlyFilePath}"
            "の作成に成功しました。`);
            } catch (error) {
              error.value = error.message;
              console.log("エラー発生", error);
            }
          } else {
            error.value = error.message;
            console.log("エラー発生", error);
          }
        }
      }
      year++;
    }
  };

  const uploadData = async (file_name, file) => {
    const parts = file_name.split("_");
    const year = parts[0];
    const month = parts[1];
    const id = parts[2].split(".")[0];
    console.log(`Year: ${year}, Month: ${month}, ID: ${id}`);

    filePath.value = `pay_data/${id}/${year}/${month}/${file.name}`;
    const storageRef = projectStorage.ref(filePath.value);

    const metadata = {
      contentType: "application/pdf",
    };

    try {
      const res = await storageRef.put(file, metadata);
      url.value = await res.ref.getDownloadURL();

      const post_item = {
        year: year,
        month: month,
        url: url.value,
      };

      const pay_urls = [];
      pay_urls.push(post_item);

      const post = {
        pay_urls: pay_urls,
      };

      try {
        await projectFirestore.collection("pay_data_urls").doc(id).set(post);
        console.log(`${id}のURLデータ登録に成功しました。`);
      } catch (error) {
        error.value = error.message;
        console.log(`${id}のURLデータ登録に失敗しました。`, error);
      }
    } catch (err) {
      console.log(err.message);
      error.value = err.message;
    }
  };

  const deleteData = async (id) => {
    filePath.value = `${folder_name}/${id}`;
    console.log("filepath", filePath.value);
    if (filePath.value) {
      let desertRef = projectStorage.ref(filePath.value);

      try {
        const res = await desertRef.getDownloadURL();
        console.log("Fileあり");
        await desertRef.delete();
        url.value = null;
      } catch (err) {
        console.log("Fileなし");
        console.log(err.message);
      }
    }
  };

  return {
    url,
    filePath,
    error,
    uploadData,
    deleteData,
    createFolder,
    createMonthlyFolder,
  };
};

export default uploadPDF;
