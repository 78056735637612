<template>
  <div class="user_point_table_box">
    <table class="point_data_table">
      <tr>
        <th>付与・使用</th>
        <th>理由</th>
        <th>ポイント</th>
        <th>備考</th>
        <th>取得日</th>
      </tr>
      <tr v-for="user_data in sorteduserDatas" :key="user_data.id">
        <td>
          <div v-if="user_data.point_status === 'add'" class="add_text">
            付与
          </div>
          <div v-if="user_data.point_status === 'use'" class="use_text">
            使用
          </div>
        </td>

        <td><div class="point_content">{{ user_data.point_content }}</div></td>
        <td>{{ user_data.points }}</td>
        <td>{{ user_data.point_other }}</td>

        <td>
          <div v-if="user_data && user_data.point_date" class="point_content">
            <div v-if="typeof user_data.point_date === 'string'">
              {{ user_data.point_date }}
            </div>
            <div v-else>
              {{ user_data.point_date.toDate().toLocaleDateString("sv-SE") }}
            </div>
          </div>
        </td>
      </tr>
    </table>
    <div class="batsu_button" @click="handleDataClose">×</div>
  </div>
</template>

<script>
import { now_timestamp, projectFirestore } from "@/firebase/config";
import { watch, ref, onMounted } from "vue";
import getDefaultDatas from "../composables/getDefaultDatas";
export default {
  props: ["user_id"],
  data() {
    return {
      today: new Date(),
    };
  },
  setup(props, context) {
    const user_datas = ref([]);
    const sorteduserDatas = ref([]);

    const { defaultdatas: datas, datas_load } = getDefaultDatas("points");
    onMounted(async () => {
      await datas_load();

      user_datas.value = datas.value.filter((data) => {
      
        return data.point_user_id === props.user_id;
      });

      sorteduserDatas.value = user_datas.value.slice().sort((a, b) => {
        const getdayA = new Date(a.point_date);
        const getdayB = new Date(b.point_date);
        return getdayB - getdayA;
      });
    });

    return {
      user_datas,
      sorteduserDatas,
    };
  },

  methods: {
    handleDataClose() {
      this.$emit("point_data_close");
    },
  },
};
</script>

<style>
.user_point_table_box {
  
  width: 350px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items:center;
  border-radius: 10px;
  border: 5px solid;
  padding: 30px 5px 20px 5px;
  position: relative;
  flex-direction: column;

  
}
.yukyu_box {
  width: 50px;
}
.user_holiday_table td {
  border-top: 0.5px solid #ddd;
  padding: 10px 10px;
}

.user_holiday_table table {
  border-collapse: collapse;
}

.batsu_button {
  position: absolute;
  right: 10px;
  top: 0px;
  font-size: 30px;
  cursor: pointer;
}

.add_text {
  color: #d9376e;
  font-weight: bold;
  width: 50px;
}
.use_text {
  color: #2cb67d;
  font-weight: bold;
  width: 50px;
}
.get_mark {
  background: #72757e;
  color: white;
  font-weight: bold;
  border-radius: 30px;
  width: 40px;
}

.unget_mark {
  background: #ffd803;
  color: white;
  font-weight: bold;
  border-radius: 30px;
  width: 40px;
}

.point_content{
  width:80px
}

.point_data_table{
  width:300px;

  
}
</style>
