import { ref } from "vue";
import { projectAuth } from "@/firebase/config";

const error = ref(null);

const signup = async (email, displayName, password) => {
  error.value = null
  try {
    const res = await projectAuth.createUserWithEmailAndPassword(email, password)
   if(!res){
    throw new Error('サインアップを完了できませんでした')
   }
   await res.user.updateProfile({ displayName })
   error.value  = null
   console.log(res.user)
   return res
  }catch(err){
    console.log(err.message)
    error.value = err.message
  }
}

const useSignup = () => {
 
  return { error, signup}
}

export default useSignup