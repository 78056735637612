import { ref } from "vue";
import getDefaultDatas from "./getDefaultDatas";
import getPicDatas from "./getPicDatas";

const getSubmittedLastMonthHolidayData = async (datas) => {
  const {
    defaultdatas: submit_datas,
    datas_error,
    datas_load,
  } = getDefaultDatas("holiday_application");

  const { defaultdatas: holidaydatas, datas_load: holidaydatas_load } =
    getDefaultDatas("holidayData");

  await holidaydatas_load();
  await datas_load();

  const now_date = new Date();

  const first_day = new Date(
    now_date.getFullYear(),
    now_date.getMonth() - 1,
    1
  );
  const final_day = new Date(now_date.getFullYear(), now_date.getMonth(), 0);
  final_day.setHours(23, 59, 59, 999);

  //今月のデータのみに限定
  const tmp_monthly_datas = submit_datas.value.filter((data) => {
    return (
      data.holiday_formated &&
      first_day <= data.holiday_formated.toDate() &&
      data.holiday_formated.toDate() <= final_day
    );
  });

  //datasに名前のある人だけ厳選
  const pic_monthly_datas = tmp_monthly_datas.filter((monthly_data) => {
    return datas.some((data) => monthly_data.userid === data.company_id);
  });

  const output_monthdata_list = (tmp_month_data, holidaydatas) => {
    return tmp_month_data.map((tmp_data) => {
      const relatedHolidayData = holidaydatas.find((holidaydata) => {
        return holidaydata.company_id === tmp_data.userid;
      });

      if (relatedHolidayData) {
        return {
          ...tmp_data,
          name: `${relatedHolidayData.last_name} ${relatedHolidayData.first_name}`,
        };
      } else {
        return tmp_data;
      }
    });
  };

  const sorted_monthdata_list = output_monthdata_list(
    pic_monthly_datas,
    holidaydatas.value
  ).sort((a, b) => {
    const HDateA = new Date(a.holiday_date);
    const HDateB = new Date(b.holiday_date);

    return HDateA - HDateB;
  });

  return sorted_monthdata_list;
};

export default getSubmittedLastMonthHolidayData;
