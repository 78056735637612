<template>
  <div class="holiday_form" v-if="form === 'holiday'">
    <h3>有給休暇申請</h3>
    <div class="form_error">{{ error }}</div>

    <form @submit.prevent="" v-if="is_kekkin_confirm">
      <div>
        <p>欠勤申請を行いますか？</p>
        <div class="radio">
          <div>
            <input type="radio" name="kekkin" value="1" v-model="is_application_absent" />行う
          </div>
          <div>
            <input type="radio" name="kekkin" value="2" v-model="is_application_absent" />行わない
          </div>
        </div>
      </div>
      <button @click="handleChangeForm">決定</button>
    </form>
    <!-- "handleHolidaySubmit"  -->
    <form @submit.prevent="handleHolidaySubmit" v-if="!is_kekkin_confirm" class="centering_item">
      <h5>有休取得日</h5>
      <input type="date" v-model="holiday_date" required class="input_holiday_date" />

      <h5>使用方法</h5>
      <div class="radio">
        <input type="radio" name="howtouse" v-model="num_of_holiday" :value="0.5" required />半休取得
      </div>
      <div class="radio">
        <input type="radio" name="howtouse" v-model="num_of_holiday" :value="1.0" />全休取得
      </div>

      <h5>休暇取得理由</h5>
      <textarea class="yukyu_textarea" v-model="holiday_reason" required></textarea>

      <button class="submit_button">申請</button>
    </form>
    <div class="batsu_button" @click="handleFormClose">×</div>
  </div>

  <div class="absent_form" v-if="form === 'absent'">
    <h3>休暇/欠勤申請</h3>
    <div class="form_error">{{ error }}</div>

    <form @submit.prevent="handleAbsentSubmit" class="centering_item">
      <h5>休暇/欠勤予定日</h5>
      <input type="date" v-model="holiday_date" required class="input_holiday_date" />

      <h5>種別</h5>
      <div class="radio">
        <input type="radio" name="absent_type" v-model="type_of_absent" :value="1" required />全日休（休暇含む）
      </div>
      <div class="radio">
        <input type="radio" name="absent_type" v-model="type_of_absent" :value="2" />遅刻
      </div>
      <div class="radio">
        <input type="radio" name="absent_type" v-model="type_of_absent" :value="3" />早退
      </div>
      <div class="radio">
        <input type="radio" name="absent_type" v-model="type_of_absent" :value="4" />中抜け
      </div>

      <h5>欠勤（休暇）理由/詳細</h5>
      <textarea type="textarea" class="yukyu_textarea" v-model="holiday_reason" required></textarea>

      <button class="submit_button">申請</button>
    </form>
    <div class="batsu_button" @click="handleFormClose">×</div>
  </div>
</template>

<script>
import { ref } from "vue";
import { now_timestamp, projectFirestore } from "@/firebase/config";
import { init, send } from "emailjs-com";

export default {
  props: ["user_name", "pic_name", "id", "user_datas", "rest_holiday", "form"],
  data() {
    return {};
  },
  setup(props, context) {
    //const form = ref("holiday")
    const holiday_date = ref("");
    const use_holiday_num = ref("");
    const num_of_weekend = ref("");
    const num_of_holiday = ref(0);
    const holiday_reason = ref("");
    // const start_day = ref("");
    // const end_day = ref("");
    const error = ref("");
    const holiday_datas = ref([]);
    const duplication_check_array = ref([]);
    const rest_day = ref("");
    const is_kekkin_confirm = ref(false);
    const is_application_absent = ref("");
    const type_of_absent = ref("");
    const message = ref("");
    const pic_name = ref("");
    const user_namme = ref("");

    const handleHolidaySubmit = () => {
      if (holiday_date.value) {
        //1日単位の申請
        try {
          //すでに休暇申請されていないか
          if (props.user_datas) {
            holiday_datas.value = props.user_datas;
            duplication_check_array.value = holiday_datas.value.filter(
              (data) => {
                return data.holiday_date === holiday_date.value;
              }
            );
            if (duplication_check_array.value.length > 0) {
              console.log(duplication_check_array.value.length);
              throw Error("この日はすでに申請されています。");
            } else {
              //有休日数が残っているか
              console.log("のこり", props.rest_holiday);
              rest_day.value = props.rest_holiday - num_of_holiday.value;
              console.log("rest", rest_day.value);
              if (rest_day.value < 0) {
                console.log(rest_day.value);
                is_kekkin_confirm.value = true;
                throw Error("有給が残っていません。");
              } else {
                //有休日数範囲内であり、申請が重複していなければsubmit
                submitHolidaydata(
                  holiday_date.value,
                  num_of_holiday.value,
                  "paid"
                );
              }
            }
          }
        } catch (err) {
          error.value = err.message;
          return null;
        }
      }
    };

    const handleAbsentSubmit = () => {
      if (holiday_date.value) {
        //1日単位の申請
        try {
          //すでに休暇申請されていないか
          if (props.user_datas) {
            holiday_datas.value = props.user_datas;
            duplication_check_array.value = holiday_datas.value.filter(
              (data) => {
                return data.holiday_date === holiday_date.value;
              }
            );
            if (duplication_check_array.value.length > 0) {
              console.log(duplication_check_array.value.length);
              throw Error("この日はすでに申請されています。");
            } else {
              submitHolidaydata(
                holiday_date.value,
                num_of_holiday.value,
                "absent"
              );
            }
          }
        } catch (err) {
          error.value = err.message;
          return null;
        }
      }
    };

    const submitHolidaydata = async (date, num, type) => {
      //
      let enter_holiday_num;
      if (type === "absent") {
        enter_holiday_num = 0;
      } else if (type === "paid") {
        enter_holiday_num = num;
      }

      const post = {
        userid: props.id,
        holiday_date: date,
        holiday_formated: new Date(date),
        use_holiday_num: enter_holiday_num,
        holiday_reason: holiday_reason.value,
        // start_day: start_day.value,
        // end_day: end_day.value,
        holiday_type: type,
        type_of_absent: type_of_absent.value,
        created: "本人からの申請",
        createdAt: now_timestamp(),
      };

      try {
        const res = await projectFirestore
          .collection("holiday_application")
          .add(post);
        console.log("有給申請データの書き込みに成功しました。");
        sendEmailjs(props.pic_name, type, num);

        // start_day.value = null;
        // end_day.value = null;
        holiday_date.value = null;
        holiday_reason.value = null;
        num_of_weekend.value = null;
        num_of_holiday.value = null;

        if (type === "paid") {
          message.value = "有休を申請しました。";
        } else if (type === "absent") {
          message.value = "欠勤登録が完了しました。";
        } else {
          message.value = null;
        }

        context.emit("close_holiday_form", message.value);
      } catch (err) {
        console.error("有給申請データ書き込みエラー:", err);
      }
    };

    const handleChangeForm = () => {
      if (is_application_absent.value === "1") {
        form.value = "absent";
        error.value = null;
      } else {
        message.value = null;
        context.emit("close_holiday_form", message.value);
      }
    };

    const handleFormClose = () => {
      message.value = null;
      context.emit("close_holiday_form", message.value);
    };

    const handlecheck = () => {
      console.log("pic_name", process.env.VUE_APP_PIC1_NAME);
      console.log("pic_email", process.env.VUE_APP_PIC1_EMAIL);
    };

    const sendEmailjs = (pic, type, num) => {
      init("38HQ7GOaxaGFelSRC");
      let enter_pic_name;
      let enter_pic_adress;
      switch (pic) {
        case "kiyanagi":
          enter_pic_name = process.env.VUE_APP_PIC1_NAME;
          enter_pic_adress = process.env.VUE_APP_PIC1_EMAIL;
          break;
        case "oda":
          enter_pic_name = process.env.VUE_APP_PIC2_NAME;
          enter_pic_adress = process.env.VUE_APP_PIC2_EMAIL;
          break;
        case "hata":
          enter_pic_name = process.env.VUE_APP_PIC3_NAME;
          enter_pic_adress = process.env.VUE_APP_PIC3_EMAIL;
          break;
        default:
          enter_pic_name = "担当者不明";
          enter_pic_adress = process.env.VUE_APP_DEFAULT_EMAIL;
      }

      let enter_use_payholiday;
      let enter_type_of_holiday;
      switch (type) {
        case "paid":
          enter_use_payholiday = "利用する";
          if (num === 0.5) {
            enter_type_of_holiday = "半休取得";
          } else if (num === 1) {
            enter_type_of_holiday = "全休取得";
          } else {
            enter_type_of_holiday = "データが取得できませんでした。";
          }
          break;
        case "absent":
          enter_use_payholiday = "利用しない";
          if (type_of_absent.value === 1) {
            enter_type_of_holiday = "全日休";
          } else if (type_of_absent.value === 2) {
            enter_type_of_holiday = "遅刻";
          } else if (type_of_absent.value === 3) {
            enter_type_of_holiday = "早退";
          } else if (type_of_absent.value === 4) {
            enter_type_of_holiday = "中抜け";
          } else {
            enter_type_of_holiday = "データが取得できませんでした。";
          }
          break;
      }

      const param = {
        to_name: enter_pic_name,
        to_adress: enter_pic_adress,
        from_name: props.user_name,
        holiday_date: holiday_date.value,
        use_payholiday: enter_use_payholiday,
        type_of_holiday: enter_type_of_holiday,
        reason_of_holiday: holiday_reason.value,
      };

      send("service_d6nscz8", "template_a45v3lm", param, "38HQ7GOaxaGFelSRC");
    };

    return {
      use_holiday_num,
      num_of_weekend,

      num_of_holiday,
      holiday_date,
      holiday_reason,
      // start_day,
      // end_day,
      error,
      is_kekkin_confirm,
      is_application_absent,
      type_of_absent,

      handleChangeForm,
      handleHolidaySubmit,
      handleAbsentSubmit,
      handleFormClose,

      handlecheck,
    };
  },
  emits: ["close_holiday_form"],
};
</script>

<style>
.holiday_form {
  background: #f8ffe4;
  border: 3px solid #ddd;
  border-radius: 20px;
  min-width: 300px;
  padding-bottom: 20px;
  margin-top: 20px;
  position: relative;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
}

.absent_form {
  background: white;
  border: 3px solid;
  border-radius: 20px;
  min-width: 300px;
  padding-bottom: 20px;
  margin-top: 20px;
  position: relative;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.form_error {
  font-size: 13px;
  color: red;
}

.input_holiday_date {
  width: 220px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding-left: 10px;
}

.yukyu_textarea {
  width: 100%;
  height: 200px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid #d9d9d9;
}

.holiday_form .radio {
  display: flex;
  justify-content: center;
}

.submit_button {
  width: 100px;
  height: 30px;
  background: gray;
  color: white;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  align-self: center;
}

.centering_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
