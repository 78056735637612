<template>
  <div class="user_screen">
    <div class="user_data">
      <img src="@/assets/MIK.png" class="welcome_logo" />
      <UserHome />
      <div class="user_footer">
        <div @click="handleLogout" class="logout_wrapper">LogOut<span class="material-symbols-outlined"> logout </span>
        </div>
      </div>

      <!-- <div class="humberger_menu">
        <HumbergerMenu />
      </div> -->
    </div>
  </div>
</template>

<script>
import HumbergerMenu from "@/components/HumbergerMenu.vue";
import UserHome from "@/components/user_comp/UserHome.vue";
import Holiday from "@/components/user_comp/Holiday.vue";
import UsePoint from "@/components/user_comp/UsePoint.vue";
import useLogout from "@/components/composables/useLogout";
import getUser from "@/components/composables/getUser";
import { useRouter } from "vue-router";
import { watch } from "vue";

export default {
  components: {
    HumbergerMenu,
    UserHome,
    Holiday,
    UsePoint,
  },
  setup() {
    const { logout, error } = useLogout();
    const handleLogout = async () => {
      await logout();
      if (!error.value) {
        console.log("user logged out");
        router.push({ name: 'User' });
      }
    };
    const router = useRouter();


    return {
      handleLogout,
    };
  },
};
</script>

<style>
.user_screen {
  background: #c2ff727e;

  padding-top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0px;
}

.user_screen_hr {
  width: 380px;
}

.user_data {
  position: relative;
  width: 600px;
  height: auto;

  background: white;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
}

.face_frame {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user_photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: darkgray;
}

.button_frame {
  margin-top: 20px;
  width: 380px;
  display: flex;
  flex-direction: column;
}

.button_frame_row {
  display: flex;
  justify-content: space-between;
  height: 70px;
  align-items: center;
}

.button {
  width: 180px;
  height: 53px;
  align-self: center;
  background: #f5c310;
  border: none;
  color: white;
  border-radius: 10px;
}

.data_button {
  width: 100px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.humberger_menu {
  position: absolute;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  right: 25px;
}

.humberger_menu .material-symbols-outlined {
  color: #33404e;
  font-size: 50px;
}

.user_footer {
  margin-top: 100px;
}

.user_footer .material-symbols-outlined {
  color: #2c3e50;
  font-size: 20px;
  margin-left: 5px;
}

.logout_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
