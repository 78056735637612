<template>
  <div class="status_box">

    <select
      v-model="user_job"
      class="job_select"
      :style="{ background: activeColor,color: activeFontColor}"
      :disabled=true
  
    
    >
      <option value="development">開発</option>
      <option value="infra">インフラ</option>
      <option value="mobile">モバイル</option>
      <option value="helpdesk">ヘルプ</option>
      <option value="kitting">キッティング</option>
      <option value="other">その他</option>
      <option value="none">未設定</option>
    </select>
  </div>

</template>

<script>
import { onMounted, ref, watch } from "vue";
import { projectFirestore } from '@/firebase/config';
export default {
  name: "JobStatus",
  props: ["job"],
  setup(props,context){
    const user_job = ref("")
    const activeColor = ref("");
    const activeFontColor = ref("");

    onMounted(
      () =>{
        if(user_job.value !== undefined){
      user_job.value = props.job
    }else{
      user_job.value = "none"
    };

      }
    )
 

   
    if (user_job.value === "development") {
      activeColor.value = "#ff6e6c";
      activeFontColor.value = "white";
    } else if (user_job.value === "infra") {
      activeColor.value = "#6246ea";
      activeFontColor.value = "white";
    } else if (user_job.value === "mobile") {
      activeColor.value = "#e53170";
      activeFontColor.value = "white";
    }else if (user_job.value === "helpdesk") {
      activeColor.value = "#fec7d7";
      activeFontColor.value = "white";
    }
    else if (user_job.value === "kitting") {
      activeColor.value = "#ffd803";
      activeFontColor.value = "white";
    }else if (user_job.value === "other") {
      activeColor.value = "#d9d4e7";
      activeFontColor.value = "white";
    }
    else if (user_job.value === "none") {
      activeColor.value = "white";
      activeFontColor.value = "black";
    }

    watch(( )=> user_job.value,(newJob) =>{

      if (newJob === "development") {
      activeColor.value = "#ff6e6c";
      activeFontColor.value = "white";
    } else if (newJob === "infra") {
      activeColor.value = "#6246ea";
      activeFontColor.value = "white";
    } else if (newJob === "mobile") {
      activeColor.value = "#e53170";
      activeFontColor.value = "white";
    }else if (newJob === "helpdesk") {
      activeColor.value = "#fec7d7";
      activeFontColor.value = "white";
    }
    else if (newJob === "kitting") {
      activeColor.value = "#ffd803";
      activeFontColor.value = "white";
    }else if (newJob === "other") {
      activeColor.value = "#d9d4e7";
      activeFontColor.value = "white";
    }else if (user_job.value === "none") {
      activeColor.value = "white";
      activeFontColor.value = "black";
    }
    })

    const handleJobChange = async(job) =>{

      console.log("job",props.id,job)
      const post = {
        engineer_type:job,
      }
      try{
        await projectFirestore
        .collection("skillData")
        .doc(props.id)
        .update(post)
        console.log("JOBを変更しました")
        context.emit("change_job")
      }
        catch(err){
          console.log("JOBを変更できませんでした",err)
        }

       

    }

    return{
          user_job,
          activeColor,
          activeFontColor,
          handleJobChange,

        }




  }
  
};
</script>

<style>

.job_select {
  appearance: none;
  border: none;
  width: 85px;
  height: 34px;
  border-radius: 30px;
  text-align: center;
  font-weight: bold;
  outline: none;
}
.status_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.status_develop {
  width: 120px;
  height: 34px;
  border-radius: 60px;
 
  background: #ff6e6c;
  color: white;
  font-weight:bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status_infra {
  width: 120px;
  height: 34px;
  border-radius: 60px;
 
  background: #6246ea;
  color: white;
  font-weight:bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status_mobile {
  width: 120px;
  height: 34px;
  border-radius: 60px;
 
  background: #e53170;
  color: white;
  font-weight:bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status_kitting {
  width: 120px;
  height: 34px;
  border-radius: 60px;
 
  background: #ffd803;
  color: white;
  font-weight:bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status_helpdesk {
  width: 120px;
  height: 34px;
  border-radius: 60px;
 
  background: #fec7d7;
  color: white;
  font-weight:bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status_other {
  width: 120px;
  height: 34px;
  border-radius: 60px;
 
  background: #d9d4e7;
  color: white;
  font-weight:bold;
  display: flex;
  justify-content: center;
  align-items: center;
}





.status_pending {
  width: 88px;
  height: 34px;
  border-radius: 30px;
  background:  #d0a5836f;
  color: #e97d24;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status_selecting {
  width: 88px;
  height: 34px;
  border-radius: 30px;
  background: #d9e7f2;
  color: #437ac7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status_rest {
  width: 88px;
  height: 34px;
  border-radius: 30px;
  background: #e7eaec;
  color: #36383a;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
