<template>
  <div>
    <div class="absent_app_form">
      <h4>CSV一括登録</h4>
      <div>
        <p>CSVを選択してください。</p>
        <input id="up-csv" type="file" ref="csvInput" />
        <button @click="handleUpload">アップロード</button>
      </div>
    </div>
  </div>
</template>

<script>
import { projectFirestore } from "@/firebase/config";
import useSignup from "../composables/useSignup";
export default {
  data() {
    return {};
  },
  setup() {},
  methods: {
    handleUpload() {
      const input = this.$refs.csvInput;

      if (input.files.length > 0) {
        const file = input.files[0];
        const reader = new FileReader();

        reader.onload = () => {
          const csvData = reader.result;
          const rows = csvData.split("\n").map((row) => row.split(","));
          console.log("データ数", rows.length);

          const headers = rows[0];

          const idIndex = headers.indexOf("id");
      
          const firstNameIndex = headers.indexOf("first_name");
          const firstNameKanaIndex = headers.indexOf("first_name_kana");
          const lastNameIndex = headers.indexOf("last_name");
          const lastNameKanaIndex = headers.indexOf("last_name_kana");
          const areaIndex = headers.indexOf("area");
          const enterDateIndex = headers.indexOf("enter_date");
          const companyIdIndex = headers.indexOf("company_id");
          const companyMailIndex = headers.indexOf("company_mail");
          const statusIndex = headers.indexOf("status");

          for (let i = 1; i < rows.length; i++) {
            const currentRow = rows[i];
            const id = currentRow[idIndex];
      
            const firstName = currentRow[firstNameIndex];
            const lastName = currentRow[lastNameIndex];
            const firstNameKana = currentRow[firstNameKanaIndex];
            const lastNameKana = currentRow[lastNameKanaIndex];
            const area = currentRow[areaIndex];
            const enter_date_string = currentRow[enterDateIndex];
            const company_id = currentRow[companyIdIndex];
            const company_mail = currentRow[companyMailIndex];
            const status = currentRow[statusIndex];
   
            const enter_date_normarize = this.normarizeDate(enter_date_string);
        
            const enter_date = new Date(enter_date_normarize)
         

            this.insertData(
              id,
              firstName,
              lastName,
              firstNameKana,
              lastNameKana,
              area,
              enter_date,
              company_id,
              company_mail,
              status
            );
          }
        };

        reader.readAsText(file);
      } else {
        console.error("ファイルが選択されていません");
      }
    },
    insertData(
      id,
      firstName,
      lastName,
      firstNameKana,
      lastNameKana,
      area,
      enter_date,
      company_id,
      company_mail,
      status
    ) {
      if (!id) {
        console.log("IDが取得できませんでした。スキップします。");
        console.log("お名前", lastName, firstName);
        return;
      }
      let engineer_id = "";
      console.log("company_id",company_id)
      console.log("yes or no ",company_id.includes("@mik-s.work"))
      if(!company_id.includes("@mik-s.work")){
       engineer_id = `${company_id}@mik-s.work`
       console.log("engineer_id",engineer_id)

      }else{
        engineer_id = company_id;

      }
      const post = {
        last_name: lastName,
        first_name: firstName,
        last_name_kana: lastNameKana,
        first_name_kana: firstNameKana,
        area: area,
        enter_date: enter_date,
        company_id: engineer_id,
        company_mail: company_mail,
        status: status,
      };

      projectFirestore
        .collection("engineers")
        .doc(id)
        .set(post)
        .then(() => {
          console.log(`ID ${id}のデータが挿入されました。}`);
        })
        .catch((error) => {
          console.log(`ID ${id}のデータ挿入に失敗しました。}`);
        });

      projectFirestore
        .collection("holidayData")
        .doc(id)
        .set(post)
        .then(() => {
          console.log(`ID ${id}のデータが挿入されました。}`);
        })
        .catch((error) => {
          console.log(`ID ${id}のデータ挿入に失敗しました。}`);
        });

      projectFirestore
        .collection("skillData")
        .doc(id)
        .set(post)
        .then(() => {
          console.log(`ID ${id}のデータが挿入されました。}`);
        })
        .catch((error) => {
          console.log(`ID ${id}のデータ挿入に失敗しました。}`);
        });

        const point_post = {
        last_name: lastName,
        first_name: firstName,
        last_name_kana: lastNameKana,
        first_name_kana: firstNameKana,
        area: area,
        enter_date: enter_date,
        company_id: company_id,
        company_mail: company_mail,
        status: status,
        hold_point:0,
        can_change_money:0,
      };

      projectFirestore
        .collection("pointData")
        .doc(id)
        .set(point_post)
        .then(() => {
          console.log(`ID ${id}のデータが挿入されました。}`);
        })
        .catch((error) => {
          console.log(`ID ${id}のデータ挿入に失敗しました。}`);
        });

      projectFirestore
        .collection("payData")
        .doc(id)
        .set(post)
        .then(() => {
          console.log(`ID ${id}のデータが挿入されました。}`);
        })
        .catch((error) => {
          console.log(`ID ${id}のデータ挿入に失敗しました。}`);
        });
    },
    getDefaultDate() {
      const today = new Date();
      return today;
    },
    normarizeDate(dateString) {

      if(!dateString){
        return "2000-01-01"
      }
      const [year, month, day] = dateString.split("/");
      const normarizedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
        2,
        "0"
      )}`;
      return normarizedDate;
    },
  },
};
</script>

<style></style>
