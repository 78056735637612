import { ref } from "vue";
import getChangeMoney from "./getChangeMoney";
import getDefaultDatas from "./getDefaultDatas";
import { projectFirestore } from "@/firebase/config";

const updatePoint = async (total_point, changemoney, user) => {

  if (total_point) {
    const post = {
      hold_point: total_point,
      can_change_money: changemoney,
    };

    try {
      await projectFirestore.collection("pointData").doc(user).update(post);

      console.log(`${user}のポイントデータを${total_point}に更新しました。`);
    } catch (err) {
      console.log("ポイントデータ更新に失敗しました", err);
    }
  }
};

const getUsersPoint = async () => {

  const users = ref([])
  const filtered_add_point = ref([])
  const filtered_use_point = ref([])
  const total_user_point= ref(0);

  console.log("getUserPoint start");

  const { defaultdatas, datas_error, datas_load } =
      getDefaultDatas("pointData");

  await datas_load();
  const {
    defaultdatas: pointdatas,
    datas_error: point_error,
    datas_load: point_datas_load,
  } = getDefaultDatas("points");
 
  await point_datas_load();

  if (defaultdatas.value) {
    users.value = defaultdatas.value.map((data) => data.id);

    users.value.map((user) => {
      console.log("usr", user);
      filtered_add_point.value = pointdatas.value.filter((point_data) => {
        return (
          point_data.point_user_id === user &&
          point_data.point_status === "add"
        );
      });

      filtered_use_point.value = pointdatas.value.filter((point_data) => {
        return (
          point_data.point_user_id === user &&
          point_data.point_status === "use"
        );
      });

      const add_point = filtered_add_point.value.reduce((total, data) => {
        console.log(data.points);
        return total + data.points;
      }, 0);

      const use_point = filtered_use_point.value.reduce((total, data) => {
        console.log(data.points);
        return total + data.points;
      }, 0);

      total_user_point.value = add_point - use_point;
      const { changemoney } = getChangeMoney(total_user_point.value);
      console.log("changemoney", changemoney.value);
      updatePoint(total_user_point.value, changemoney.value, user);
    });
  }

};



export default getUsersPoint;