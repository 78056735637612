import { projectFirestore } from "@/firebase/config"
import { ref } from "vue"


const error = ref(null);

const updateData = (collection_array,post, id) => {
  error.value =null

  console.log("useUpdatedata area")
  collection_array.forEach((data) => {
    try{
       projectFirestore
      .collection(data)
      .doc(id)
      .update(post)
      console.log("useUpdatedata",data,id)
    }catch(err){
      console.log("変更できませんでした",err)
      error.value = err.message
    }
  

  })

}

const useUpdateData = () => {
  return {error, updateData}
}

export default useUpdateData
