<template>
  <div class="absent_app_form">
    <div>
      <h3>初回設定</h3>
    </div>

    <div>
      <div>
        <h5>入社日</h5>
        <div v-if="typeof enter_date === 'string'"> {{ enter_date }}</div>
        <div v-else>{{ enter_date.toDate().toLocaleDateString("sv-SE") }}</div>
      </div>

      <div>
        <h5>初回有休付与基準日</h5>
        <p>{{ base_date }}</p>
      </div>


      <div>
        <h5>次回有休付与日</h5>
        <p>{{ next_add_date }}</p>
      </div>

      <div>
        <h5>次回付与日数</h5>
        <p>{{ num_of_add }}</p>
      </div>
    </div>
    <p>この内容で設定してもよろしいですか。</p>
    <button @click="handleSetting">設定</button>
  </div>
</template>

<script>
import { ref } from 'vue';
import getCountHoliday from '../composables/getCountHoliday';

export default {
  props: ["enter_date"],
  setup(props, context) {

    //const fixed_holiday = ref(0)
    const today = ref(new Date())


    const { base_date, next_add_date, num_of_add } = getCountHoliday(props.enter_date, today.value.getFullYear())

    const handleSetting = () => {
      context.emit("first_set", base_date, next_add_date, num_of_add)
    }

    return {
      base_date,
      next_add_date,
      num_of_add,

      handleSetting
    }

  }
};
</script>

<style></style>
