<template>
  <div class="welcome_page">
    <h1>Welcome！</h1>
    <div v-if="showLogin">
      <h2>管理者ログイン</h2>
      <AdminLoginForm @login="enterTop" />
      <!-- <p>
        No account yet? <span @click="showLogin = false">Sign up</span>instead
      </p> -->
    </div>
    <div v-else>
      <h2>Sign up</h2>
      <AdminSignupForm @signup="enterTop" />
      <p>
        Already account yet?
        <span @click="showLogin = true">Log in</span>instead
      </p>
    </div>
  </div>
</template>

<script>
import AdminSignupForm from "@/components/admin_comp/AdminSignupForm.vue";
import AdminLoginForm from "@/components/admin_comp/AdminLoginForm.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  components: { AdminLoginForm, AdminSignupForm },
  setup() {
    const showLogin = ref(true);
    const router = useRouter();
    const enterTop = () => {
      router.push({ name: "Home" });
    };
    return { showLogin, enterTop };
  },
};
</script>

<style>
.welcome_page {
  background: #f8ffe4;
  height: 100vh;
  padding-top: 120px;
}
</style>
