<template>
  <div class="notice_over" v-if="limit">
  <div class="absent_app_form">
    <p>あと{{diff}}日で以下の有給休暇の期限が切れます。</p>
    <h5>期限切れ直近の有給休暇日数</h5>
    <div>{{ limit }}日</div>
    <div class="limit_close" @click="handleMessageClose"><span class="material-symbols-outlined">
close
</span></div>
   </div>
  </div>
</template>

<script>
import { projectFirestore } from "@/firebase/config";
import getCountHoliday from "../composables/getCountHoliday";
import { ref } from "vue";
import getUser from "../composables/getUser";

export default {
  props: ["limit","diff"],
  setup(props, context) {
    const handleMessageClose = ()=>{
      context.emit("timeover_message_close")
    }

    return{
      handleMessageClose,
    }

  },
};
</script>

<style>
.notice_over .absent_app_form{
  position:relative
}
.limit_close {
  position:absolute;
  right:10px;
  top:10px;
  

}
.limit_close .material-symbols-outlined {
  color:#2c3e50;;
  font-size:20px;
  font-weight: bold;
  cursor: pointer;
}
</style>
