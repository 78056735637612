<template>
  <div class="add_engineer_box">
    <h3>追加するエンジニアの確認</h3>
    <div class="full_part">
      <div class="entry_part">
        <div class="entry_item_name">
          <p>お名前</p>
          <div></div>
        </div>
        <div class="entry_item_name">
          <p>ふりがな</p>
          <div></div>
        </div>
        <div class="entry_item">
          <p>会社メールアドレス</p>
        </div>

        <div class="entry_item">
          <p>社員ID</p>
        </div>

        <div class="entry_item">
          <p>所属地域</p>
        </div>

        <div class="entry_item">
          <p>担当営業者</p>
        </div>

        <div class="entry_item">
          <p>入社日</p>
        </div>

        <div class="entry_item">
          <p>主要連絡メールアドレス</p>
        </div>

        <div class="entry_item">
          <p>連絡先電話番号</p>
        </div>
      </div>
      <div class="face_part">
        <div class="entry_item">
          <p>顔写真</p>
        </div>
      </div>
    </div>
    <div class="entry_part2">
      <div class="entry_item_address">
        <p>住所</p>
      </div>

      <div class="entry_item">
        <p>生年月日</p>
      </div>

      <div class="entry_item">
        <p>緊急連絡先</p>
      </div>

      <div class="emergency">
        <div class="entry_item">
          <p>緊急連絡先の方のお名前</p>
        </div>

        <div class="entry_item">
          <p>族柄</p>
        </div>
      </div>
    </div>

    <button>登録</button>
  </div>
</template>

<script>
export default {
  name: "ConfirmEngineer",
};
</script>

<style>
.add_engineer_box {
  width: 900px;
  background: white;
  border-radius: 10px;
  margin-top: 25px;
  margin-left: 20px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
}

.add_engineer_box h3 {
  align-self: flex-start;
  margin-left: 62px;
}

.entry_part {
  margin-left: 62px;
  width: 500px;
}
.entry_part2 {
  margin-left: 62px;
}
.entry_item {
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}
.entry_item p {
  font-size: 14px;
  margin: 0;
}

.entry_item input {
  width: 259px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}
.entry_item_name p {
  font-size: 14px;
  margin: 0;
}
.entry_item_name {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}

.entry_item_name input {
  width: 159px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
}

.entry_item_address {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}
.entry_item_address p {
  font-size: 14px;
  margin: 0;
}

.entry_item_address input {
  width: 550px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.emergency {
  display: flex;
}
.emergency .entry_item {
  width: 270px;
}

.face_up_box {
  width: 260px;
  height: 200px;
  background: #e3f5ff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #105ef5;
  font-size: 14px;
}

.add_engineer_box button {
  width: 241px;
  height: 33px;
  align-self: center;
  background: #f5c310;
  border: none;
  color: white;
  border-radius: 10px;
  margin-top: 62px;
  margin-bottom: 41px;
}

.full_part {
  display: flex;
  margin-top: 30px;
}
</style>
