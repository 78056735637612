import { ref } from "vue";


const getCalcTotalUse = async (next_add,datas_array) => {
  //有効期間内の使用分を計算
  const currentYearStart = ref("");
  const currentYearEnd = ref("");
  const nextAdd = new Date(next_add);

 
  currentYearStart.value = new Date(nextAdd);
  currentYearStart.value.setFullYear(nextAdd.getFullYear() - 1);

  currentYearEnd.value = new Date(nextAdd);
  currentYearEnd.value.setDate(currentYearEnd.value.getDate() - 1);
  const passed_holiday_array = datas_array;

  const currentTwoYearStart = ref(new Date(currentYearStart.value));
  currentTwoYearStart.value.setFullYear(
    currentYearStart.value.getFullYear() - 1
  );

  console.log("currentTwoYearStart", currentTwoYearStart.value);

  const filtered_holiday_array = passed_holiday_array.filter((data) =>{
    const holidayDate = new Date(data.holiday_date);
    return  currentTwoYearStart.value <= holidayDate &&
      holidayDate <= currentYearEnd.value
  })
  const totalUseHoliday =filtered_holiday_array.reduce((total, data) => {
      return total + parseFloat(data.use_holiday_num, 10);
   
  }, 0);

  console.log("totalUseholiday",totalUseHoliday)
  console.log("filtered_holiday_array", filtered_holiday_array);
  return {filtered_holiday_array,totalUseHoliday};
};

export default getCalcTotalUse;