<template>
  <div v-if="isReturnButton" class="detail_button_container">
    <button class="top_return_button" @click="handleReturn">戻る</button>
  </div>
  <div class="add_engineer_box">
    <h3>エンジニアの追加</h3>
    <form @submit.prevent="handleSubmit">
      <div class="full_part">
        <div class="entry_part">
          <div class="entry_item_name">
            <label>お名前</label>
            <div>
              <input type="text" v-model="last_name" required />
              <input type="text" v-model="first_name" required />
            </div>
          </div>
          <div class="entry_item_name">
            <label>ふりがな</label>
            <div>
              <input type="text" v-model="last_name_kana" />
              <input type="text" v-model="first_name_kana" />
            </div>
          </div>
          <div class="entry_item_id">
            <label>会社メールアドレス</label>
            <div><input type="text" v-model="company_mail" />@mik-s.jp</div>
          </div>

          <div class="entry_item_id">
            <label>社員ID</label>
            <div>
              <input type="text" v-model="company_id" required />@mik-s.work
            </div>
          </div>

          <div class="radio_item">
            <label>所属地域</label>
            <div class="radio">
              <input type="radio" name="area" value="sapporo" v-model="area" />札幌
            </div>
            <div class="radio">
              <input type="radio" name="area" value="morioka" v-model="area" />盛岡
            </div>
            <div class="radio">
              <input type="radio" name="area" value="tokyo" v-model="area" />東京
            </div>
            <div class="radio">
              <input type="radio" name="area" value="oosaka" v-model="area" />大阪
            </div>
            <div class="radio">
              <input type="radio" name="area" value="fukuoka" v-model="area" />福岡
            </div>
          </div>

          <div class="radio_item">
            <label>担当営業者</label>
            <div class="radio">
              <input type="radio" name="pic" value="kiyanagi" v-model="pic" />鬼柳
            </div>
            <div class="radio">
              <input type="radio" name="pic" value="oda" v-model="pic" />小田
            </div>
            <div class="radio">
              <input type="radio" name="pic" value="hata" v-model="pic" />畑
            </div>
          </div>

          <div class="entry_item">
            <label>入社日</label>
            <input type="date" v-model="enter_date" required />
          </div>

          <div class="radio_item">
            <label>現在のステータス</label>
            <div class="radio">
              <input type="radio" name="status" value="enter" v-model="status" />今月入社
            </div>
            <div class="radio">
              <input type="radio" name="status" value="work" v-model="status" />就業中
            </div>
            <div class="radio">
              <input type="radio" name="status" value="stya1" v-model="status" />待機中
            </div>
            <div class="radio">
              <input type="radio" name="status" value="stay2" v-model="status" />研修中
            </div>
            <div class="radio">
              <input type="radio" name="status" value="stay3" v-model="status" />休職中
            </div>
            <div class="radio">
              <input type="radio" name="status" value="other" v-model="status" />その他
            </div>
          </div>

          <div class="entry_item">
            <label>主要連絡メールアドレス</label>
            <input type="text" v-model="major_mail" />
          </div>

          <div class="entry_item">
            <label>連絡先電話番号</label>
            <input type="text" v-model="phone" />
          </div>
        </div>
        <div class="face_part">
          <div class="entry_item">
            <label>顔写真</label>
            <div class="face_up_box">
              <p>顔写真を選択してください png/jpeg</p>
              <input type="file" id="avater" name="avater" accept="image/png, image/jpeg" />
            </div>
          </div>
        </div>
      </div>
      <div class="entry_part2">
        <div class="entry_item_address">
          <label>住所</label>
          <input type="text" v-model="address" />
        </div>

        <div class="entry_item">
          <label>生年月日</label>
          <div class="enter_date">
            <input type="date" v-model="birth_date" />
          </div>
        </div>

        <div class="entry_item">
          <label>緊急連絡先</label>
          <input type="text" v-model="emergency_phone" />
        </div>

        <div class="emergency">
          <div class="entry_item">
            <label>緊急連絡先の方のお名前</label>
            <input type="text" v-model="emergency_name" />
          </div>

          <div class="entry_item">
            <label>族柄</label>
            <input type="text" v-model="emergency_relation" />
          </div>
        </div>
      </div>

      <button type="submit">登録</button>
    </form>
  </div>
</template>

<script>
import { ref } from "vue";
import useStorage from "@/components/composables/useStorage";
import { now_timestamp, projectFirestore } from "../../firebase/config";
import useSignup from "@/components/composables/useSignup";

export default {
  name: "AddEngineer",
  data() {
    return {
      isReturnButton: true,
    };
  },
  setup(props, context) {
    const last_name = ref("");
    const first_name = ref("");
    const last_name_kana = ref("");
    const first_name_kana = ref("");
    const address = ref("");
    const area = ref("");
    const face_url = ref(null);
    const phone = ref("");
    const pic = ref("");
    const status = ref("");
    const enter_date = ref("");
    const company_id = ref("");
    const company_mail = ref("");
    const major_mail = ref("");
    const birth_date = ref("");
    const emergency_name = ref("");
    const emergency_phone = ref("");
    const emergency_relation = ref("");
    const engineer_id = ref("");

    //スキル登録
    const engineer_type = ref("未設定");
    const work_experience_year = ref("");
    const work_experience_month = ref("");
    const specialty = ref([]);
    const quolification = ref([]);
    const is_data = ref(false);
    const data_url = ref("");

    const emit = context.emit;
    const addClose = (emit) => {
      const is_close = true;
      emit("add_view_close", is_close);
    };

    const { error, signup } = useSignup();

    const handleSubmit = async () => {
      console.log("push");


      //顔写真データの設定
      const file = ref(null);
      const fileError = ref(null);

      const picture = document.getElementById("avater");
      const selected = picture.files[0];
      console.log(selected);

      if (selected && company_id.value) {
        const { url, uploadImage } = useStorage("faces", company_id.value);

        file.value = selected;
        fileError.value = null;

        if (file.value) {
          await uploadImage(file.value);
          face_url.value = url.value;
          console.log("image uploaded, url:", url.value);
        }
      } else {
        file.value = null;
        face_url.value = null;
      }

      engineer_id.value = `${company_id.value}@mik-s.work`;
      console.log("engineer_id", engineer_id.value)

      const engineer_mail = `${company_mail.value}@mik-s.jp`;

      const post = {
        last_name: last_name.value,
        first_name: first_name.value,
        last_name_kana: last_name_kana.value,
        first_name_kana: first_name_kana.value,
        address: address.value,
        area: area.value,
        face_url: face_url.value,
        phone: phone.value,
        pic: pic.value,
        status: status.value,

        enter_date: new Date(enter_date.value),
        birth_date: birth_date.value,
        company_id: engineer_id.value,
        company_mail: engineer_mail,
        major_mail: major_mail.value,
        emergency_name: emergency_name.value,
        emergency_phone: emergency_phone.value,
        emergency_relation: emergency_relation.value,
        createdAt: now_timestamp(),
      };
      try {
        const res = await projectFirestore
          .collection("engineers")
          .doc(company_id.value)
          .set(post);
        console.log("デフォルトデータの書き込みに成功しました。");

        const first_password = "mik-s.jp";
        //signup(engineer_id, displayName, first_password);

        handleSkillSubmit();
        handleRestSubmit();
        handlePointSubmit();
        handlePaySubmit();

        addClose(emit);
      } catch (err) {
        console.error("データ書き込みエラー：", err);
      }
    };

    const handleRestSubmit = async () => {
      const holiday_post = {
        last_name: last_name.value,
        first_name: first_name.value,
        last_name_kana: last_name_kana.value,
        first_name_kana: first_name_kana.value,
        area: area.value,
        pic: pic.value,
        face_url: face_url.value,
        company_id: engineer_id.value,
        enter_date: new Date(enter_date.value),

        createdAt: now_timestamp(),
      };

      try {
        const res = await projectFirestore
          .collection("holidayData")
          .doc(company_id.value)
          .set(holiday_post);
        console.log("有休データの書き込みに成功しました。");
      } catch (err) {
        console.error("有休データ書き込みエラー：", err);
      }
    };

    const handleSkillSubmit = async () => {
      const skill_post = {
        last_name: last_name.value,
        first_name: first_name.value,
        last_name_kana: last_name_kana.value,
        first_name_kana: first_name_kana.value,
        area: area.value,
        pic: pic.value,
        face_url: face_url.value,
        enter_date: new Date(enter_date.value),
        birth_date: birth_date.value,
        company_id: engineer_id.value,
        engineer_type: engineer_type.value,
        work_experience_year: work_experience_year.value,
        work_experience_month: work_experience_month.value,
        specialty: specialty.value,
        quolification: quolification.value,
        is_data: is_data.value,
        data_url: data_url.value,
        createdAt: now_timestamp(),
      };

      try {
        const res = await projectFirestore
          .collection("skillData")
          .doc(company_id.value)
          .set(skill_post);
        console.log("スキルデータの書き込みに成功しました。");
      } catch (err) {
        console.error("スキルデータ書き込みエラー：", err);
      }
    };

    const handlePointSubmit = async () => {
      const point_post = {
        last_name: last_name.value,
        first_name: first_name.value,
        last_name_kana: last_name_kana.value,
        first_name_kana: first_name_kana.value,
        enter_date: new Date(enter_date.value),
        company_id: engineer_id.value,
        birth_date: birth_date.value,
        area: area.value,
        pic: pic.value,
        face_url: face_url.value,
        hold_point: 0,
        can_change_money: 0,
        createdAt: now_timestamp(),
      };

      try {
        const res = await projectFirestore
          .collection("pointData")
          .doc(company_id.value)
          .set(point_post);
        console.log("ポイントデータの書き込みに成功しました。");
      } catch (err) {
        console.error("ポイントデータ書き込みエラー：", err);
      }
    };

    const handlePaySubmit = async () => {
      const point_post = {
        last_name: last_name.value,
        first_name: first_name.value,
        last_name_kana: last_name_kana.value,
        first_name_kana: first_name_kana.value,
        enter_date: new Date(enter_date.value),
        company_id: engineer_id.value,
        area: area.value,
        pic: pic.value,
        face_url: face_url.value,
        createdAt: now_timestamp(),
      };

      try {
        const res = await projectFirestore
          .collection("payData")
          .doc(company_id.value)
          .set(point_post);
        console.log("ペイデータの書き込みに成功しました。");
      } catch (err) {
        console.error("ペイデータ書き込みエラー：", err);
      }
    };

    return {
      last_name,
      first_name,
      last_name_kana,
      first_name_kana,
      address,
      area,
      phone,
      pic,
      status,
      company_id,
      company_mail,
      emergency_name,
      emergency_phone,
      emergency_relation,
      enter_date,
      birth_date,

      handleSubmit,
    };
  },
  methods: {
    handleReturn() {
      this.isReturnButton = false;
      this.$emit("add_user_return");
    },
  },
};
</script>

<style>
.add_engineer_box {
  width: 100%;
  background: white;
  border-radius: 10px;
  margin: 20px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.add_engineer_box h3 {
  align-self: flex-start;
  margin-top: 50px;
  margin-left: 62px;
}

.entry_part {
  margin-left: 62px;
  width: 500px;
}

.entry_part2 {
  margin-left: 62px;
}

.entry_item {
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}

.entry_item p {
  font-size: 14px;
  margin: 0;
}

.entry_item input {
  width: 259px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.entry_item_id {
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}

.entry_item_id input {
  width: 130px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.entry_item_name p {
  font-size: 14px;
  margin: 0;
}

.entry_item_name {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}

.entry_item_name input {
  width: 159px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
}

.entry_item_address {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}

.entry_item_address p {
  font-size: 14px;
  margin: 0;
}

.entry_item_address input {
  width: 550px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.emergency {
  display: flex;
}

.emergency .entry_item {
  width: 270px;
}

.face_up_box {
  width: 260px;
  height: 200px;
  background: #e3f5ff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #105ef5;
  font-size: 14px;
}

.add_engineer_box button {
  width: 241px;
  height: 33px;
  align-self: center;
  background: #f5c310;
  border: none;
  color: white;
  border-radius: 10px;
  margin-top: 62px;
  margin-bottom: 41px;
}

.full_part {
  display: flex;
  margin-top: 30px;
}

.radio_item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.radio {
  margin: 5px 0;
  font-size: 13px;
}

.radio_item p {
  font-size: 14px;
  margin: 0 0 10px 0;
}

.enter_date {
  display: flex;
  align-items: center;
  width: 30%;
}
</style>
