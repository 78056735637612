<template>
  <Slide>
    <a href="#">
    <span>MIKからのお知らせ</span></a>
    <a href="#">
    <span>給与明細</span></a>
    <a href="#">
    <span>技術経歴書</span></a>
    <a href="#">
    <span>有休利用履歴</span></a>
    <a href="#">
    <span>ポイント詳細</span></a>
    <a href="#">
    <span>お問合せ</span></a>
    </Slide>
</template>

<script>
import {Slide} from 'vue3-burger-menu';
export default {
  components:{
    Slide
  }

}
</script>

<style>

</style>