import { ref } from "vue";
import getDefaultDatas from "./getDefaultDatas"



const getFilteredDatas =async (doc_id) =>{


  const currentDate = new Date();
  const userid = ref(`${doc_id}@mik-s.work`);
  const futurefilterData = ref([])
  const passedfilterData = ref([])
  const absentfilterData = ref([])

  const {defaultdatas, datas_error, datas_load} =  getDefaultDatas("holiday_application")

 await datas_load();

  futurefilterData.value = defaultdatas.value.filter((user) => {
    try {
      if (user.holiday_date) {
        const oneHoliday = new Date(user.holiday_date);
     
        return (
          
          user.userid === userid.value &&
          oneHoliday > currentDate &&
          user.holiday_type === "paid"
        );
      } else {
        throw Error("データ登録なし");
      }
    } catch (err) {
      console.log(err.message);
    }
  })
  .sort((a, b) => {
    const HDateA = new Date(a.holiday_date);
    const HDateB = new Date(b.holiday_date);

    return HDateB - HDateA;
  });



futurefilterData.value.forEach((data) => {
  data.delete_button_state = false;
  data.button_title = "取消";
  data.fix_button_state = false;
  data.fix_button_title = "修正"
});

// //消化済みデータの作成
passedfilterData.value = defaultdatas.value.filter((user) => {
    try {
      if (user.holiday_date) {
        const oneHoliday = new Date(user.holiday_date);
        return (
          user.userid === userid.value &&
          oneHoliday < currentDate &&
          user.holiday_type === "paid"
        );
      } else {
        throw Error("データがありません");
      }
    } catch (err) {
      console.log(err.message);
    }
  })
  .sort((a, b) => {
    const HDateA = new Date(a.holiday_date);
    const HDateB = new Date(b.holiday_date);

    return HDateB - HDateA;
  });

passedfilterData.value.forEach((data) => {

  console.log("holiday_dateの型",typeof data.holiday_date);
  // if(typeof data.holiday_date === "string"){
  //   data.holiday_date_date = new Date(data.holiday_date).toISOString().substr(0, 10);
  // }else{
  //   console.log("holiday_dateがstringではない")
  // }

  data.delete_button_state = false;
  data.button_title = "取消";
  data.fix_button_state = false;
  data.fix_button_title = "修正"
});



// //欠勤データの作成

absentfilterData.value = defaultdatas.value.filter((user) => {
    return (
      user.userid === userid.value && user.holiday_type === "absent"
    );
  })
  .sort((a, b) => {
    const HDateA = new Date(a.holiday_date);
    const HDateB = new Date(b.holiday_date);

    return HDateB - HDateA;
  });

absentfilterData.value.forEach((data) => {
  data.delete_button_state = false;
  data.button_title = "取消";
  data.fix_button_state = false;
  data.fix_button_title = "修正"
});


//  })


 
return {
  futurefilterData,passedfilterData,absentfilterData

}



}

export default getFilteredDatas