<template>
  <div class="skill_regist_box">
    <div v-if="data_error">{{ data_error }}</div>
    <h3>エンジニアスキル・経験データ登録</h3>
    <div v-if="defaultdata" class="regist_form_wrapper">
      <form @submit.prevent="handleSubmit">
        <div class="skill_regist">
          <div class="skill_regist_left">
            <div class="entry_item">
              <div class="skill_top_wrapper">
                <div class="skill_top_left">
                  <div class="skill_title">ID</div>
                  <div>{{ defaultdata.company_id }}@mik-s.work</div>
                </div>
                <div class="skill_top_right">
                  <div class="skill_title">エンジニア氏名</div>
                  <div v-if="defaultdata" class="skill_name_wrapper">
                    <div class="name_left">
                      <div class="skill_kana">
                        {{ defaultdata.last_name_kana }}
                      </div>
                      <div>{{ defaultdata.last_name }}</div>
                    </div>
                    <div class="name_right">
                      <div class="skill_kana">
                        {{ defaultdata.first_name_kana }}
                      </div>
                      <div>{{ defaultdata.first_name }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="defaultdata" class="engineer_type">
              <div class="radio_item">
                <h5>エンジニア領域</h5>
                <div class="radio">
                  <input
                    type="radio"
                    name="engineer_type"
                    value="development"
                    v-model="defaultdata.engineer_type"
                  />開発
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    name="engineer_type"
                    value="infra"
                    v-model="defaultdata.engineer_type"
                  />インフラ
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    name="engineer_type"
                    value="mobile"
                    v-model="defaultdata.engineer_type"
                  />移動体通信
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    name="engineer_type"
                    value="helpdesk"
                    v-model="defaultdata.engineer_type"
                  />ヘルプデスク
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    name="engineer_type"
                    value="kitting"
                    v-model="defaultdata.engineer_type"
                  />キッティング
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    name="engineer_type"
                    value="other"
                    v-model="defaultdata.engineer_type"
                  />その他
                </div>
              </div>
            </div>
            <div class="skill_entry_item">
              <h5>実務経験年数</h5>
              <div v-if="defaultdata" class="experience">
                <input
                  type="number"
                  id="work_experience_year"
                  v-model="defaultdata.work_experience_year"
                />年
                <input
                  type="number"
                  id="work_experience_month"
                  min="0"
                  max="11"
                  v-model="defaultdata.work_experience_month"
                />ヶ月
              </div>
            </div>

            <div class="entry_item">
              <h5>得意分野・言語</h5>
              <div v-if="defaultdata">
                <input
                  type="text"
                  v-model="specialty"
                  @keydown.enter.prevent="handleKeydown"
                />
              </div>
              <div
                v-for="(specialty, index) in defaultdata.specialty"
                :key="specialty"
              >
                <div class="reg_item">
                  {{ specialty }}
                  <button @click="handleDeleteSPItem(index)">✖️</button>
                </div>
              </div>
            </div>

            <div class="entry_item">
              <h5>保有資格</h5>
              <div v-if="defaultdata">
                <input
                  type="text"
                  v-model="quolification"
                  @keydown.enter.prevent="handle_quo_Keydown"
                />
              </div>
              <div
                v-for="(quolification, index) in defaultdata.quolification"
                :key="quolification"
              >
                <div class="reg_item">
                  {{ quolification }}
                  <button @click="handleDeleteItem(index)">✖️</button>
                </div>
              </div>
            </div>
          </div>
          <div class="skill_regist_right">
            <h5>職務経歴書</h5>
            <div v-if="defaultdata.is_data">
              <button @click="handleDataUpdate">技術経歴書を更新する</button>
            </div>
            <div v-else>
              <input
                type="file"
                id="keireki"
                name="keireki"
                accept="application/pdf"
              />
            </div>
          </div>
        </div>

        <div class="skillreg_button_wrapper">
          <button class="skill_regist_button">登録する</button>
        </div>
      </form>
    </div>
    <div v-else class="pulse-box"><Pulse /></div>
  </div>
</template>

<script>
import getDefaultData from "../composables/getDefaultData";
import { projectFirestore, now_timestamp } from "@/firebase/config";
import Pulse from "../Pulse.vue";
import useStorage from "@/components/composables/useStorage";
import { ref } from "vue";

export default {
  name: "FixDefaultData",
  props: ["engineer_id"],
  components: { Pulse },
  data() {
    return {};
  },
  setup(props, context) {
    const is_data = ref(false);

    const specialty = ref("");
    const specialtys = ref([]);
    const quolification = ref("");

    const handleKeydown = () => {
      if (!defaultdata.value.specialty.includes(specialty.value)) {
        specialty.value = specialty.value.replace(/\s/, "");
        defaultdata.value.specialty.push(specialty.value);
      }
      specialty.value = "";
    };

    const handle_quo_Keydown = () => {
      if (!defaultdata.value.quolification.includes(quolification.value)) {
        quolification.value = quolification.value.replace(/\s/, "");
        defaultdata.value.quolification.push(quolification.value);
      }
      quolification.value = "";
    };

    const { defaultdata, data_error, data_load } = getDefaultData(
      "skillData",
      props.engineer_id
    );

    const emit = context.emit;
    const regClose = (emit) => {
      emit("regist_close");
    };

    data_load();

   const handleDataUpdate = () => {
    defaultdata.value.is_data = false;
   }

    const updateStatus = (newStatus) => {
      defaultdata.value.status = newStatus;
    };

    const handleDeleteItem = (index) => {
      defaultdata.value.quolification.splice(index, 1);
    };

    const handleDeleteSPItem = (index) => {
      defaultdata.value.specialty.splice(index, 1);
    };

    const handleSubmit = async () => {
      const file = ref(null);
      const fileError = ref(null);

      const keireki_doc = document.getElementById("keireki");
      const selected = keireki_doc.files[0];
      console.log(selected);

      if (selected && defaultdata.value.company_id) {
        const { url, uploadImage } = useStorage(
          "keireki",
          defaultdata.value.company_id
        );

        file.value = selected;
        fileError.value = null;

        if (file.value) {
          await uploadImage(file.value);
          defaultdata.value.data_url = url.value;
          console.log("image uploaded, url:", url.value);
          defaultdata.value.is_data = true;
        } else {
          file.value = null;
          face_url.value = null;
        }
      }
      if (defaultdata.value) {
        const regist_post = {
          last_name: defaultdata.value.last_name,
          first_name: defaultdata.value.first_name,
          last_name_kana: defaultdata.value.last_name_kana,
          first_name_kana: defaultdata.value.first_name_kana,
          face_url: defaultdata.value.face_url,
          company_id: defaultdata.value.company_id,
          engineer_type: defaultdata.value.engineer_type,
          work_experience_year: defaultdata.value.work_experience_year,
          work_experience_month: defaultdata.value.work_experience_month,
          specialty: defaultdata.value.specialty,
          quolification: defaultdata.value.quolification,
          is_data: defaultdata.value.is_data,
          data_url: defaultdata.value.data_url,
          first_registAt: now_timestamp(),
          createdAt: defaultdata.value.createdAt,
        };

        // // 各フィールドが `undefined` でないことを確認
        // for (const key in regist_post) {
        //   if (regist_post[key] === undefined) {
        //     delete regist_post[key]; // `undefined` フィールドを削除
        //   }
        // }

        if (Object.keys(regist_post).length > 0) {
          try {
            const res = await projectFirestore
              .collection("skillData")
              .doc(props.engineer_id)
              .set(regist_post);
            console.log("書き込みに成功しました。");
            regClose(emit);
          } catch (err) {
            console.error("データ書き込みエラー：", err);
          }
        }
      }
    };

    return {
      specialty,
      specialtys,
      quolification,
      defaultdata,
      data_error,
      is_data,
      handleDataUpdate,
      handleSubmit,
      updateStatus,
      handleKeydown,
      handle_quo_Keydown,
      handleDeleteItem,
      handleDeleteSPItem,
    };
  },
  methods: {
    pushBack() {
      const on_push = true;
      this.$emit("edit-to-description", on_push);
    },
  },
};
</script>

<style>
.regist_form_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skill_regist_box {
  width: 900px;
  background: white;
  border-radius: 10px;
  margin-left: 20px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  padding: 50px;
}

.skill_regist_box h3 {
  align-self: flex-start;
  margin-bottom: 80px;
}

.entry_part {
  margin-left: 62px;
  width: 500px;
}
.entry_part2 {
  margin-left: 62px;
}
.entry_item {
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}
.entry_item p {
  font-size: 14px;
  margin: 0;
}

.entry_item input {
  width: 259px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}
.entry_item_name p {
  font-size: 14px;
  margin: 0;
}
.entry_item_name {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}

.entry_item_name input {
  width: 159px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
}

.entry_item_address {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}
.entry_item_address p {
  font-size: 14px;
  margin: 0;
}

.entry_item_address input {
  width: 550px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.emergency {
  display: flex;
}
.emergency .entry_item {
  width: 270px;
}

.face_up_box {
  width: 260px;
  height: 200px;
  background: #e3f5ff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #105ef5;
  font-size: 14px;
}

.add_engineer_box button {
  width: 241px;
  height: 33px;
  align-self: center;
  background: #f5c310;
  border: none;
  color: white;
  border-radius: 10px;
  margin-top: 62px;
  margin-bottom: 41px;
}

.full_part {
  display: flex;
  margin-top: 30px;
}

.radio_item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.radio {
  margin: 5px 0;
  font-size: 13px;
}

.radio_item p {
  font-size: 14px;
  margin: 0 0 10px 0;
}

.enter_date {
  display: flex;
  align-items: center;
  width: 30%;
}

.discription_circle_button {
  position: absolute;
  right: 80px;
  top: 120px;
  background: #8d8c89;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  font-size: 24px;
}
.experience {
  display: flex;
  align-items: center;
}

.skill_entry_item input {
  width: 100px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  margin-left: 5px;
  margin-right: 5px;
}

.skill_regist {
  display: flex;
}
.skillreg_button_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.skill_regist_button {
  width: 200px;
  height: 37px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 10px;
  margin-top: 50px;
  margin-right: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.reg_item {
  width: 200px;
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
}
.reg_item button {
  border: none;
}
</style>
