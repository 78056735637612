<template>
  <div class="skill_engineer_box">
    <div v-if="data_error">{{ data_error }}</div>
    <h3>エンジニアスキル・経験データ</h3>
    <div v-if="defaultdata">
      <div class="home_data_row_face">
        <div v-if="defaultdata && defaultdata.face_url">
          <img :src="defaultdata.face_url" class="face_box" />
        </div>
        <div v-else><div class="face_image_prev"></div></div>
      </div>

      <div class="home_data_row">
        <div class="home_data_box">
          <div class="home_data_title">
            <h5>エンジニア氏名</h5>
          </div>
          <div class="data_item">
            <div v-if="defaultdata" class="skill_name_wrapper">
              <div class="name_left">
                <div class="skill_kana">{{ defaultdata.last_name_kana }}</div>
                <div>{{ defaultdata.last_name }}</div>
              </div>
              <div class="name_right">
                <div class="skill_kana">
                  {{ defaultdata.first_name_kana }}
                </div>
                <div>{{ defaultdata.first_name }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="home_data_box">
          <div class="home_data_title">
            <h5>エリア</h5>
          </div>
          <div v-if="defaultdata" class="data_item">
            <AreaStatus :area="defaultdata.area" />
          </div>
        </div>

        <div class="home_data_box">
          <div class="home_data_title">
            <h5>担当者</h5>
          </div>
          <div v-if="defaultdata" class="data_item">
            <PICStatus :pic="defaultdata.pic" />
          </div>
        </div>
      </div>

      <div class="home_data_row">
        <div class="home_data_box">
          <div class="home_data_title">
            <h5>エンジニア領域</h5>

            <div>
              <span class="material-symbols-outlined" @click="handleEditType">
                edit_square
              </span>
            </div>
          </div>
          <!-- ここからデータ部 -->
          <div v-if="!show_input_array[0]">
            <div v-if="defaultdata" class="data_item">
              <JobStatus
              :id="defaultdata.id"
              :job="defaultdata.engineer_type" />
            </div>
          </div>
          <!-- データ挿入部 -->
          <div v-else>
            <div v-if="defaultdata" class="data_item">
              <div class="skill_radio_item">
                <div class="radio">
                  <input
                    type="radio"
                    name="engineer_type"
                    value="development"
                    v-model="defaultdata.engineer_type"
                  />開発
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    name="engineer_type"
                    value="infra"
                    v-model="defaultdata.engineer_type"
                  />インフラ
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    name="engineer_type"
                    value="mobile"
                    v-model="defaultdata.engineer_type"
                  />移動体通信
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    name="engineer_type"
                    value="helpdesk"
                    v-model="defaultdata.engineer_type"
                  />ヘルプデスク
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    name="engineer_type"
                    value="kitting"
                    v-model="defaultdata.engineer_type"
                  />キッティング
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    name="engineer_type"
                    value="other"
                    v-model="defaultdata.engineer_type"
                  />その他
                </div>
              </div>
            </div>
            <button @click="handleRegistType">登録</button>
          </div>
          <!-- ここまで -->
        </div>

        <div class="home_data_box">
          <div class="home_data_title">
            <h5>実務経験年数</h5>
            <span class="material-symbols-outlined" @click="handleShowCareers">
              edit_square
            </span>
          </div>
          <!-- データここから -->
          <div class="data_item" v-if="!show_input_array[1]">
            <div v-if="defaultdata">
              {{ defaultdata.work_experience_year }}年{{
                defaultdata.work_experience_month
              }}ヶ月
            </div>
          </div>
          <div v-else class="data_item">
            <div class="radio_item_column">
              <div class="radio_item_column">
                <div class="radio">
                  <input
                    type="radio"
                    name="calc_method"
                    v-model="method"
                    :disabled="!defaultdata.enter_date"
                    value="1"
                  />入社時を起点として計算する
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    name="calc_method"
                    v-model="method"
                    value="2"
                  />業務開始時期を設定して計算する
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    name="calc_method"
                    v-model="method"
                    value="3"
                  />期間を入力する
                </div>
              </div>

              <div v-if="method === '1'">
                <h6>入社日</h6>
                <div v-if="typeof defaultdata.enter_date === 'string'">
                  {{ defaultdata.enter_date }}
                </div>

                <div v-else>
                  {{
                    defaultdata.enter_date.toDate().toLocaleDateString("sv-SE")
                  }}
                </div>

                <h6>ブランク期間</h6>
                <div class="skill_radio_item">
                  <div class="radio">
                    <input
                      type="radio"
                      name="blank"
                      v-model="blank"
                      value="1"
                    />あり
                  </div>
                  <div class="radio">
                    <input
                      type="radio"
                      name="blank"
                      v-model="blank"
                      value="2"
                    />なし
                  </div>
                </div>

                <div v-if="blank === '1'">
                  <input type="number" v-model="blank_years" />年
                  <input type="number" v-model="blank_months" />ヶ月
                </div>

                <button
                  @click="handleCalcfromEnterday(blank_years, blank_months)"
                >
                  登録
                </button>
              </div>

              <div v-if="method === '2'" class="skill_input_item">
                <h6>エンジニア開始起点日</h6>
                <input type="date" v-model="start_date" />
                <h6>ブランク期間</h6>
                <div class="skill_radio_item">
                  <div class="radio">
                    <input
                      type="radio"
                      name="blank"
                      v-model="blank"
                      value="1"
                    />あり
                  </div>
                  <div class="radio">
                    <input
                      type="radio"
                      name="blank"
                      v-model="blank"
                      value="2"
                    />なし
                  </div>
                </div>

                <div v-if="blank === '1'">
                  <input type="number" v-model="blank_years" />年
                  <input type="number" v-model="blank_months" />ヶ月
                </div>
                <button
                  @click="
                    handleCalcfromStartday(
                      start_date,
                      blank_years,
                      blank_months
                    )
                  "
                >
                  登録
                </button>
              </div>

              <div v-if="method === '3'">
                <input
                  type="number"
                  id="work_experience_year"
                  v-model="defaultdata.work_experience_year"
                />年
                <input
                  type="number"
                  id="work_experience_month"
                  min="0"
                  max="11"
                  v-model="defaultdata.work_experience_month"
                />ヶ月
                <button @click="handleRegistCareers">登録</button>
              </div>
            </div>
          </div>
          <!-- データここまで -->
        </div>

        <div class="home_data_box">
          <div class="home_data_title">
            <h5>得意分野・言語</h5>
            <span
              class="material-symbols-outlined"
              @click="handleEditSpecialty"
            >
              edit_square
            </span>
          </div>
          <!-- データここから -->
          <div v-if="!show_input_array[2]" class="data_item">
            <div v-if="defaultdata.specialty">
              <div v-for="specialty in defaultdata.specialty" :key="specialty">
                {{ specialty }}
              </div>
            </div>
            <div v-else>
              <div v-for="specialty in specialties" :key="specialty">
                {{ specialty }}
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="defaultdata.specialty" class="data_item_column">
              <input
                type="text"
                v-model="specialty"
                @keydown.enter.prevent="handleKeydownSpecialty"
              />
              <div
                v-for="(specialty, index) in specialties"
                :key="specialty"
                class="reg_item"
              >
                {{ specialty }}
                <button @click="handleDeleteSPItem(index)">✖️</button>
              </div>
              <button @click="handleRegistSpecialty">登録</button>
            </div>
            <div v-else class="data_item_column">
              <input
                type="text"
                v-model="specialty"
                @keydown.enter.prevent="handleKeydownSpecialty"
              />
              <div class="data_item_column">
                <div
                  v-for="(specialty, index) in specialties"
                  :key="specialty"
                  class="reg_item"
                >
                  {{ specialty }}
                  <button @click="handleDeleteSPItem(index)">✖️</button>
                </div>
              </div>
              <button @click="handleRegistSpecialty">登録</button>
            </div>
          </div>
          <!-- データここまで -->
        </div>
      </div>

      <div class="home_data_row">
        <div class="home_data_box">
          <div class="home_data_title">
            <h5>保有資格</h5>
            <span class="material-symbols-outlined" @click="handleEditQualify">
              edit_square
            </span>
          </div>
          <!-- データここから -->
          <div v-if="!show_input_array[3]" class="data_item">
            <div v-if="defaultdata.qualification">
              <div
                v-for="qualification in defaultdata.qualification"
                :key="qualification"
              >
                {{ qualification }}
              </div>
            </div>
            <div v-else>
              <div v-for="qualification in qualifications" :key="qualification">
                {{ qualification }}
              </div>
            </div>
          </div>
          <div v-else class="data_item_column">
            <input type="text" v-model="qualification" />
            <button @click="handleAddQualify">追加</button>
            <div
              v-for="(qualification, index) in qualifications"
              :key="qualification"
              class="reg_item"
            >
              {{ qualification }}
              <button @click="handleDeleteQFItem(index)">✖️</button>
            </div>
            <button @click="handleRegistQualification">登録</button>
          </div>
          <!-- データここまで -->
        </div>

        <div class="home_data_box">
          <div class="home_data_title">
            <h5>技術経歴書</h5>
            <span
              class="material-symbols-outlined"
              @click="handleSelectDocument"
            >
              edit_square
            </span>
          </div>
          <div v-if="!show_input_array[4]">
            <div v-if="defaultdata && defaultdata.data_url" class="data_item">
              <button
                @click="openPDF(defaultdata.data_url)"
                class="skilldata_button"
              >
                技術経歴書
              </button>
            </div>
          </div>
          <div v-else class="skill_input_item">
            <input
              type="file"
              id="keireki"
              name="keireki"
              accept="application/pdf"
            />
            <button @click="handleUploadDoc">登録</button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="pulse-box"><Pulse /></div>
  </div>
</template>

<script>
import getDefaultData from "../composables/getDefaultData";
import Pulse from "../Pulse.vue";
import { onMounted, ref } from "vue";
import JobStatus from "./JobStatus.vue";
import AreaStatus from "../home_comp/AreaStatus.vue";
import PICStatus from "../home_comp/PICStatus.vue";
import { projectFirestore } from "@/firebase/config";
import useStorage from "../composables/useStorage";

export default {
  props: ["engineer_id"],
  name: "DetailSelectedEngineer",
  components: {
    Pulse,
    JobStatus,
    AreaStatus,
    PICStatus,
  },
  data() {
    return {
      method: null,
      blank: null,
    };
  },

  setup(props) {
    const specialty = ref("");
    const specialties = ref([]);
    const qualification = ref("");
    const qualifications = ref([]);
    const show_input_array = ref(Array.from({ length: 5 }, () => false));
    const blank_years = ref(0);
    const blank_months = ref(0);
    const start_date = ref("");

    const { defaultdata, data_error, data_load } = getDefaultData(
      "skillData",
      props.engineer_id
    );

    data_load();

    onMounted(() => {
      if (defaultdata.value) {
        if (!defaultdata.value.specialty) {
          console.log("初期化1");
          const post = {
            specialty: specialties.value,
          };
          updateData("specialtyフィールド追加", post);
        }

        if (!defaultdata.value.qualification) {
          console.log("初期化2");
          const qualify_post = {
            qualification: qualifications.value,
          };
          updateData("qualificationフィールド追加", qualify_post);
        }
      }
    });

    const updateData = async (name, post) => {
      console.log("post",post)
      try {
        await projectFirestore
          .collection("skillData")
          .doc(props.engineer_id)
          .update(post);
      } catch (err) {
        console.log(`${name}の変更に失敗しました。`, err.message);
      }
    };

    const changeShowInput = (targetIndex) => {
      show_input_array.value[targetIndex] =
        !show_input_array.value[targetIndex];

      return !show_input_array.value[targetIndex];
    };

    const handleKeydownSpecialty = () => {
      if (!specialties.value) {
        specialties.value = [];
      }

      if (!specialties.value.includes(specialty.value)) {
        specialty.value = specialty.value.replace(/\s/, "");
        specialties.value.push(specialty.value);
      }
      specialty.value = "";
    };

    const handleAddQualify = () => {
      if (!qualification.value) {
        qualifications.value = [];
      } else {
        const trimmedQualification = qualification.value.trim();
        qualifications.value = qualifications.value || [];
        if (
          trimmedQualification !== "" &&
          (!qualifications.value ||
            !qualifications.value.includes(trimmedQualification))
        ) {
          qualification.value = qualification.value.replace(/\s/, "");
          qualifications.value.push(trimmedQualification);

          console.log(qualifications.value);
        }
      }
      qualification.value = "";
    };

    const handleDeleteSPItem = (index) => {
      specialties.value.splice(index, 1);
    };

    const handleDeleteQFItem = (index) => {
      qualifications.value.splice(index, 1);
    };

    const handleEditType = () => {
      const targetIndex = 0;
      changeShowInput(targetIndex);
    };

    const handleShowCareers = () => {
      const targetIndex = 1;
      changeShowInput(targetIndex);
    };

    const handleEditSpecialty = () => {
      console.log("special_click");
      const targetIndex = 2;
      changeShowInput(targetIndex);
      specialties.value = defaultdata.value.specialty;
      console.log(show_input_array.value);
    };

    const handleEditQualify = () => {
      console.log("edit");
      const targetIndex = 3;
      changeShowInput(targetIndex);
      console.log("3", show_input_array.value);
      qualifications.value = defaultdata.value.qualification;
    };

    const handleSelectDocument = () => {
      const targetIndex = 4;
      changeShowInput(targetIndex);
    };

    const handleRegistType = () => {
      const post = {
        engineer_type: defaultdata.value.engineer_type,
      };
      updateData("type", post);
      show_input_array.value[0] = false;
    };

    const handleRegistCareers = async () => {
      console.log("push!")
      let experience_year = 0
      let experience_month = 0
      if(defaultdata.value.work_experience_year){
        experience_year = defaultdata.value.work_experience_year
      }

      if(defaultdata.value.work_experience_month){
        experience_month = defaultdata.value.work_experience_month
      }
      const post = {
        work_experience_year: experience_year,
        work_experience_month:experience_month,
      };
      await updateData("経歴", post);
      show_input_array.value[1] = false;
    };

    const handleRegistSpecialty = () => {
      console.log("specialties.value", specialties.value);
      const post = {
        specialty: [...specialties.value],
      };
      updateData("得意", post);
      show_input_array.value[2] = false;
    };

    const handleRegistQualification = () => {
      const post = {
        qualification: [...qualifications.value],
      };
      updateData("資格", post);
      show_input_array.value[3] = false;
    };

    const handleUploadDoc = async () => {
      const file = ref(null);
      const fileError = ref(null);

      const keireki_doc = document.getElementById("keireki");
      const selected = keireki_doc.files[0];
      console.log(selected);

      const folder_id = defaultdata.value.company_id.replace("@mik-s.work", "");

      if (selected && folder_id) {
        const { url, uploadImage } = useStorage("keireki", folder_id);

        file.value = selected;
        fileError.value = null;

        if (file.value) {
          await uploadImage(file.value);
          defaultdata.value.data_url = url.value;
          console.log("image uploaded, url:", url.value);
          const post = {
            data_url: defaultdata.value.data_url,
          };

          updateData("data_file", post);
          show_input_array.value[4] = false;
        }
      } else {
        file.value = null;
      }
    };

    const handleCalcfromEnterday = (blank_years, blank_months) => {
      const today = new Date();
      const base = defaultdata.value.enter_date.toDate();

      const timeDiffBlank =
        (blank_years * 365.25 + blank_months * 30.44) * 24 * 60 * 60 * 1000;

      const timeDiff = today - base - timeDiffBlank;
      const years = Math.floor(timeDiff / (365.25 * 24 * 60 * 60 * 1000));
      const months = Math.floor(
        (timeDiff % (365.25 * 24 * 60 * 60 * 1000)) /
          (30.44 * 24 * 60 * 60 * 1000)
      );

      defaultdata.value.work_experience_year = years;
      defaultdata.value.work_experience_month = months;

      handleRegistCareers();
    };

    const handleCalcfromStartday = (start_date, blank_years, blank_months) => {
      const today = new Date();
      const base = new Date(start_date);
      console.log("base", base);

      const timeDiffBlank =
        (blank_years * 365.25 + blank_months * 30.44) * 24 * 60 * 60 * 1000;

      const timeDiff = today - base - timeDiffBlank;
      const years = Math.floor(timeDiff / (365.25 * 24 * 60 * 60 * 1000));
      const months = Math.floor(
        (timeDiff % (365.25 * 24 * 60 * 60 * 1000)) /
          (30.44 * 24 * 60 * 60 * 1000)
      );

      defaultdata.value.work_experience_year = years;
      defaultdata.value.work_experience_month = months;

      handleRegistCareers();
    };

    return {
      blank_years,
      blank_months,
      start_date,
      show_input_array,
      defaultdata,
      data_error,
      specialty,
      qualification,
      qualifications,
      specialties,
      handleEditType,
      handleRegistType,
      handleSelectDocument,
      handleShowCareers,
      handleRegistCareers,
      handleKeydownSpecialty,
      handleAddQualify,
      handleEditSpecialty,
      handleRegistSpecialty,
      handleDeleteSPItem,
      handleEditQualify,
      handleRegistQualification,
      handleDeleteQFItem,
      handleUploadDoc,
      handleCalcfromEnterday,
      handleCalcfromStartday,
    };
  },
  methods: {
    pushReturn() {
      const is_close = true;
      this.$emit("return-button-click", is_close);
      console.log("emit1");
    },
    pushEdit() {
      const is_push = true;
      this.$emit("switch-edit-screen", is_push);
    },
    openPDF(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style>
.skill_engineer_box {
  width: 900px;
  background: white;
  border-radius: 10px;
  margin-left: 20px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  padding: 50px;
}

.skill_engineer_box .home_data_title {
  background: #f9bc60;
}

.pulse-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.skill_detail_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.skill_detail_right {
  display: flex;
  align-items: flex-start;
}
.skill_top_right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.skill_top_left {
  margin-bottom: 50px;
}
.skill_name_wrapper {
  display: flex;
}

.skill_kana {
  font-size: 12px;
}

.skill_engineer_box .face_image_prev {
  background-color: #f9bc60;
  background-image: url("@/assets/mik_logo_toumei.png");
}
.skill_engineer_box .material-symbols-outlined {
  font-size: 20px;
  cursor: pointer;
}

.skill_input_item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
}

.skill_input_item input {
  width: 200px;
  height: 80px;
  display: flex;
  align-items: center;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.skill_radio_item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.radio {
  display: flex;
  align-items: center;
}

.radio input {
  width: 20px;
  margin: 5px 5px 0 0;
  font-size: 13px;
}

.radio_column {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.radio_column input {
  width: 20px;
  margin: 5px 5px 0 0;
  font-size: 13px;
}

.reg_item {
  width: 200px;
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
}
.reg_item button {
  border: none;
}

.data_item_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}
</style>
