<template>
  <div class="point_add_form">
    <h4>ポイント換金申請が来ています。</h4>
    <div v-if="local_use_datas">
      <table>
        <tr>
          <th>#</th>
          <th>利用ポイント</th>
          <th>換金額</th>
          <th>申請日</th>
          <th>給与反映予定月</th>
          <th>対応</th>
        </tr>
        <tr v-for="(data, index) in local_use_datas" :key="data.id">
          <td>{{ (index + 1).toString().padStart(2, "0") }}</td>
          <td>
            <div class="table_item">{{ data.points }}pt</div>
          </td>
          <td>
            <div class="table_item">{{ data.change_money }}円</div>
          </td>
          <td>
            <div v-if="data.point_date" class="table_item">
              {{ data.point_date.toDate().toLocaleDateString("sv-SE") }}
            </div>
          </td>
          <td>
            <div class="table_item">
              <div v-if="data.point_date.toDate().getMonth() + 1 <= 12">
                {{ data.point_date.toDate().getMonth() + 1 }}月分給与
              </div>
              <div v-else>
                {{ data.point_date.toDate().getMonth() + 1 - 12 }}月分給与
              </div>
            </div>
          </td>
          <td>
            <div>
              <button
                @click="handleCorrespond(data.id)"
                :disabled="data.disable_status"
              >
                {{ data.button_title }}
              </button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { projectFirestore } from "@/firebase/config";
import { onMounted, ref, watch } from "vue";
export default {
  props: ["use_datas", "user_id"],
  setup(props) {
    const local_use_datas = ref(props.use_datas);

    onMounted(() => {
      local_use_datas.value = [...props.use_datas];

      local_use_datas.value.forEach((data) => {
        data.disable_status = false;
        data.button_title = "対応";

        console.log("buttontitle:", data.button_title);
      });
    });

    const handleCorrespond = async (id) => {
      console.log("push");
      const post = {
        is_submit: false,
      };
      await projectFirestore
        .collection("points")
        .doc(id)
        .update(post)
        .then(() => {
          projectFirestore
            .collection("pointData")
            .doc(props.user_id)
            .update(post);

          const correspondedIndex = local_use_datas.value.findIndex(
            (data) => data.id === id
          );
          if (correspondedIndex !== -1) {
            local_use_datas.value[correspondedIndex].disable_status = true;
            local_use_datas.value[correspondedIndex].button_title = "対応済み";
          }
          console.log(local_use_datas.value[correspondedIndex].disable_stastus);
        })
        .catch((err) => {
          console.error("アップデートに失敗しました", err);
        });
    };
    return {
      local_use_datas,
      handleCorrespond,
    };
  },
};
</script>

<style></style>
