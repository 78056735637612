<template>
  <div class="userpoint_use_form">
    <h4>ポイント利用登録</h4>
    <h5>ポイント対象者</h5>
    <div v-if="user_data">
      <div>{{ user_data.last_name }}{{ user_data.first_name }}</div>
    </div>
    <h5>利用ポイント</h5>
    <input type="number" v-model="point" />
  
    <h5>ポイント利用申請日</h5>
    <input type="date" v-model="point_date" />

    <h5>備考</h5>
    <input type="text" v-model="point_other" class="point_reason_area"/>
    <div>
      <button @click="handleUsepoint">登録</button>
      <button @click="handleResetPoint">クリア</button>
    </div>

    <div class="point_use_close" @click="handleClose">×</div>
  </div>
</template>

<script>
import { ref, reactive, watch } from "vue";
import { projectFirestore } from "@/firebase/config";
export default {
  props: ["users", "user_data"],
  setup(props, context) {
    const local_users = ref(props.users);
    const point_user_id = ref("");
    const point_content_num = ref("");
    const point_month = ref("");
    const point_content = ref("");
    const points = ref("");
    const point_date = ref("");
    const point_other = ref("");
    const input_point = ref(0);
    const input_comment = ref("");
    const point = ref(0)

    watch(
      () => props.users,
      (newUsers, oldUsers) => {
        local_users.value = newUsers;
        console.log("local", local_users.value);
      }
    );

    const handleResetPoint = () => {
      local_users.value = [];
      point_content_num.value = null;
      point_date.value = null;
      point_other.value = null;
      points.value = null;
      point_month.value = null;
      point_content.value = null;
      input_point.value = null;
      input_comment.value = null;
    };

    const closeAddform = () => {
      console.log("click1");
      context.emit("add_form_close");
    };

    const handleUsepoint = async () => {

        const currentday = new Date();

      const point_usepost = {
        point_user_id: props.user_data.id,
        point_status: "use",
        point_content: "利用申出あり",
        points: point.value,
        point_date: point_date.value,
        point_other: point_other.value,
        createdAt: currentday,
      };
   

      try {
        const res = await projectFirestore
          .collection("points")
          .add(point_usepost);
        console.log("ポイントの書き込みに成功しました。");
        context.emit("point_use_finish");
        handleResetPoint();
  
      } catch (err) {
        console.error("ポイントの書き込みに失敗しました:", err);
      }
    };

    return {
      point_content_num,
      point_content,
      point_month,
      points,
      point_date,
      point_other,
      local_users,
      input_point,
      input_comment,
      point,

      handleUsepoint,
      handleResetPoint,
    };
  },
  methods:{
    handleClose(){
      this.$emit("handle_use_close")
    }
  },
};
</script>

<style>
.userpoint_use_form {
  position: relative;
  width: 480px;
  background: white;
  border-radius: 20px;

  border: 3px solid;
  padding: 0 20px 20px;
  font-size: 15px;
  margin: 20px;
}

.userpoint_use_form h4 {
  margin-bottom: 25px;
}

.userpoint_use_form h5 {
  margin-bottom: 5px;
}

.point_form_content {
  display: flex;
  padding-bottom: 20px;
}

.point_form_select {
  display: flex;
}

.point_form_item {
  margin-right: 30px;
}

.point_use_close {
  position: absolute;
  right: 15px;
  top: 10px;
  color: #2c3e50;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

</style>
