import { ref } from "vue";
import { projectAuth } from "@/firebase/config";
import getAdminDatas from "./getAdminDatas";

const error = ref(null);

const admin_login = async (email, password) => {
  error.value = null;

  try {
    if (isAdmin(email)) {
      const res = await projectAuth.signInWithEmailAndPassword(email, password);
      error.value = null;

      if (!res) {
        throw Error("ログイン情報が間違っています。");
      }
    } else {
      throw Error("Admin権限がありません。");
    }
  } catch (err) {
    console.log(err.value);
    error.value = err.message;
  }
};

const isAdmin = async (email) => {
  const { admindatas, load } = getAdminDatas();

  try {
    await load();
    console.log("adimin_id", admindatas.value.admin_id);
    console.log("email", email);

    const result = admindatas.value.some((admin) => {
      return admin.admin_id === email;
    });
    return result;
  } catch (err) {
    console.error("Error in isAdmin", err);
    return false;
  }
};

const useAdminLogin = () => {
  return { error, admin_login, isAdmin };
};

export default useAdminLogin;
