<template>
  <div class="holidata_button_container">
    <button class="top_ret_button" @click="handleReturnRestTop">もどる</button>
    <div>
      <button class="top_button" @click="handleShowFirstSet">データ更新</button>
    </div>
    <div>
      <button class="top_button" @click="handleShowAddHoliday">有休付与</button>
    </div>

    <button class="top_button" @click="handleShowAbform">
      {{ show_ab_button_title }}
    </button>
  </div>
  <div v-if="is_show_ab_app_form">
    <div v-if="defaultdata && defaultdata.company_id">
      <AbsentAppForm :passedfilterData="local_passedData" :futurefilterData="local_futureData"
        :company_id="defaultdata.company_id" :remain_2yearbefore="defaultdata.twoyear_before_remain"
        @absent_form_close="handleAbsentClose" />
    </div>
  </div>
  <div v-if="is_show_first_set">
    <div v-if="defaultdata && defaultdata.enter_date">
      <FirstSetting :enter_date="defaultdata.enter_date" @first_set="handleFirstSet" />
    </div>
  </div>
  <div v-if="isAddHoliday">
    <div v-if="defaultdata">
      <AddHoliday :user_id="defaultdata.company_id" :add_day="defaultdata.num_of_add"
        :date_of_add="defaultdata.next_add" :enter_date="defaultdata.enter_date" :base_day="defaultdata.base_day"
        :usedholiday="oneyear_use_days" :remain_2yearbefore="defaultdata.twoyear_before_remain"
        :receive_total="defaultdata.receive_num_of_day" @holiday_add_finish="handleAddSetting"
        @set_datas="handleFirstSet" @add_holiday_close="handleAddHoliClose" />
    </div>
  </div>

  <!-- NoticeTimeOverは修正後いずれ再度追加 -->
  <!-- <div v-if="isTimeOverMessage">
    <NoticeTimeOver
      :limit="limit_holiday - totalUseHoliday"
      :diff="diffDays"
      @timeover_message_close="handleTimeOverClose"
    />
  </div> -->

  <div class="holiday_box">
    <div v-if="data_error">{{ data_error }}</div>
    <h3>有給休暇詳細データ</h3>
    <div v-if="defaultdata">
      <div v-if="defaultdata">
        <div class="holiday_wrapper">
          <div class="holiday_content_wrapper">
            <div class="holiday_content_item">
              <h4>エンジニア氏名</h4>
              <div v-if="defaultdata" class="skill_name_wrapper">
                <div class="holiday_name_left">
                  <div class="skill_kana">
                    {{ defaultdata.last_name_kana }}
                  </div>
                  <div>{{ defaultdata.last_name }}</div>
                </div>
                <div class="holiday_name_right">
                  <div class="skill_kana">
                    {{ defaultdata.first_name_kana }}
                  </div>
                  <div>{{ defaultdata.first_name }}</div>
                </div>
              </div>
            </div>
            <div class="holiday_content_item">
              <h4>ID</h4>
              <div>{{ defaultdata.company_id }}</div>
            </div>
            <div class="holiday_content_item">
              <h4>有休利用ステータス</h4>

              <HolidayStatus :status_number="status" />
            </div>

            <div class="holiday_content_item">
              <h4>入社日</h4>
              <div v-if="defaultdata.enter_date">
                <div v-if="typeof defaultdata.enter_date === 'string'">
                  {{ defaultdata.enter_date }}
                </div>
                <div v-else>
                  {{
                    defaultdata.enter_date.toDate().toLocaleDateString("sv-SE")
                  }}
                </div>
              </div>
            </div>
          </div>

          <div class="holiday_content_wrapper">
            <div class="rest_entry_item">
              <h4>初回有休付与基準日</h4>
              <div v-if="defaultdata.base_day">
                {{ defaultdata.base_day }}
                <button @click="handleInputBaseday">
                  {{ base_button_title }}
                </button>
              </div>
              <div v-else>
                未設定 <button @click="handleInputBaseday">設定</button>
              </div>
              <div v-if="is_show_input_baseday">
                <input type="date" class="rest_input_date" v-model="defaultdata.base_day" /><button
                  @click="pushBaseRegist">登録</button>
              </div>
            </div>

            <div class="rest_entry_item">
              <h4>有効有休日数</h4>
              <div v-if="totalReceiveHoliday">
                {{ totalReceiveHoliday }}日

                <button @click="handleInputRemain">修正</button>
                <div>
                  <h6>前々期繰越日数</h6>
                  {{ defaultdata.twoyear_before_remain }}日
                </div>
              </div>
              <div v-else>
                未設定<button @click="handleInputRemain">設定</button>
              </div>

              <div v-if="is_show_input_remain" class="show_input_remain">
                <div class="remain_up">
                  <h5>有効有休日数</h5>
                  <input type="number" class="rest_input_num" v-model="defaultdata.receive_num_of_day" />
                </div>
                <div class="remain_down">
                  <h5>前々期繰越日数</h5>
                  <input type="number" class="rest_input_num" v-model="defaultdata.twoyear_before_remain" />
                </div>

                <button @click="pushReceiveRegist">登録</button>
              </div>
            </div>

            <div class="rest_entry_item">
              <h4>有休残日数</h4>

              {{
                defaultdata.receive_num_of_day +
                defaultdata.twoyear_before_remain -
                (use_days + plan_days)
              }}日
            </div>
          </div>

          <div class="holiday_content_wrapper">
            <div class="rest_entry_item">
              <h4>本年度使用済み有休日数</h4>
              <div>{{ oneyear_use_days }}日</div>
            </div>

            <div class="rest_entry_item">
              <h4>次回有休付与日</h4>
              <div v-if="defaultdata.next_add" class="data_container">
                <div>{{ defaultdata.next_add }}</div>

                <div><button @click="handleInputNextadd">修正</button></div>
              </div>
              <div v-else>
                <div>
                  未設定 <button @click="handleInputNextadd">設定</button>
                </div>
              </div>
              <div v-if="is_show_input_nextadd">
                <input type="date" class="rest_input_date" v-model="defaultdata.next_add" /><button
                  @click="pushNextAdd">登録</button>
              </div>
            </div>

            <div class="rest_entry_item">
              <h4>次回付与予定の有休日数</h4>
              <div v-if="defaultdata.num_of_add" class="data_container">
                {{ defaultdata.num_of_add }}日
                <div>
                  <button @click="handleInputNumofAdd">修正</button>
                </div>
              </div>
              <div v-else>
                未設定 <button @click="handleInputNumofAdd">設定</button>
              </div>
              <div v-if="is_show_input_num_of_add">
                <input type="number" class="rest_input_num" v-model="defaultdata.num_of_add" />日<button
                  @click="pushNumofadd">登録</button>
              </div>
            </div>
          </div>
        </div>

        <div class="rest_detail_title">有給休暇予定</div>
        <div v-if="local_futureData">
          <table>
            <tr>
              <th>No.</th>
              <th>有休取得予定日</th>
              <th>使用日数</th>
              <th>取得理由</th>
              <th>申請日</th>
              <th>登録者</th>
              <th>修正/取消</th>
            </tr>
            <tr v-for="(futuredata, index) in local_futureData" :key="futuredata.id">
              <td>{{ (index + 1).toString().padStart(2, "0") }}</td>
              <td>
                <div v-if="futuredata.holiday_date" class="rest_table_item">
                  {{ futuredata.holiday_date }}
                </div>
                <div v-else class="rest_table_item">
                  {{ futuredata.start_day }}~{{ futuredata.end_day }}
                </div>
              </td>
              <td>
                <div class="rest_table_item">
                  {{ futuredata.use_holiday_num }}
                </div>
              </td>
              <td>
                <div class="rest_table_item_reason">
                  {{ futuredata.holiday_reason }}
                </div>
              </td>
              <td>
                <div v-if="futuredata.createdAt" class="rest_table_item">
                  {{ futuredata.createdAt.toDate().getFullYear() }}/
                  {{ futuredata.createdAt.toDate().getMonth() + 1 }}/{{
                    futuredata.createdAt.toDate().getDate()
                  }}
                </div>
              </td>
              <td>
                <div class="rest_table_item" v-if="futuredata.created">
                  {{ futuredata.created }}
                </div>
              </td>
              <td>
                <div class="rest_table_item" v-if="futuredata.fix_title">
                  <button :disabled="futuredata.fix_button_state" @click="handlefutureDataDelete(futuredata.id)">
                    {{ futuredata.fix_title }}
                  </button>
                </div>
              </td>

              <td>
                <div class="rest_table_item" v-if="futuredata.button_title">
                  <button :disabled="futuredata.delete_button_state" @click="handlefutureDataDelete(futuredata.id)">
                    {{ futuredata.button_title }}
                  </button>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div v-else>データがありません。</div>
        <div class="rest_detail_title">取得済み有給休暇</div>

        <div v-if="local_passedData">
          <table>
            <tr>
              <th>No.</th>
              <th>有休取得日</th>
              <th>使用日数</th>
              <th>取得理由</th>
              <th>申請日</th>
              <th>登録者</th>
              <th>修正/取消</th>
            </tr>
            <tr v-for="(passdata, index) in local_passedData" :key="passdata.id">
              <td>{{ (index + 1).toString().padStart(2, "0") }}</td>
              <td>
                <div class="rest_table_item" v-if="!passdata.fix_button_state">
                  {{ passdata.holiday_date }}
                </div>
                <div v-else>
                  <input type="string" v-model="passdata.holiday_date" @keydown.enter="
                    handleChangeHolidayData(
                      passdata.id,
                      passdata.holiday_date,
                      passdata.use_holiday_num,
                      passdata.holiday_reason,
                      passdata.created
                    )
                    " />
                </div>
              </td>
              <td>
                <div class="rest_table_item" v-if="!passdata.fix_button_state">
                  {{ passdata.use_holiday_num }}
                </div>
                <div v-else>
                  <input type="number" v-model="passdata.use_holiday_num" @keydown.enter="
                    handleChangeHolidayData(
                      passdata.id,
                      passdata.holiday_date,
                      passdata.use_holiday_num,
                      passdata.holiday_reason,
                      passdata.created
                    )
                    " />
                </div>
              </td>
              <td>
                <div class="rest_table_item_reason" v-if="!passdata.fix_button_state">
                  {{ passdata.holiday_reason }}
                </div>
                <div v-else>
                  <input type="string" v-model="passdata.holiday_reason" @keydown.enter="
                    handleChangeHolidayData(
                      passdata.id,
                      passdata.holiday_date,
                      passdata.use_holiday_num,
                      passdata.holiday_reason,
                      passdata.created
                    )
                    " />
                </div>
              </td>
              <td>
                <div class="rest_table_item" v-if="passdata.createdAt">
                  {{ passdata.createdAt.toDate().toLocaleDateString("sv-SE") }}
                </div>
              </td>
              <td>
                <div class="rest_table_item" v-if="!passdata.fix_button_state">
                  {{ passdata.created }}
                </div>
                <div v-else>
                  <input type="string" v-model="passdata.created" @keydown.enter="
                    handleChangeHolidayData(
                      passdata.id,
                      passdata.holiday_date,
                      passdata.use_holiday_num,
                      passdata.holiday_reason,
                      passdata.created
                    )
                    " />
                </div>
              </td>
              <td>
                <div class="table_buttons">
                  <div v-if="passdata.button_title">
                    <button @click="handlepassedFixData(passdata.id)">
                      {{ passdata.fix_button_title }}
                    </button>
                  </div>
                  <div v-if="passdata.button_title">
                    <button :disabled="passdata.delete_button_state" @click="handlepassedDataDelete(passdata.id)">
                      {{ passdata.button_title }}
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div v-else>データがありません。</div>
      </div>

      <div class="rest_detail_title">欠勤状況</div>
      <div v-if="local_absentData">
        <table>
          <tr>
            <th>No.</th>
            <th>欠勤日</th>
            <th>種別</th>
            <th>理由・詳細</th>
            <th>登録日</th>
            <th>登録者</th>
            <th>修正/取消</th>
          </tr>
          <tr v-for="(data, index) in local_absentData" :key="data.id">
            <td>{{ (index + 1).toString().padStart(2, "0") }}</td>
            <td>{{ data.holiday_date }}</td>
            <td>
              <div v-if="data.absent_type">
                <div v-if="data.absent_type === '1'" class="rest_table_item">
                  全日休
                </div>
                <div v-if="data.absent_type === '2'" class="rest_table_item">
                  遅刻
                </div>
                <div v-if="data.absent_type === '3'" class="rest_table_item">
                  早退
                </div>
                <div v-if="data.absent_type === '4'" class="rest_table_item">
                  中抜け
                </div>
              </div>
            </td>
            <td>
              <div class="rest_table_item_reason">
                {{ data.holiday_reason }}
              </div>
            </td>
            <td>
              <div v-if="data.createdAt" class="rest_table_item">
                {{ data.createdAt.toDate().getFullYear() }}-{{
                  data.createdAt.toDate().getMonth() + 1
                }}-{{ data.createdAt.toDate().getDate() }}
              </div>
            </td>
            <td>
              <div class="rest_table_item">{{ data.created }}</div>
            </td>
            <td>
              <div class="rest_table_item" v-if="data.button_title">
                <button :disabled="data.delete_button_state" @click="handleabsentDataDelete(data.id)">
                  {{ data.button_title }}
                </button>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div class="rest_detail_title">有給休暇付与・期限切れ状況</div>
      <div v-if="receiveHolidays">
        <table>
          <tr>
            <th>No.</th>
            <th>内訳</th>
            <th>日数</th>

            <th>付与日</th>
            <th>期限</th>
            <th>ステータス</th>
            <th>登録者</th>
            <th>取消</th>
          </tr>
          <tr v-for="(data, index) in receiveHolidays" :key="data.id">
            <td>{{ (index + 1).toString().padStart(2, "0") }}</td>
            <td>
              <div v-if="data.is_add" class="rest_table_item">付与</div>
            </td>
            <td>
              <div class="rest_table_item">{{ data.num_of_date }}日</div>
            </td>
            <td>
              <div class="rest_table_item">
                <div v-if="typeof data.add_date === 'string'">
                  {{ data.add_date }}
                </div>
                <div v-else>
                  {{ data.add_date.toDate().getFullYear() }}-{{
                    data.add_date.toDate().getMonth() + 1
                  }}-{{ data.add_date.toDate().getDate() }}
                </div>
              </div>
            </td>
            <td>
              <div v-if="data.delete_date" class="rest_table_item">
                {{ data.delete_date.toDate().getFullYear() }}-{{
                  data.delete_date.toDate().getMonth() + 1
                }}-{{ data.delete_date.toDate().getDate() }}
              </div>
            </td>

            <td>
              <div v-if="data.delete_date" class="rest_table_item">
                <div v-if="now < data.delete_date.toDate()" class="paid_text">
                  有効
                </div>
                <div v-else class="absent_text">期限切れ</div>
              </div>
            </td>

            <td>{{ data.created }}</td>
            <td>
              <button @click="handleReceiveDataDelete(data.id)">取消</button>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-else class="pulse-box">
      <Pulse />
    </div>
  </div>
</template>

<script>
import {
  ref,
  onBeforeUnmount,
  onMounted,
  watchEffect,
  watch,
  onUnmounted,
} from "vue";
import getDefaultData from "../composables/getDefaultData";
import { now_timestamp, projectFirestore } from "../../firebase/config";
import HolidayStatus from "./HolidayStatus.vue";
import getDefaultDatas from "../composables/getDefaultDatas";

import Pulse from "../Pulse.vue";
import AbsentAppForm from "./AbsentAppForm.vue";
import FirstSetting from "./FirstSetting.vue";
import AddHoliday from "./AddHoliday.vue";
import NoticeTimeOver from "./NoticeTimeOver.vue";
import useUpdateData from "../composables/userUpdateData";
import getFilteredDatas from "../composables/getFilteredDatas";
import getReceiveHolidays from "../composables/getReceiveHolidays";

export default {
  props: ["engineer_id", "doc_id"],
  name: "DetailSelectedEngineer",
  components: {
    Pulse,
    HolidayStatus,
    AbsentAppForm,
    FirstSetting,
    AddHoliday,
    NoticeTimeOver,
  },
  data() {
    return {
      now: new Date(),
    };
  },

  setup(props, context) {
    const remain_holiday = ref("");
    const receive_holiday = ref("");
    const day_of_absent = ref("");
    const type_of_absent = ref("");
    const absent_reason = ref("");
    const holiday_type = ref("");
    const is_use_holiday = ref("");

    const status = ref("");

    const is_first = ref(false);
    const near_timeover_days = ref(0);

    const is_show_input_baseday = ref(false);
    const is_show_input_nextadd = ref(false);
    const is_show_input_num_of_add = ref(false);
    const is_show_input_remain = ref(false);
    const is_show_input_absence = ref(false);

    const is_show_ab_app_form = ref(false);
    const is_show_first_set = ref(false);
    const show_ab_button_title = ref("休暇・欠勤登録");

    const base_button_title = ref("修正");

    const currentYearStart = ref("");
    const currentYearEnd = ref("");

    const isAddHoliday = ref(false);
    const isTimeOverMessage = ref(false);
    const userid = ref("");
    const oneyear_use_days = ref(0);
    const use_days = ref(0);
    const plan_days = ref(0);

    const isFixItem = ref(false);

    const handleShowAbform = () => {
      is_show_ab_app_form.value = !is_show_ab_app_form.value;
      if (is_show_ab_app_form.value === true) {
        show_ab_button_title.value = "キャンセル";
      } else {
        show_ab_button_title.value = "欠勤登録";
      }
    };

    const handleTimeOverClose = () => {
      isTimeOverMessage.value = false;
    };

    const handleAddHoliClose = () => {
      isAddHoliday.value = false;
    };

    const handleAbsentClose = async () => {
      console.log("absentclose");
      const absentFetchData = async () => {
        try {
          is_show_ab_app_form.value = false;
          show_ab_button_title.value = "休暇・欠勤登録";

          //onMountedでやることを再度実施
          const { futurefilterData, passedfilterData, absentfilterData } =
            await getFilteredDatas(props.doc_id);

          local_futureData.value = futurefilterData.value;
          local_passedData.value = passedfilterData.value;
          local_absentData.value = absentfilterData.value;

          await data_load();

          const nextAdd = new Date(defaultdata.value.next_add);

          currentYearStart.value = new Date(nextAdd);
          currentYearStart.value.setFullYear(nextAdd.getFullYear() - 1);

          currentYearEnd.value = new Date(nextAdd);
          currentYearEnd.value.setDate(currentYearEnd.value.getDate() - 1);

          use_days.value = await calcTotalUseHoliday();
          oneyear_use_days.value = await calcTotalUseHolidayOneyear();
          plan_days.value = await calcTotalPlannedHoliday();

          confirmStatus(use_days.value, plan_days.value);

          const { receive_holidays, total_receive_holiday } =
            await getReceiveHolidays(defaultdata.value.company_id);

          receiveHolidays.value = receive_holidays.value;
          totalReceiveHoliday.value = total_receive_holiday.value;
          defaultdata.value.receive_num_of_day = totalReceiveHoliday.value;

          defaultdata.value.remain_holiday =
            defaultdata.value.receive_num_of_day +
            defaultdata.value.twoyear_before_remain -
            (use_days.value + plan_days.value);
        } catch (err) {
          console.error("データ取得エラー", err);
        }
      };
      await absentFetchData();


    };

    const handleInputBaseday = () => {
      is_show_input_baseday.value = !is_show_input_baseday.value;
      if (is_show_input_baseday.value) {
        base_button_title.value = "キャンセル";
      } else {
        base_button_title.value = "修正";
      }
    };

    const handleInputNextadd = () => {
      is_show_input_nextadd.value = !is_show_input_nextadd.value;
    };

    const handleInputNumofAdd = () => {
      is_show_input_num_of_add.value = !is_show_input_num_of_add.value;
    };

    const handleInputRemain = () => {
      is_show_input_remain.value = !is_show_input_remain.value;
    };

    const handleInputAbsence = () => {
      is_show_input_absence.value = !is_show_input_absence.value;
    };

    const handleShowFirstSet = () => {
      is_show_first_set.value = !is_show_first_set.value;
    };

    const { defaultdata, data_error, data_load } = getDefaultData(
      "holidayData",
      props.doc_id
    );
    data_load();

    const local_futureData = ref([]);
    const local_passedData = ref([]);
    const local_absentData = ref([]);

    const { defaultdatas: add_holi_datas, datas_load: add_holi_load } =
      getDefaultDatas("holiday_add_delete");

    const pushReceiveRegist = async () => {
      const post = {
        receive_num_of_day: defaultdata.value.receive_num_of_day,
        twoyear_before_remain: defaultdata.value.twoyear_before_remain,
        remain_holiday:
          defaultdata.value.receive_num_of_day +
          defaultdata.value.twoyear_before_remain,
      };
      try {
        const res = await projectFirestore
          .collection("holidayData")
          .doc(defaultdata.value.id)
          .update(post);

        is_show_input_remain.value = false;
        console.log("有給残日数を変更しました");
      } catch (err) {
        console.log("有給残日数の変更に失敗しました:", err);
      }
    };

    const pushBaseRegist = async () => {
      const post = {
        base_day: defaultdata.value.base_day,
      };
      try {
        const res = await projectFirestore
          .collection("holidayData")
          .doc(defaultdata.value.id)
          .update(post);

        is_show_input_baseday.value = false;
        console.log("");
      } catch (err) {
        console.log(":", err);
        console.log(defaultdata.value.id);
      }
    };

    const pushNextAdd = async () => {
      const post = {
        next_add: defaultdata.value.next_add,
      };
      try {
        const res = await projectFirestore
          .collection("holidayData")
          .doc(defaultdata.value.id)
          .update(post);

        is_show_input_nextadd.value = false;
        console.log("");
      } catch (err) {
        console.log(":", err);
        console.log(defaultdata.value.id);
      }
    };

    const pushNumofadd = async () => {
      const post = {
        num_of_add: defaultdata.value.num_of_add,
      };
      try {
        const res = await projectFirestore
          .collection("holidayData")
          .doc(defaultdata.value.id)
          .update(post);
        console.log("");

        is_show_input_num_of_add.value = false;
      } catch (err) {
        console.log(":", err);
        console.log(defaultdata.value.id);
      }
    };

    const pushNumofabsence = async () => {
      const post = {
        num_of_absence: defaultdata.value.num_of_absence,
      };
      try {
        const res = await projectFirestore
          .collection("holidayData")
          .doc(defaultdata.value.id)
          .update(post);
        console.log("");
      } catch (err) {
        console.log(":", err);
        console.log(defaultdata.value.id);
      }
    };

    const handleFirstSet = async (base_date, next_add_date, num_of_add) => {
      const doc_id = defaultdata.value.company_id.replace("@mik-s.work", "");


      defaultdata.value.base_day = base_date;
      defaultdata.value.next_add = next_add_date;
      defaultdata.value.num_of_add = num_of_add;
      //defaultdata.value.twoyear_before_remain = fixed_holiday;
      //console.log("fix", fixed_holiday);

      const post = {
        base_day: base_date,
        next_add: next_add_date,
        num_of_add: num_of_add,
        // twoyear_before_remain: defaultdata.value.twoyear_before_remain,

      };

      try {
        const res = await projectFirestore
          .collection("holidayData")
          .doc(doc_id)
          .update(post);
        console.log("初期データを設定しました。");
        is_show_first_set.value = false;
        is_first.value = false;
      } catch (err) {
        console.log("初期データ設定エラー:", err);
      }
    };

    const handleShowAddHoliday = () => {
      isAddHoliday.value = true;
    };

    const totalUseHoliday = ref(0);
    const totalPlanedHoliday = ref(0);
    const totalReceiveHoliday = ref(0);

    const receiveHolidays = ref([]);
    const today = ref("");
    const limit_holiday = ref(0);
    const diffDays = ref(0);

    today.value = new Date();

    watch(
      [defaultdata, add_holi_datas],
      async ([newDefaultdata, newAddHolidatas]) => {
        if (newDefaultdata && newDefaultdata.company_id) {
          //有休付与データの作成
          receiveHolidays.value = newAddHolidatas
            .filter((user) => {
              return user.user_id === userid.value;
            })
            .sort((a, b) => {
              return a.add_date - b.add_date;
            });

          const receive = receiveHolidays.value;
          totalReceiveHoliday.value = receive.reduce((total, data) => {
            if (data.delete_date) {
              const delete_day = new Date(data.delete_date.seconds * 1000);

              if (data.is_add && today.value < delete_day) {
                return total + data.num_of_date;
              } else {
                return total;
              }
            }
          }, 0);

          receiveHolidays.value.some((data) => {
            const limit_day = new Date(data.delete_date.seconds * 1000);

            const daysDifference =
              Math.floor(limit_day - today.value) / (24 * 60 * 60 * 1000);

            if (totalUseHoliday.value) {
              near_timeover_days.value =
                parseFloat(limit_holiday.value) -
                parseFloat(totalUseHoliday.value);
            }

            if (near_timeover_days.value <= 0) {
              isTimeOverMessage.value = false;
            }

            if (daysDifference <= 30) {
              isTimeOverMessage.value = true;
              limit_holiday.value = data.num_of_date;
              diffDays.value = Math.floor(daysDifference);
            }
          });
        } else {
          console.log("デフォルトデータはnullかcompany_idがありません。");
        }
      }
    );

    const handlefutureDataDelete = async (id) => {
      await projectFirestore
        .collection("holiday_application")
        .doc(id)
        .delete()
        .then(() => {
          const deletedIndex = local_futureData.value.findIndex(
            (data) => data.id === id
          );
          if (deletedIndex !== -1) {
            // データが見つかった場合
            local_futureData.value[deletedIndex].delete_button_state = true;
            local_futureData.value[deletedIndex].button_title = "済";
          }
        });
    };

    const handlepassedDataDelete = async (id) => {
      await projectFirestore
        .collection("holiday_application")
        .doc(id)
        .delete()
        .then(() => {
          const deletedIndex = local_passedData.value.findIndex(
            (data) => data.id === id
          );
          if (deletedIndex !== -1) {
            // データが見つかった場合
            local_passedData.value.splice(deletedIndex, 1);
          }
        });
    };

    const handleReceiveDataDelete = async (id) => {
      console.log("receive_data_delete", id);
      await projectFirestore
        .collection("holiday_add_delete")
        .doc(id)
        .delete()
        .then(() => {
          const deletedIndex = receiveHolidays.value.findIndex(
            (data) => data.id === id
          );
          if (deletedIndex !== -1) {
            // データが見つかった場合
            receiveHolidays.value.splice(deletedIndex, 1);
          }
        });
    };

    const handlepassedFixData = async (id) => {
      const fixIndex = local_passedData.value.findIndex(
        (data) => data.id === id
      );
      if (fixIndex !== -1) {
        //データが見つかったら、修正フラグをtrueに変える
        local_passedData.value[fixIndex].fix_button_state =
          !local_passedData.value[fixIndex].fix_button_state;
      }

      console.log("fixindex", fixIndex);
      console.log(
        "holiday_date",
        local_passedData.value[fixIndex].holiday_date
      );
      console.log(
        "holiday_datetaype",
        typeof local_passedData.value[fixIndex].holiday_date
      );
    };

    const handleChangeHolidayData = async (
      id,
      holidayDate,
      holidayNum,
      holidayReason,
      created
    ) => {
      const post = {
        holiday_date: holidayDate,
        holiday_formated: new Date(holidayDate),
        use_holiday_num: holidayNum,
        holiday_reason: holidayReason,
        created: created,
      };

      console.log("passid", id);
      await projectFirestore
        .collection("holiday_application")
        .doc(id)
        .update(post);
      console.log("changed");
      handlepassedFixData(id);
    };

    // const handleChangeHolidayNum = async (id, holidaynum) => {
    //   const post = {
    //     use_holiday_num: holidaynum,
    //   };
    //   await projectFirestore
    //     .collection("holiday_application")
    //     .doc(id)
    //     .update(post);
    //   handlepassedFixData(id);
    // };

    // const handleChangeHolidayReason = async (id, holidayreason) => {
    //   const post = {
    //     holiday_reason: holidayreason,
    //   };
    //   await projectFirestore
    //     .collection("holiday_application")
    //     .doc(id)
    //     .update(post);

    //   handlepassedFixData(id);
    // };

    // const handleChangeHolidayCreatedAt = async (id, createdAt) => {
    //   const post = {
    //     createdAt: createdAt,
    //   };
    //   await projectFirestore
    //     .collection("holiday_application")
    //     .doc(id)
    //     .update(post);
    // };

    // const handleChangeHolidayCreated = async (id, created) => {
    //   const post = {
    //     created: created,
    //   };
    //   await projectFirestore
    //     .collection("holiday_application")
    //     .doc(id)
    //     .update(post);

    //   handlepassedFixData(id);
    // };

    const handleabsentDataDelete = async (id) => {
      await projectFirestore
        .collection("holiday_application")
        .doc(id)
        .delete()
        .then(() => {
          const deletedIndex = local_absentData.value.findIndex(
            (data) => data.id === id
          );
          if (deletedIndex !== -1) {
            // データが見つかった場合
            local_absentData.value.splice(deletedIndex, 1);
          }
        });
    };

    onMounted(async () => {
      const { futurefilterData, passedfilterData, absentfilterData } =
        await getFilteredDatas(props.doc_id);

      local_futureData.value = futurefilterData.value;
      local_passedData.value = passedfilterData.value;
      local_absentData.value = absentfilterData.value;

      await data_load();

      const nextAdd = new Date(defaultdata.value.next_add);

      currentYearStart.value = new Date(nextAdd);
      currentYearStart.value.setFullYear(nextAdd.getFullYear() - 1);

      currentYearEnd.value = new Date(nextAdd);
      currentYearEnd.value.setDate(currentYearEnd.value.getDate() - 1);

      use_days.value = await calcTotalUseHoliday();
      oneyear_use_days.value = await calcTotalUseHolidayOneyear();
      plan_days.value = await calcTotalPlannedHoliday();

      confirmStatus(oneyear_use_days.value, plan_days.value);

      console.log("use", use_days.value, "plan", plan_days.value);

      const { receive_holidays, total_receive_holiday } =
        await getReceiveHolidays(defaultdata.value.company_id);

      receiveHolidays.value = receive_holidays.value;
      totalReceiveHoliday.value = total_receive_holiday.value;

      defaultdata.value.receive_num_of_day = totalReceiveHoliday.value;
    });

    onUnmounted(() => {
      const { error, updateData } = useUpdateData();

      const remain =
        defaultdata.value.receive_num_of_day +
        defaultdata.value.twoyear_before_remain -
        (use_days.value + plan_days.value);

      if (totalReceiveHoliday.value) {
        const post = {
          receive_num_of_day: totalReceiveHoliday.value,
          remain_holiday: remain,
          status: status.value,
        };
        const collection_array = ["holidayData"];
        updateData(collection_array, post, defaultdata.value.id);
      }
    });

    const calcTotalUseHoliday = async () => {
      //有効期間内の使用分を計算

      const passed = local_passedData.value;
      console.log("passed", passed);
      console.log("CurrentStart", currentYearStart.value);
      console.log("CurrentEnd", currentYearEnd.value);

      const currentTwoYearStart = ref(new Date(currentYearStart.value));
      currentTwoYearStart.value.setFullYear(
        currentYearStart.value.getFullYear() - 1
      );

      console.log("currentTwoYearStart", currentTwoYearStart);

      totalUseHoliday.value = passed.reduce((total, data) => {
        const holidayDate = new Date(data.holiday_date);
        console.log("holidayDate", holidayDate);

        if (
          currentTwoYearStart.value <= holidayDate &&
          holidayDate <= currentYearEnd.value
        ) {
          return total + parseFloat(data.use_holiday_num, 10);
        } else {
          return total;
        }
      }, 0);

      return totalUseHoliday.value;
    };

    const calcTotalUseHolidayOneyear = async () => {
      //本年度分のみ

      const passed = local_passedData.value;
      console.log("passed", passed);
      console.log("CurrentStart", currentYearStart.value);
      console.log("CurrentEnd", currentYearEnd.value);

      const oneyear_totaluse = ref(0);

      oneyear_totaluse.value = passed.reduce((total, data) => {
        const holidayDate = new Date(data.holiday_date);
        console.log("holidayDate", holidayDate);

        if (
          currentYearStart.value <= holidayDate &&
          holidayDate <= currentYearEnd.value
        ) {
          return total + parseFloat(data.use_holiday_num, 10);
        } else {
          return total;
        }
      }, 0);

      console.log("oneyear_totaluse", oneyear_totaluse.value);

      return oneyear_totaluse.value;
    };

    const calcTotalPlannedHoliday = async () => {
      // console.log("futurefilterData.value", futurefilterData);
      // if (!futurefilterData || futurefilterData.length === 0) {
      //   return 0;
      // }
      const future = local_futureData.value;
      totalPlanedHoliday.value = future.reduce((total, data) => {
        const plannedholidayDate = new Date(data.holiday_date);
        if (
          currentYearStart.value <= plannedholidayDate &&
          plannedholidayDate <= currentYearEnd.value
        ) {
          return total + parseFloat(data.use_holiday_num, 10);
        } else {
          return total;
        }
      }, 0);

      return totalPlanedHoliday.value;
    };

    const confirmStatus = (totalUse, plannedUse) => {
      const future_totalUse = totalUse + plannedUse;
      console.log("confirm_totaluse", totalUse);
      if (totalUse) {
        if (totalUse >= 5) {
          status.value = 1;
        } else if (future_totalUse >= 5) {
          status.value = 2;
        } else {
          status.value = 3;
        }
      } else {
        status.value = 4;
      }
    };

    const handleAddSetting = async (result_diffdays) => {
      const fetchData = async () => {
        try {
          const { receive_holidays, total_receive_holiday } =
            await getReceiveHolidays(defaultdata.value.company_id);

          receiveHolidays.value = receive_holidays.value;
          totalReceiveHoliday.value = total_receive_holiday.value;

          defaultdata.value.receive_num_of_day = totalReceiveHoliday.value;

          defaultdata.twoyear_before_remain = result_diffdays;
        } catch (err) {
          console.error("データ取得エラー", err);
        }
      };
      await fetchData();

      isAddHoliday.value = !isAddHoliday.value;
    };

    return {
      is_show_ab_app_form,
      is_show_first_set,
      show_ab_button_title,
      remain_holiday,
      receive_holiday,

      use_days,
      plan_days,
      oneyear_use_days,

      status,

      day_of_absent,
      type_of_absent,
      absent_reason,
      holiday_type,
      is_use_holiday,

      defaultdata,
      data_error,

      base_button_title,

      totalUseHoliday,
      local_passedData,
      local_futureData,
      local_absentData,

      totalReceiveHoliday,
      receiveHolidays,

      is_show_input_baseday,
      is_show_input_nextadd,
      is_show_input_num_of_add,
      is_show_input_remain,
      is_show_input_absence,

      isAddHoliday,
      isTimeOverMessage,
      is_first,

      limit_holiday,
      diffDays,
      isFixItem,

      handleShowAbform,
      handleAbsentClose,
      handlefutureDataDelete,
      handlepassedDataDelete,
      handleabsentDataDelete,
      handleTimeOverClose,
      handleAddHoliClose,

      handleFirstSet,
      handleShowFirstSet,
      handleInputBaseday,

      handleInputNextadd,
      handleInputNumofAdd,
      handleInputRemain,
      handleInputAbsence,
      handleShowAddHoliday,

      pushReceiveRegist,
      pushBaseRegist,
      pushNextAdd,
      pushNumofadd,
      pushNumofabsence,
      handleAddSetting,

      handleReceiveDataDelete,
      handlepassedFixData,
      handleChangeHolidayData,
      // handleChangeHolidayNum,
      // handleChangeHolidayReason,
      // //handleChangeHolidayCreatedAt,
      // handleChangeHolidayCreated,
    };
  },
  emits: ["detail-return"],
  methods: {
    handleReturnRestTop() {
      this.$emit("detail-return");
    },
  },
};
</script>

<style>
.holiday_box {
  width: 1200px;
  background: white;
  border-radius: 10px;
  margin-top: 25px;
  margin-left: 20px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  padding: 30px 62px 62px 62px;
}

.rest_entry_item {
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}

.pulse-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.big_image {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.holiday_name_left {
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
}

.holiday_name_right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.holiday_wrapper {
  display: flex;
  flex-direction: column;
}

.holiday_content_wrapper {
  width: 100%;
  display: flex;
}

.holidata_button_container {
  display: flex;
  margin-left: 20px;
}

.holiday_content_item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 300px;
}

.top_button {
  width: 150px;
  height: 32px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 5px;
  margin-right: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.top_ret_button {
  width: 150px;
  height: 32px;
  background: #2c3e5062;
  color: white;
  border: none;
  border-radius: 5px;
  margin-right: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.rest_input_date {
  width: 120px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  margin: 10px;
}

.rest_input_num {
  width: 60px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  margin: 10px;
}

.rest_detail_title {
  font-weight: bold;
  width: 1000px;
  color: white;
  background: gray;
  margin-top: 50px;
  text-align: center;
}

.data_container {
  display: flex;
}

.data_container button {
  margin-left: 10px;
}

.ab_app_container {
  display: flex;
  flex-direction: column;
}

.absent_app_form {
  width: 880px;
  background: white;
  border-radius: 20px;

  border: 3px solid;
  padding: 0 20px 20px;
  font-size: 15px;
  margin: 20px;
}

.absent_app_form h4 {
  margin-bottom: 25px;
}

.absent_app_form h5 {
  margin-bottom: 5px;
}

.ab_form_content {
  display: flex;
  padding-bottom: 20px;
}

.ab_form_select {
  display: flex;
}

.ab_form_item {
  margin-right: 30px;
}

.ab_reason_area {
  width: 250px;
  height: 50px;
}

.rest_table {
  display: flex;
  justify-content: center;
  font-size: 15px;
}

.rest_table_item {
  width: 120px;
  display: flex;
  justify-content: center;
}

.rest_table_item_reason {
  width: 200px;
  display: flex;
  justify-content: center;
}

.rest_table_item .paid_text {
  color: #d9376e;
  font-weight: bold;
  font-size: 14px;
  width: 50px;
  text-align: center;
}

.rest_table_item .absent_text {
  color: gray;
  font-weight: bold;
  font-size: 13px;
  width: 80px;
  text-align: center;
}

.table_buttons {
  display: flex;
}

.table_buttons button {
  margin: 2px;
}


.show_input_remain {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
}

.show_input_remain h5 {
  margin: 0px;
}

.remain_up {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.remain_down {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
