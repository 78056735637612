<template>
  <div class="out_form_style">
    <form class="form_style" @submit.prevent="handleSubmit">
      <p>パスワードを変更してください。</p>
      <label>新パスワード</label>
      <input
        class="input_box"
        type="password"
        required
        placeholder="新しいパスワード"
        v-model="new_password"
      />

      <label>パスワード確認</label>
      <input
        class="input_box"
        type="password"
        required
        placeholder="確認用"
        v-model="password_confirm"
      />

      <h5>使用できる文字の種類</h5>
      <ul>
        <li>アルファベット大文字、小文字</li>
        <li>数字</li>
        <li>記号（.?/_#*）</li>
        <li>ハイフン（-）は利用できません。</li>
      </ul>
      <h5>文字数</h5>
      <ul>
        <li>8文字以上24文字以下</li>
      </ul>

      <div>{{ error }}</div>
      <button class="button">変更する</button>
    </form>
  </div>
</template>

<script>
import { ref } from "vue";
import useChangePassword from "../composables/useChangePassword";
import { useRouter } from "vue-router";

export default {
  setup(props, context) {
    const { error, changepassword } = useChangePassword();
    const email = ref("");
    const password_confirm = ref("");
    const new_password = ref("");
    const router = useRouter();

    const handleSubmit = async () => {
      try {
        if (new_password.value === password_confirm.value) {
          const regex = /^[a-zA-Z0-9.!?/_#*]{8,24}$/;
          if (regex.test(new_password.value)) {
            await changepassword(new_password.value).then(() => {
              router.push({ name: "User_Home"})
            }).error(() => {
              console.log("パスワード変更失敗")
            });
            

          } else {
            console.log(
              "パスワード条件に合致していません。",
              new_password.value
            );
            throw Error("パスワード条件に合致していません。");
          }
        } else {
          console.log("新パスワードと確認用パスワードが一致していません。");
          throw Error("新パスワードと確認用パスワードが一致していません。");
        }
      } catch (err) {
        error.value = err.message;
      }

      // context.emit("signup");
    };

    return { email, password_confirm, new_password, error, handleSubmit };
  },
};
</script>

<style>
.out_form_style {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.form_style {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input_box {
  width: 220px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding-left: 10px;
}

.button {
  width: 220px;
  height: 33px;
  align-self: center;
  background: #f5c310;
  border: none;
  color: white;
  border-radius: 10px;
  margin-top: 62px;
  margin-bottom: 41px;
}
</style>
