<template>
  <div class="point_add_form">
    <h4>ポイント付与</h4>

    <div class="radio_item_column">
      <div class="radio_item_column">
        <div class="radio">
          <input
            type="radio"
            name="calc_method"
            v-model="selected_method"
            value="1"
          />ポイント対象者を選択する
        </div>
        <div class="radio">
          <input
            type="radio"
            name="calc_method"
            v-model="selected_method"
            value="2"
          />ポイント非対象者を選択する （選択した社員以外の全員にポイント付与）
        </div>
      </div>
    </div>

    <div v-if="selected_method === '1'">
      <!-- 対象者を選択した場合 -->
      <h5>ポイント対象者</h5>
      <div v-if="local_users && local_users.length > 0">
        <div v-for="user in local_users" :key="user.id">{{ user.name }}</div>
      </div>
    </div>

    <div v-if="selected_method === '2'">
      <!-- 非対象者を選択した場合 -->
      <h5>ポイント非対象者</h5>
      <div v-if="local_users && local_users.length > 0">
        <div v-for="user in local_users" :key="user.id">{{ user.name }}</div>
      </div>
    </div>

    <h5>ポイント付与理由</h5>
    <select v-model="point_content_num">
      <option value="1">皆勤賞</option>
      <option value="2">対象アンケートの回答</option>
      <option value="3">がんばったMVPエントリー</option>
      <option value="4">がんばったMVPへの投票</option>
      <option value="5">友人紹介</option>
      <option value="6">紹介した友人の入社</option>
      <option value="7">新規派遣先紹介</option>
      <option value="8">紹介いただいた派遣先との契約締結（50）</option>
      <option value="9">紹介いただいた派遣先との契約締結（100）</option>
      <option value="10">紹介いただいた派遣先との契約締結（200）</option>
      <option value="11">その他</option>
    </select>

    <div v-if="point_content_num === '1'">
      <input type="number" min="1" max="12" v-model="point_month" />月分
    </div>
    <div v-if="point_content_num === '11'">
      <div>
        <div>
          <label>付与ポイント</label>
          <input type="number" v-model="input_point" />ポイント
        </div>
        <div>
          <label>ポイント付与理由</label>
          <input type="text" v-model="input_comment" />
        </div>
      </div>
    </div>

    <h5>ポイント付与日</h5>
    <input type="date" v-model="point_date" />

    <h5>備考</h5>
    <input type="text" v-model="point_other" />
    <div>
      <div v-if="selected_method === '1'">
        <button @click="handleAddpoint">登録</button>
      </div>

      <div v-if="selected_method === '2'">
        <button @click="handleAddpointUnselect">登録</button>
      </div>

      <button @click="handleResetPoint">クリア</button>
      <p>ポイントが即座に反映しない場合は、ブラウザをリロードしてください。</p>
    </div>
  </div>
</template>

<script>
import { ref, reactive, watch } from "vue";
import { projectFirestore } from "@/firebase/config";
export default {
  props: ["users","defaultdatas","now_page"],
  data() {
    return {
      selected_method: 0,
    };
  },
  setup(props, context) {
    const local_users = ref(props.users);
    const point_user_id = ref("");
    const point_content_num = ref("");
    const point_month = ref("");
    const point_content = ref("");
    const points = ref("");
    const point_date = ref("");
    const point_other = ref("");
    const input_point = ref(0);
    const input_comment = ref("");
    const remove_selected_users = ref([])

    watch(
      () => props.users,
      (newUsers, oldUsers) => {
        local_users.value = newUsers;
        console.log("local", local_users.value);
      }
    );

    const handleResetPoint = () => {
      local_users.value = [];
      point_content_num.value = null;
      point_date.value = null;
      point_other.value = null;
      points.value = null;
      point_month.value = null;
      point_content.value = null;
      input_point.value = null;
      input_comment.value = null;
    };

    handleResetPoint();

    const closeAddform = () => {
      context.emit("add_form_close",props.now_page);
    };

    const handleAddpointUnselect = async() =>{
      remove_selected_users.value = props.defaultdatas.filter((user) =>{
        console.log("user.id",user.id)
        console.log("local_users",local_users.value)
    
        return !local_users.value.some(localUser => localUser.id === user.id)


      })
    
      console.log("remove",remove_selected_users.value)

      for (let user of remove_selected_users.value) {
        const userData = reactive({
          points: 0,
          point_content: "",
        });
        if (point_content_num.value) {
          switch (point_content_num.value) {
            case "1":
              const month = point_month.value;
              userData.point_content = `${month}月分皆勤賞`;
              userData.points = 5;
              break;
            case "2":
              userData.point_content = "対象アンケートの回答";
              userData.points = 1;
              break;
            case "3":
              userData.point_content = "がんばったMVPエントリー";
              userData.points = 3;
              break;
            case "4":
              userData.point_content = "がんばったMVPへの投票";
              userData.points = 1;
              break;
            case "5":
              userData.point_content = "友人紹介";
              userData.points = 5;
              break;
            case "6":
              userData.point_content = "紹介した友人の入社";
              userData.points = 200;
              break;
            case "7":
              userData.point_content = "新規派遣先紹介";
              userData.points = 5;
              break;
            case "8":
              userData.point_content = "紹介いただいた派遣先との契約締結（50）";
              userData.points = 50;
              break;
            case "9":
              userData.point_content =
                "紹介いただいた派遣先との契約締結（100）";
              userData.points = 100;
              break;
            case "10":
              userData.point_content =
                "紹介いただいた派遣先との契約締結（200）";
              userData.points = 200;
              break;
            case "11":
              userData.point_content = input_comment.value;
              console.log("comment", input_comment.value);
              userData.points = input_point.value;
              break;
            default:
              console.log(
                "unknown point_content_num:",
                point_content_num.value
              );
              break;
          }
        }

        const currentday = new Date();

        const point_addpost = {
          point_user_id: user.id,
          point_status: "add",
          point_content: userData.point_content,
          points: userData.points,
          point_date: point_date.value,
          point_other: point_other.value,
          createdAt: currentday,
        };
        console.log(point_addpost);

        try {
          const res = await projectFirestore
            .collection("points")
            .add(point_addpost);
          console.log("ポイントの書き込みに成功しました。");
        } catch (err) {
          console.error("ポイントの書き込みに失敗しました:", err);
        }
      }

      handleResetPoint();
      closeAddform();


    }

    const handleAddpoint = async () => {
      console.log(point_content_num.value);
      //ポイント内容の設定

      for (let user of local_users.value) {
        const userData = reactive({
          points: 0,
          point_content: "",
        });
        if (point_content_num.value) {
          switch (point_content_num.value) {
            case "1":
              const month = point_month.value;
              userData.point_content = `${month}月分皆勤賞`;
              userData.points = 5;
              break;
            case "2":
              userData.point_content = "対象アンケートの回答";
              userData.points = 1;
              break;
            case "3":
              userData.point_content = "がんばったMVPエントリー";
              userData.points = 3;
              break;
            case "4":
              userData.point_content = "がんばったMVPへの投票";
              userData.points = 3;
              break;
            case "5":
              userData.point_content = "友人紹介";
              userData.points = 5;
              break;
            case "6":
              userData.point_content = "紹介した友人の入社";
              userData.points = 200;
              break;
            case "7":
              userData.point_content = "新規派遣先紹介";
              userData.points = 5;
              break;
            case "8":
              userData.point_content = "紹介いただいた派遣先との契約締結（50）";
              userData.points = 50;
              break;
            case "9":
              userData.point_content =
                "紹介いただいた派遣先との契約締結（100）";
              userData.points = 100;
              break;
            case "10":
              userData.point_content =
                "紹介いただいた派遣先との契約締結（200）";
              userData.points = 200;
              break;
            case "11":
              userData.point_content = input_comment.value;
              console.log("comment", input_comment.value);
              userData.points = input_point.value;
              break;
            default:
              console.log(
                "unknown point_content_num:",
                point_content_num.value
              );
              break;
          }
        }

        const currentday = new Date();

        const point_addpost = {
          point_user_id: user.id,
          point_status: "add",
          point_content: userData.point_content,
          points: userData.points,
          point_date: point_date.value,
          point_other: point_other.value,
          createdAt: currentday,
        };
        console.log(point_addpost);

        try {
          const res = await projectFirestore
            .collection("points")
            .add(point_addpost);
          console.log("ポイントの書き込みに成功しました。");
        } catch (err) {
          console.error("ポイントの書き込みに失敗しました:", err);
        }
      }

      handleResetPoint();
      closeAddform();
    };

    return {
      point_content_num,
      point_content,
      point_month,
      points,
      point_date,
      point_other,
      local_users,
      input_point,
      input_comment,
      remove_selected_users,
      closeAddform,
      handleAddpoint,
      handleResetPoint,
      handleAddpointUnselect,
    };
  },
  emits: ["add_form_close"],
};
</script>

<style>
.point_add_form {
  width: 900px;
}

.point_add_form {
  width: 880px;
  background: white;
  border-radius: 20px;

  border: 3px solid;
  padding: 0 20px 20px;
  font-size: 15px;
  margin: 20px;
}

.point_add_form h4 {
  margin-bottom: 25px;
}

.point_add_form h5 {
  margin-bottom: 5px;
}

.point_form_content {
  display: flex;
  padding-bottom: 20px;
}

.point_form_select {
  display: flex;
}

.point_form_item {
  margin-right: 30px;
}

.point_reason_area {
  width: 250px;
  height: 50px;
}
</style>
