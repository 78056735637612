<template>
  <div class="status_box">
    <div class="status_submit_true" v-if="is_submit">
      申請あり
    </div>
    <div class="status_submit_false" v-if="!is_submit">
      申請なし
    </div>
    </div>
</template>

<script>
export default {
  name: "DefaultStatus",
  props: ["is_submit"],
};
</script>

<style>
.status_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.status_submit_true {
  width: 88px;
  height: 34px;
  border-radius: 30px;
  background: #ffd803;
  color: #36383a;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:14px;
  font-weight:bold;
}


.status_submit_false {
  width: 88px;
  height: 34px;
  border-radius: 30px;
  background: #e7eaec;
  color: #36383a;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:14px;
  font-weight:bold;
}
</style>
