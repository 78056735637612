import { ref } from "vue";
import getReceiveHolidays from "./getReceiveHolidays";

const getCarryForwardHoliday = async (company_id, use_holiday) => {
  const carry_holiday = ref(0);
  //切り替え前の有給取得日数を確認

  //切り替え前の年の新規有給付与日数を確認
  const { receive_holidays } = await getReceiveHolidays(company_id);
  const lastData = receive_holidays.value[receive_holidays.value.length - 1];
  const lastAddHoliday = lastData.num_of_date;
  const lastCarryForward = lastData.carry_forward;

  //新規有給付与日数ー有給取得日数
  carry_holiday.value = lastAddHoliday - use_holiday;
  const carryForwardDays = carry_holiday.value;

  return { carryForwardDays, lastCarryForward };
};

export default getCarryForwardHoliday;
