<template>
  <div class="add_engineer_box">
    <div v-if="data_error">{{ data_error }}</div>
    <div v-if="defaultdata">
      <h3>有休データの修正</h3>
      <form @submit.prevent="handleSubmit">
        <div>
          {{ defaultdata.last_name }}{{ defaultdata.first_name }}さんのデータ
        </div>
        <div>ID:{{ defaultdata.company_id }}</div>

        <div class="entry_item" v-if="defaultdata">
          <label>有給残日数</label>
          <input type="number" v-model="defaultdata.remain_holiday" />
        </div>

        <div class="entry_item" v-if="defaultdata">
          <label>次回有給付与日</label>
          <div class="enter_date">
            <input
              type="number"
              min="2019"
              max="2050"
              v-model="defaultdata.enter_year"
            />年
            <input
              type="number"
              v-model="defaultdata.enter_month"
              min="1"
              max="12"
            />月
            <input
              type="number"
              v-model="defaultdata.enter_day"
              min="1"
              max="31"
            />日
          </div>
        </div>

        <div class="entry_item" v-if="defaultdata">
          <label>次回取得有給日数</label>
          <input type="number" v-model="defaultdata.remain_holiday" />
        </div>

        <button>修正する</button>
      </form>
    </div>
    <div v-else class="pulse-box"><Pulse /></div>
  
  </div>
</template>

<script>
import getDefaultData from "../composables/getDefaultData";
import { projectFirestore } from "@/firebase/config";
import Pulse from "../Pulse.vue";

export default {
  name: "FixDefaultData",
  props: ["engineer_id"],
  components: { Pulse },
  data() {
    return {};
  },
  setup(props, context) {
    const { defaultdata, data_error, data_load } = getDefaultData(
      props.engineer_id
    );

    const emit = context.emit;
    const fixClose = (emit) => {
      const is_close = true;
      emit("fix_view_close", is_close);
    };

    data_load();

    const updateStatus = (newStatus) => {
      defaultdata.value.status = newStatus;
    };

    const handleSubmit = async () => {
      if (defaultdata.value) {
        const engineer_data = {
          last_name: defaultdata.value.last_name,
          first_name: defaultdata.value.first_name,
          last_name_kana: defaultdata.value.last_name_kana,
          first_name_kana: defaultdata.value.first_name_kana,
          address: defaultdata.value.address,
          area: defaultdata.value.area,
          phone: defaultdata.value.phone,
          status: defaultdata.value.status,
          pic: defaultdata.value.pic,
          enter_year: defaultdata.value.enter_year,
          enter_month: defaultdata.value.enter_month,
          enter_day: defaultdata.value.enter_day,
          birth_year: defaultdata.value.enter_year,
          birth_month: defaultdata.value.enter_month,
          birth_day: defaultdata.value.enter_day,
          company_id: defaultdata.value.company_id,
          company_mail: defaultdata.value.company_mail,
          emergency_name: defaultdata.value.emergency_name,
          emergency_phone: defaultdata.value.emergency_phone,
          emergency_relation: defaultdata.value.emergency_relation,
        };

        // 各フィールドが `undefined` でないことを確認
        for (const key in engineer_data) {
          if (engineer_data[key] === undefined) {
            delete engineer_data[key]; // `undefined` フィールドを削除
          }
        }

        if (Object.keys(engineer_data).length > 0) {
          try {
            const res = await projectFirestore
              .collection("engineers")
              .doc(props.engineer_id)
              .set(engineer_data);
            console.log("書き込みに成功しました。");
            fixClose(emit);
          } catch (err) {
            console.error("データ書き込みエラー：", err);
          }
        }
      }
    };

    return {
      defaultdata,
      data_error,
      handleSubmit,
      updateStatus,
    };
  },
  methods: {
    pushBack() {
      const on_push = true;
      this.$emit("edit-to-description", on_push);
    },
  },
};
</script>

<style>
.add_engineer_box {
  width: 900px;
  background: white;
  border-radius: 10px;
  margin-top: 25px;
  margin-left: 20px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
}

.add_engineer_box h3 {
  align-self: flex-start;
  margin-left: 62px;
}

.entry_part {
  margin-left: 62px;
  width: 500px;
}
.entry_part2 {
  margin-left: 62px;
}
.entry_item {
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}
.entry_item p {
  font-size: 14px;
  margin: 0;
}

.entry_item input {
  width: 259px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}
.entry_item_name p {
  font-size: 14px;
  margin: 0;
}
.entry_item_name {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}

.entry_item_name input {
  width: 159px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
}

.entry_item_address {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}
.entry_item_address p {
  font-size: 14px;
  margin: 0;
}

.entry_item_address input {
  width: 550px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.emergency {
  display: flex;
}
.emergency .entry_item {
  width: 270px;
}

.face_up_box {
  width: 260px;
  height: 200px;
  background: #e3f5ff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #105ef5;
  font-size: 14px;
}

.add_engineer_box button {
  width: 241px;
  height: 33px;
  align-self: center;
  background: #f5c310;
  border: none;
  color: white;
  border-radius: 10px;
  margin-top: 62px;
  margin-bottom: 41px;
}

.full_part {
  display: flex;
  margin-top: 30px;
}

.radio_item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.radio {
  margin: 5px 0;
  font-size: 13px;
}

.radio_item p {
  font-size: 14px;
  margin: 0 0 10px 0;
}

.enter_date {
  display: flex;
  align-items: center;
  width: 30%;
}

.discription_circle_button {
  position: absolute;
  right: 80px;
  top: 120px;
  background: #8d8c89;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  font-size: 24px;
}
</style>
