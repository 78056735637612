<template>
  <div>
    <div class="absent_app_form">
      <h4>CSV一括登録</h4>
      <div>
        <p>CSVを選択してください。</p>
        <input id="up-csv" type="file" ref="restcsvInput" />
        <button @click="handleUpload">アップロード</button>
      </div>
    </div>
  </div>
</template>

<script>
import { projectFirestore } from "@/firebase/config";

export default {
  data() {
    return {};
  },
  setup() {},
  methods: {
    handleUpload() {
      const input = this.$refs.restcsvInput;

      if (input.files.length > 0) {
        const file = input.files[0];
        const reader = new FileReader();

        reader.onload = () => {
          const csvData = reader.result;
          const rows = csvData.split("\n").map((row) => row.split(","));
          console.log("データ数", rows.length);

          const headers = rows[0];

          const idIndex = headers.indexOf("メール");

          const holidayDateIndex =
            headers.indexOf("休暇を取得したい日はいつですか。");
          const absentTypeIndex =
            headers.indexOf("どのように休暇をとりますか。");

          const holidayReasonIndex =
            9;
          console.log("holidayReasonIndex", holidayReasonIndex);
          const holidayTypeIndex =
            headers.indexOf("この休暇に有給を使用しますか。");
          const createdAtIndex = headers.indexOf("完了時刻");
          const createdIndex = headers.indexOf("名前");

          for (let i = 1; i < rows.length; i++) {
            const currentRow = rows[i];
            const userid = currentRow[idIndex];
            const holiday_date = currentRow[holidayDateIndex].replace(
              /\//g,
              "-"
            );

            console.log("holiday_date", holiday_date);

            let holiday_type = "";
            let absent_type = 0;
            let use_holiday_num = 0;

            if (currentRow[holidayTypeIndex] === "使用する") {
              holiday_type = "paid";

              if (currentRow[absentTypeIndex] === "【全日休】") {
                use_holiday_num = 1;
              } else if (
                currentRow[absentTypeIndex] === "【半休】午前休" ||
                "【半休】午後休"
              ) {
                use_holiday_num = 0.5;
              } else {
                use_holiday_num = -1;
              }
            } else {
              holiday_type = "absent";
              if (currentRow[absentTypeIndex] === "【全日休】") {
                absent_type = 1;
              } else if (currentRow[absentTypeIndex] === "【半休】午前休") {
                absent_type = 2;
              } else if (currentRow[absentTypeIndex] === "【半休】午後休") {
                absent_type = 3;
              }
            }
            const holiday_reason = currentRow[holidayReasonIndex];
            const createdAt = new Date(currentRow[createdAtIndex]);
            const created = currentRow[createdIndex];

            this.insertData(
              userid,
              holiday_date,
              holiday_type,
              absent_type,
              use_holiday_num,
              holiday_reason,
              createdAt,
              created
            );
          }

          this.$refs.restcsvInput.value = null;
        };

        reader.readAsText(file);
      } else {
        console.error("ファイルが選択されていません");
      }
    },
    insertData(
      userid,
      holiday_date,
      holiday_type,
      absent_type,
      use_holiday_num,
      holiday_reason,
      createdAt,
      created
    ) {
      if (!userid) {
        console.log("IDが取得できませんでした。スキップします。ID:", userid);
        return;
      }

      const post = {
        userid: userid,
        holiday_date: holiday_date,
        absent_type: absent_type,
        holiday_formated: new Date(holiday_date),
        use_holiday_num: use_holiday_num,
        holiday_reason: holiday_reason,
        holiday_type: holiday_type,
        createdAt: createdAt,
        created: created,
      };

      console.log("postdata", { post });

      projectFirestore
        .collection("holiday_application")
        .add(post)
        .then(() => {
          console.log(`ID ${userid}のデータが挿入されました。`);
        })
        .catch((error) => {
          console.log(`ID ${userid}のデータ挿入に失敗しました。`);
        });
    },
  },
};
</script>

<style></style>
