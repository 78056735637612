<template>
  <div class="holiday_form">
    <h3>社内貢献ポイント利用申請</h3>

    <div>
      <h5>利用可能ポイント数</h5>

      <div v-if="point">
        <p>{{ rest_point }}pt</p>
      </div>

      <h5>利用ポイント</h5>

      <select v-model="use_point">
        <option
          v-if="point<200"
          v-for="i in Math.floor(parseInt(point) / 10)"
          :value="i * 10"
          :key="i"
        >
        {{ i * 10 }}
      </option>
        <option
          v-if="point>=200"
          v-for="i in 20"
          :value="i * 10"
          :key="i"
        >
          {{ i * 10 }}
        </option>
      </select>

      ポイント

      <h5>ポイント換金額</h5>

      <p>{{ change_money }}円</p>
    </div>
    <div class="pointform_bottun_wrapper">
      <button @click="handleSubmitPoint" class="point_submit_button">
        申請
      </button>
      <button @click="handleClear" class="point_clear_button">クリア</button>
    </div>
    <div class="batsu_button" @click="handleFormClose">×</div>
  </div>
</template>

<script>
import getChangeMoney from "@/components/composables/getChangeMoney";
import { ref, watch } from "vue";
import { projectFirestore } from "@/firebase/config";
import { init, send } from "emailjs-com";

export default {
  props: ["point", "user_id", "user_name", "pic_name"],
  setup(props, context) {
    const use_point = ref(0);
    const rest_point = ref(props.point);
    const is_use_submit = ref(false);
    const change_money = ref(0);

    watch(
      () => use_point.value,
      (newUsePoint) => {
        //使用分のポイントを引く。
        rest_point.value = props.point - newUsePoint;

        const { changemoney: updateChangemoney } = getChangeMoney(newUsePoint);
        if (change_money.value !== updateChangemoney.value)
          change_money.value = updateChangemoney.value;
      }
    );

    const handleClear = () => {
      use_point.value = 0;
      change_money.value = 0;
    };

    const handleSubmitPoint = async () => {
      const currentday = new Date();

      const post = {
        point_status: "use",
        point_user_id: props.user_id,
        points: use_point.value,
        point_date: currentday,
        createdAt: currentday,
        change_money: change_money.value,
        point_content: "利用申請",
        //admin側の詳細出力に利用
        is_submit: true,
      };

      const pointdata_post = {
        is_submit: true,
        final_change: currentday,
        hold_point: rest_point.value,
      };

      try {
        await projectFirestore.collection("points").add(post);
        console.log("ポイントデータの書き込みに成功しました。");

        await projectFirestore
          .collection("pointData")
          .doc(props.user_id)
          .update(pointdata_post);
        console.log("ポイントユーザーデータの書き込みに成功しました。");
        sendPointEmailjs(props.pic_name, use_point.value, change_money.value);

        context.emit("point_used", use_point.value);

        //handleClear();
      } catch (err) {
        console.error("ポイントデータの書き込みに失敗しました:", err);
      }
    };

    const sendPointEmailjs = (pic, use_point, change_money) => {
      init("38HQ7GOaxaGFelSRC");
      let enter_pic_name;
      let enter_pic_address;
      switch (pic) {
        case "kiyanagi":
          enter_pic_name = process.env.VUE_APP_PIC1_NAME;
          enter_pic_address = process.env.VUE_APP_PIC1_EMAIL;

          break;
        case "oda":
          enter_pic_name = process.env.VUE_APP_PIC2_NAME;
          enter_pic_address = process.env.VUE_APP_PIC2_EMAIL;
          break;
        case "hata":
          enter_pic_name = process.env.VUE_APP_PIC3_NAME;
          enter_pic_address = process.env.VUE_APP_PIC3_EMAIL;
          break;
        default:
          enter_pic_name = "担当者不明";
          enter_pic_address = process.env.VUE_APP_DEFAULT_EMAIL;
      }

      const param = {
        to_name: enter_pic_name,
        to_address: enter_pic_address,
        from_name: props.user_name,
        use_point: use_point,
        change_money: change_money,
      };
      send("service_d6nscz8", "template_69ianji", param, "38HQ7GOaxaGFelSRC");
    };
    return {
      is_use_submit,
      change_money,
      rest_point,
      use_point,
      rest_point,
      handleClear,
      handleSubmitPoint,
    };
  },
  methods: {
    handleFormClose() {
      this.$emit("point_form_close");
    },
  },
};
</script>

<style>
.point_submit_button {
  width: 100px;
  height: 30px;
  background: gray;
  color: white;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  align-self: center;
  margin-top: 20px;
  margin-right: 5px;
}

.point_clear_button {
  width: 100px;
  height: 30px;
  background: white;
  color: gray;
  border: 1px solid gray;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  align-self: center;
  margin-top: 20px;
  margin-left: 5px;
}

.pointform_bottun_wrapper {
  display: flex;
}
</style>
